import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { FileCopy, Share } from '@material-ui/icons';
import { ChangeEvent, useState } from 'react';
import { SharedTrackerService } from 'features/Map/api/sharedTracker';
import { setTimeout } from 'timers';
import copy from 'copy-to-clipboard';
import { useStyles } from './styles';

const options = [
  { value: '1h', label: '1h' },
  { value: '3h', label: '3h' },
  { value: '6h', label: '6h' },
  { value: '12h', label: '12h' },
  { value: '1d', label: '1d' },
  { value: '2s', label: '2d' },
  { value: '4d', label: '4d' },
];

interface IShareTrackerLinkProps {
  deviceId: string;
  plate: string;
}

export function ShareTrackerLink({ deviceId, plate }: IShareTrackerLinkProps) {
  const classes = useStyles({});
  const [time, setTime] = useState('1h');
  const [link, setLink] = useState('');
  const [copied, setCopied] = useState(false);

  const navigationShareIsAvailable = !!navigator.share;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLink('');
    setTime(event.target.value);
  };

  const handleGenereteLink = async () => {
    setLink('');
    const resp = await SharedTrackerService.generateToken(
      deviceId,
      plate,
      time,
    );

    const token = resp.data.payload;
    setLink(`https://admin.onemonitora.com.br/shared_tracking/${token}`);
  };

  const successCopied = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };

  const handleCopyToClipboard = async () => {
    const copied = copy(link);

    if (copied) {
      successCopied();
    }
  };

  const handleShareLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Rastreamento',
          text: plate ?? '',
          url: link,
        })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error));
    } else {
      console.log('navigator.share not available');
    }
  };

  return (
    <Card className={classes.cardContainer} style={{ margin: 0, padding: 0 }}>
      <CardContent>
        <Grid container className={classes.grid} spacing={3}>
          <Grid item xs={6} className={classes.text}>
            <TextField
              select
              value={time}
              onChange={handleChange}
              fullWidth
              InputProps={{ className: classes.input }}
            >
              {options.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleGenereteLink}
              variant="contained"
              color="primary"
              fullWidth
              disabled={!deviceId || !plate}
              size="small"
              style={{ fontSize: 10 }}
            >
              Gerar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="link"
              fullWidth
              value={link}
              classes={{}}
              InputLabelProps={{ style: { fontSize: 14 } }}
              InputProps={{
                endAdornment: (
                  <>
                    {navigationShareIsAvailable ? (
                      <IconButton onClick={handleShareLink} disabled={!link}>
                        <Share fontSize="small" />
                      </IconButton>
                    ) : (
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        open={copied}
                        placement="top"
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Copiado!"
                      >
                        <IconButton
                          onClick={handleCopyToClipboard}
                          disabled={!link}
                        >
                          <FileCopy fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                ),
                readOnly: true,
                className: classes.input,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
