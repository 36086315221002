import * as React from 'react';

export function lazyImport<
  T extends React.ComponentType<any>,
  I extends { [K2 in K]: T },
  K extends keyof I,
  // eslint-disable-next-line prettier/prettier
  >(factory: () => Promise<I>, name: K): I {
  return Object.create({
    [name]: React.lazy(() =>
      factory().then(module => ({ default: module[name] })),
    ),
  });
}

// exemplo
// const { Home } = lazyImport(() => import("./Home"), "Home");
