import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import { GridSettings } from './GridSettings';
import { MapSettings } from './MapSettings';
import { SettingsLabel } from '../components/SettingsLabel';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      className={classes.tabPanel}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export function GridSettingsMenu() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const toggleBackdrop = () => setIsLoading(prev => !prev);

  return (
    <>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          className={classes.tabs}
        >
          <Tab label="Mapa" {...a11yProps(0)} />
          <Tab label="Grid" {...a11yProps(1)} />
          <Tab label="Rótulos" {...a11yProps(2)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <MapSettings toggleBackdrop={toggleBackdrop} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GridSettings toggleBackdrop={toggleBackdrop} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SettingsLabel toggleBackdrop={toggleBackdrop} />
        </TabPanel>
      </div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
