/* eslint-disable import/no-duplicates */
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';

export function DateTimeFormat({ date }: { date: string }): JSX.Element {
  if (date === undefined) return <>-</>;

  const formatedDate = format(
    new Date(date.replace(' ', 'T')),
    'dd/MM/yyyy HH:mm:ss',
    {
      locale: ptBR,
    },
  );
  return <>{formatedDate}</>;
}
