import axios, { AxiosResponse } from 'axios';
import { TPosition } from 'types/position';
import { REACT_APP_API_URL } from 'config';

const Api = axios.create({
  baseURL: REACT_APP_API_URL,
});

interface IDecodedToken {
  deviceId: string;
  plate: string;
  iat: number;
  exp: number;
  sub: any;
}

export const SharedTrackerService = {
  verifyToken: (
    token: string,
  ): Promise<AxiosResponse<{ message: string; payload: IDecodedToken }>> =>
    Api.get('/shared_tracker/decode', { params: { token } }),
  generateToken: (
    deviceId: string,
    plate: string,
    expiresIn: string,
  ): Promise<AxiosResponse<{ message: string; payload: string }>> =>
    Api.post('/shared_tracker', { deviceId, plate, expiresIn }),
  lastPosition: (
    deviceId: string,
    token: string,
  ): Promise<AxiosResponse<{ message: string; payload: TPosition }>> =>
    Api.get(`/positions/last_position/${deviceId}`, {
      headers: { Authorization: `Bearer ${token}` },
    }),
};
