/* eslint-disable no-return-assign */
import { useAuth } from 'context/authContext';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { IEvent } from 'types/Event';
import { EventService, IListUnreadEventByClientId } from './eventService';

const REFETCH_INTERVAL = 5 * 60000;
interface IPaginateEventsResponse {
  events: IEvent[];
  currentPage: number;
  totalItems: number;
  totalPages: number;
  hasNextPage: boolean;
  itemsPerPage: number;
}

async function listUnreadEvents({
  page = 1,
  accessType,
  clientId,
  eventType = [],
  size = 20,
}: IListUnreadEventByClientId): Promise<IPaginateEventsResponse> {
  const response = await EventService.unreadEventsByClientId({
    accessType,
    clientId,
    size,
    page,
    eventType,
  });

  const { message, payload } = response.data;
  if (message === 'success') {
    const {
      events,
      currentPage,
      totalItems,
      totalPages,
      hasNextPage,
      itemsPerPage,
    } = payload;
    return {
      events,
      currentPage,
      totalItems,
      totalPages,
      hasNextPage,
      itemsPerPage,
    };
  }

  return {
    events: [] as IEvent[],
    currentPage: 0,
    totalItems: 0,
    totalPages: 0,
    hasNextPage: false,
    itemsPerPage: 0,
  };
}

export function useEventsVehicles(): UseInfiniteQueryResult<
  IPaginateEventsResponse,
  unknown
> {
  const { user } = useAuth();
  const { permissions, visualizedEvents, vehicles, accessType, id } = user;
  const slugs =
    visualizedEvents &&
    visualizedEvents.map(({ slug }: { slug: string }) => slug);

  const eventQuery = useInfiniteQuery(
    'unreadEvents',
    ({ pageParam }) =>
      listUnreadEvents({
        page: pageParam,
        accessType,
        clientId: id,
        eventType: slugs,
        size: 30,
      }),
    {
      getNextPageParam: lastPage => {
        if (lastPage.currentPage < lastPage.totalPages)
          return lastPage.currentPage + 1;
        return undefined;
      },
      refetchInterval: REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: Boolean(permissions.includes('events') && vehicles.length),
    },
  );

  return eventQuery;
}
