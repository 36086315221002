import {
  Backdrop,
  Fade,
  IconButton,
  makeStyles,
  Modal,
  ModalProps,
  Theme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

type props = {
  isOpen: boolean;
  handleClose(): void;
  children: JSX.Element;
  modalProps?: React.ComponentType<ModalProps>;
  paperProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  clickAwayToClose?: boolean;
  width?: string | number;
};

type styleProps = {
  width: string | number;
};

const useStyles = makeStyles<Theme, styleProps>(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: props => props.width,
    margin: 'auto',
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
  },
}));

export function CustomModal({
  isOpen = false,
  handleClose,
  children,
  modalProps,
  paperProps,
  clickAwayToClose = false,
  width = '70%',
}: props): JSX.Element {
  const classes = useStyles({ width });
  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={clickAwayToClose ? handleClose : undefined}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...modalProps}
    >
      <Fade in={isOpen}>
        <div className={classes.paper} {...paperProps}>
          <IconButton className={classes.closeBtn} onClick={handleClose}>
            <Close />
          </IconButton>
          {children}
        </div>
      </Fade>
    </Modal>
  );
}
