import { Tooltip } from '@material-ui/core';
import {
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularConnectedNoInternet0Bar,
} from '@material-ui/icons';
import { useCallback } from 'react';

import { useStyles } from './styles';

interface GsmSignalIconProps {
  signal: number;
}

export function GsmSignalIcon({ signal }: GsmSignalIconProps) {
  const classes = useStyles();

  const renderIcon = useCallback(() => {
    if (signal === undefined) return <>-</>;
    if (Number(signal) === 0)
      return (
        <SignalCellularConnectedNoInternet0Bar
          fontSize="small"
          className={classes.noSignal}
        />
      );
    if (Number(signal) < 25)
      return (
        <SignalCellular1Bar fontSize="small" className={classes.lowSignal} />
      );
    if (Number(signal) < 50)
      return (
        <SignalCellular2Bar fontSize="small" className={classes.highSignal} />
      );
    if (Number(signal) < 80)
      return (
        <SignalCellular3Bar fontSize="small" className={classes.highSignal} />
      );
    return (
      <SignalCellular4Bar fontSize="small" className={classes.highSignal} />
    );
  }, [signal, classes]);

  return (
    <Tooltip title={`${signal}%`} arrow placement="right">
      {renderIcon()}
    </Tooltip>
  );
}
