import axios from 'axios';
import { storage } from 'utils';
import { REACT_APP_API_URL } from '../config';

type PayloadInfos = {
  new_password: string;
  password_confirmation: string;
  password: string;
};

const Api = axios.create({
  baseURL: REACT_APP_API_URL,
});
function getToken() {
  const localToken = storage.getItem({ key: 'user', storageType: 'local' });
  const sessionToken = storage.getItem({ key: 'user', storageType: 'session' });

  if (localToken) {
    return {
      ...localToken,
      type: 'local',
    };
  }

  if (sessionToken) {
    return {
      ...sessionToken,
      type: 'session',
    };
  }

  return null;
}

export async function changePassword(data: PayloadInfos) {
  const { token } = getToken();

  await Api.patch('/client/change_password', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return true;
}
