import {
  AppBar,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  Box,
  Badge,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import {
  MoreVert,
  Search,
  Notifications,
  ExitToApp,
  Settings,
  ArrowBack,
} from '@material-ui/icons';

import { Can, InfoSidebar } from 'components';
import { useState, useEffect } from 'react';
import { useAuth } from 'context/authContext';
import { Link } from 'components/Elements';
import { EventService, useEventsVehicles } from 'features/Map';
import { useDisclosure } from 'hooks';
import { IEvent } from 'types/Event';
import { queryClient } from 'lib/react-query';
import { LocalThemeProvider } from 'context/LocalThemeProvider';
import { SettingsMenu } from './SettingsMenu';
import { ProfileSection } from '../components/ProfileSection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      background: theme.palette.background.default,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    navigator: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

interface HeaderProps {
  title?: string;
  goBack?: boolean;
  openSearch?: () => void;
  searchContent?: React.ReactNode;
  contextMenuContent?: [
    {
      id: number;
      label: string;
      icon: React.ReactNode;
      action: () => void;
    },
  ];
  navigator?: [
    {
      title: string;
      icon: React.ElementType;
      path: string;
    },
  ];
}

export function Header({
  title = '',
  openSearch = () => {},
  searchContent,
  contextMenuContent,
  navigator,
  goBack,
}: HeaderProps) {
  const classes = useStyles();
  const { SignOut } = useAuth();
  const { data: events, fetchNextPage, hasNextPage } = useEventsVehicles();
  // const { data: lastEvents } = useLastEvents();

  const { open, isOpen, toggle } = useDisclosure();
  const settingsMenu = useDisclosure();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [unreadEvents, setUnreadEvents] = useState<IEvent[] | undefined>([]);
  const [unreadEventsCount, setUnreadEventsCount] = useState<number>(0);
  // const [listLastEvents, setlistLastEvents] = useState<any[] | undefined>([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const mergedPages: IEvent[] = [];
    if (events?.pages) {
      events.pages.map(page => mergedPages.push(...page.events));
      setUnreadEvents(mergedPages);
      setUnreadEventsCount(events?.pages[0]?.totalItems);
    }
  }, [events]);

  // useEffect(() => {
  //   setlistLastEvents(lastEvents);
  // }, [lastEvents]);

  const handleRemoveEvent = async (id: string) => {
    try {
      const unreadEventsUpdate = {
        pages: events?.pages.map(page => {
          return {
            ...page,
            totalItems: page.totalItems - 1,
            events: page.events.filter(val => val.id !== id),
          };
        }),
        pageParams: events?.pageParams,
      };
      queryClient.setQueryData('unreadEvents', unreadEventsUpdate);
      await EventService.read(id);
      // queryClient.invalidateQueries('unreadEvents');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar>
          {goBack && (
            <Link to="..">
              <Tooltip arrow title="Voltar" placement="right">
                <IconButton edge="start">
                  <ArrowBack />
                </IconButton>
              </Tooltip>
            </Link>
          )}
          {title && (
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.title}
            >
              {title}
            </Typography>
          )}
          <Box position="absolute" right={10}>
            <Can permissions={['events']}>
              <IconButton
                aria-label="notifications"
                color="default"
                edge="start"
                onClick={open}
              >
                <Badge
                  badgeContent={unreadEventsCount}
                  max={999}
                  color="primary"
                >
                  <Notifications />
                </Badge>
              </IconButton>
            </Can>
            {openSearch && (
              <IconButton
                aria-label="search"
                color="default"
                edge="end"
                onClick={openSearch}
              >
                <Search />
              </IconButton>
            )}
            <IconButton
              aria-label="display more actions"
              edge="end"
              color="default"
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
          </Box>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {contextMenuContent &&
              contextMenuContent.map(item => {
                return (
                  <MenuItem
                    key={item.id}
                    onClick={() => {
                      item.action();
                      handleClose();
                    }}
                  >
                    {item.icon}
                    {item.label}
                  </MenuItem>
                );
              })}
            {navigator?.length &&
              navigator.map(({ icon: Icon, title, path }) => (
                <MenuItem key={title}>
                  <Link to={path} className={classes.navigator}>
                    <Icon />
                    {title}
                  </Link>
                </MenuItem>
              ))}
            <MenuItem
              onClick={() => {
                settingsMenu.open();
                handleClose();
              }}
              className={classes.menuItem}
            >
              <Settings />
              Ajustes
            </MenuItem>
            <LocalThemeProvider>
              <ProfileSection />
            </LocalThemeProvider>
            <MenuItem onClick={SignOut} className={classes.menuItem}>
              <ExitToApp />
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {searchContent}
      <Can permissions={['events']}>
        <InfoSidebar
          isOpen={isOpen}
          toggleInfoSideBar={toggle}
          data={unreadEvents}
          handleRemoveEvent={handleRemoveEvent}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage || false}
        />
      </Can>
      <SettingsMenu open={settingsMenu.isOpen} onClose={settingsMenu.close} />
    </>
  );
}
