import { AxiosResponse } from 'axios';
import { IResponse } from 'types/Response';
import { User } from 'types/User';
import { Api } from '../lib/axios';

type LoginCredentials = {
  login: string;
  password: string;
};

export const SessionService = {
  login: ({
    login,
    password,
  }: LoginCredentials): Promise<AxiosResponse<IResponse<User>>> =>
    Api.post('/customer/session', { login, password }),
  logout: (token: string): Promise<void> =>
    Api.delete(`/customer/session/${token}`),
};
