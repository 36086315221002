import { useMediaQuery, useTheme } from '@material-ui/core';
import { Suspense } from 'react';
import { lazyImport } from 'utils/lazyImport';

const { DesktopVersion } = lazyImport(
  () => import('../components/DesktopVersion'),
  'DesktopVersion',
);
const { MobileVersion } = lazyImport(
  () => import('../components/MobileVersion'),
  'MobileVersion',
);

export function Map(): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Suspense fallback={<div>Loading</div>}>
        <MobileVersion />;
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<div>Loading</div>}>
      <DesktopVersion />;
    </Suspense>
  );
}
