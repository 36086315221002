import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  settings: {
    position: 'fixed',
    bottom: 0,
    left: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  lightModeChecked: {
    color: theme.palette.warning.light,
  },
  darkModeChecked: {
    color: theme.palette.grey.A100,
  },
}));
