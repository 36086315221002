import React, { ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Check, Close } from '@material-ui/icons';

interface AlertDialogProps {
  isOpen: boolean;
  onAgree: () => void;
  onDisagree: () => void;
  onClose: () => void;
  message: string | ReactNode;
  showButtons: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog({
  isOpen,
  message,
  showButtons,
  onAgree,
  onDisagree,
  onClose,
}: AlertDialogProps) {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      {showButtons && (
        <DialogActions>
          <Button onClick={onAgree} color="primary">
            <Check fontSize="small" />
          </Button>
          <Button onClick={onDisagree} color="primary">
            <Close fontSize="small" />
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
