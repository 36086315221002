import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import { ChangeEvent, useState } from 'react';
import { useStyles } from './styles';

interface CommandsInfoProps {
  handleSendCommand: (command: string) => void;
}

export function CommandsInfo({ handleSendCommand }: CommandsInfoProps) {
  const classes = useStyles({});
  const [selectedCommand, setSelectedCommand] = useState('');

  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    setSelectedCommand(String(event.target.value));
  };

  const sendCommand = () => {
    handleSendCommand(selectedCommand);
  };

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        {/* <Typography gutterBottom variant="h6" component="h2">
            Último posicionamento
          </Typography> */}
        {/* <Divider /> */}

        <FormControl style={{ minWidth: 100 }} size="small">
          <InputLabel id="demo-simple-select-label">Comando</InputLabel>
          <Select value={selectedCommand} onChange={handleChange}>
            <MenuItem value="enable_output_1">bloqueio</MenuItem>
            <MenuItem value="disable_output_1">desbloqueio</MenuItem>
          </Select>
        </FormControl>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={sendCommand}
        >
          Enviar
        </Button>
      </CardActions>
    </Card>
  );
}
