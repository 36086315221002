import {
  Backdrop,
  Button,
  CircularProgress,
  Box,
  makeStyles,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import toastContext from 'context/toastContext';
import { ChangeEventHandler, useRef, useState } from 'react';
import { changePassword } from 'services/changePassword';
// import { changePassword } from 'services/changePassword';
import { PasswordField } from '../PasswordField';

type PayloadInfos = {
  new_password: string;
  password_confirmation: string;
  password: string;
};

const useChangePasswordStyle = makeStyles(({ zIndex }) => ({
  backdrop: {
    zIndex: zIndex.tooltip,
  },
}));

type ChangePasswordProps = {
  onFinish?: () => void;
};

type ErrorMessages = Record<string, string>;
export function ChangePassword({ onFinish }: ChangePasswordProps) {
  const classes = useChangePasswordStyle();
  const infosRef = useRef<{ payload: PayloadInfos }>({
    payload: {
      new_password: '',
      password_confirmation: '',
      password: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorInfos, setErrorInfos] = useState<ErrorMessages>({});
  const fieldHandler: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => {
    const { id, value } = e.target;
    infosRef.current.payload[id as unknown as keyof PayloadInfos] = value;
  };
  const fieldsVerify = () => {
    const { new_password, password, password_confirmation } =
      infosRef.current.payload;
    const allMajorThanSix = [
      new_password,
      password,
      password_confirmation,
    ].every(fieldValues => fieldValues.length >= 6);

    if (!allMajorThanSix) {
      Object.entries(infosRef.current.payload).forEach(([field, message]) => {
        if (message.length < 6) {
          setErrorInfos(old => ({
            ...old,
            [field]: 'O campo deve ter no mínimo 6 dígitos',
          }));
        }
      });
      return toastContext.error('A senha deve ter no minimo 6 dígitos');
    }
    if (new_password !== password_confirmation) {
      setErrorInfos(old => ({
        ...old,
        password_confirmation: 'Campo inválido',
      }));
      return toastContext.error(
        'Confirmação de senha inválida, verifique o campo',
      );
    }

    return {
      new_password,
      password,
      password_confirmation,
    };
  };
  return (
    <>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="form"
        onSubmit={e => {
          e.preventDefault();
          setErrorInfos({});
          const fields = fieldsVerify();
          if (!fields) return undefined;
          const { new_password, password, password_confirmation } = fields;

          setIsLoading(true);
          changePassword({
            new_password,
            password,
            password_confirmation,
          })
            .then(() => {
              toastContext.success('Senha alterada com sucesso!');
              if (onFinish) onFinish();
              setErrorInfos({});
            })
            .catch(e => {
              const error = e as AxiosError;
              if (error.response) {
                toastContext.error(error.response.data.message);
                setErrorInfos(old => ({
                  ...old,
                  password: 'Verifique o campo',
                }));
              }
            })
            .finally(() => {
              setIsLoading(false);
            });

          return undefined;
        }}
      >
        <section
          style={{
            display: 'grid',
            margin: '20px 0',
            justifyContent: 'stretch',
            gap: 16,
          }}
        >
          <PasswordField
            onChange={fieldHandler}
            id="new_password"
            label="Nova senha"
            errorMessage={errorInfos.new_password}
          />
          <PasswordField
            onChange={fieldHandler}
            id="password_confirmation"
            label="Confirme a nova senha"
            errorMessage={errorInfos.password_confirmation}
          />
          <PasswordField
            onChange={fieldHandler}
            id="password"
            label="Senha atual"
            errorMessage={errorInfos.password}
          />
        </section>

        <Button color="primary" variant="contained" type="submit" fullWidth>
          Confirmar
        </Button>
      </Box>
    </>
  );
}
