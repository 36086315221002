import { createContext, useCallback, useContext, useState } from 'react';
import { storage } from 'utils/storage';

interface VisualizeLabelProvider {
  children: React.ReactNode;
}

interface VisualizeLabelContextData {
  toggleVisualizeLabel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  visualizeLabel: boolean;
}

const VisualizeLabelContext = createContext({} as VisualizeLabelContextData);

function VisualizeLabelProvider({
  children,
}: VisualizeLabelProvider): JSX.Element {
  const [visualizeLabel, setVisualizeLabel] = useState(() => {
    const visualizeLabel = storage.getItem({
      key: 'visualizeLabel',
      storageType: 'local',
    });
    if (visualizeLabel === 'label') {
      return true;
    }
    return false;
  });

  const toggleVisualizeLabel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      setVisualizeLabel(checked);
      const values = checked ? 'label' : 'plate';
      storage.setItem({ key: 'visualizeLabel', storageType: 'local', values });
    },
    [],
  );

  return (
    <VisualizeLabelContext.Provider
      value={{ toggleVisualizeLabel, visualizeLabel }}
    >
      {children}
    </VisualizeLabelContext.Provider>
  );
}

function useVisualizeLabel(): VisualizeLabelContextData {
  const context = useContext(VisualizeLabelContext);

  if (!context) {
    throw new Error(
      'useVisualizeLabel must be used whit a VisualizeLabelProvider.',
    );
  }

  return context;
}

export { VisualizeLabelProvider, useVisualizeLabel };
