import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  online: {
    color: theme.palette.success.main,
    width: 60,
    fontSize: 10,
  },
  recently: {
    color: theme.palette.info.main,
    width: 60,
    fontSize: 10,
  },
  shortTime: {
    color: theme.palette.warning.main,
    width: 60,
    fontSize: 10,
  },
  longTime: {
    color: theme.palette.error.main,
    width: 60,
    fontSize: 10,
  },
  offline: {
    width: 60,
    fontSize: 10,
  },
}));
