import { Tooltip } from '@material-ui/core';
import { PowerOff } from '@material-ui/icons';
import { useStyles } from './styles';

export function BateryVoltage({ voltage }: { voltage: string }): JSX.Element {
  const classes = useStyles();
  if (voltage === undefined) return <>-</>;
  if (Number(voltage) === 0) {
    return (
      <Tooltip title={`${voltage}V`} arrow placement="right">
        <PowerOff fontSize="small" className={classes.off} />
      </Tooltip>
    );
  }
  if (Number(voltage) <= 5) {
    return <strong className={classes.lowVoltage}>{voltage}V</strong>;
  }
  return <strong className={classes.on}>{voltage}V</strong>;
}
