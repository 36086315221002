import { Fallback } from 'components';
import { Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { lazyImport } from 'utils/lazyImport';

const { AuthRoutes } = lazyImport(() => import('features/Login'), 'AuthRoutes');
const { ValidateLinkRoutes } = lazyImport(
  () => import('features/ValidateLink'),
  'ValidateLinkRoutes',
);

const PublicRoutes = () => {
  return (
    <>
      <Suspense fallback={<Fallback />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export const publicRoutes = [
  {
    path: '/',
    element: <PublicRoutes />,
    children: [
      { path: '/', element: <AuthRoutes /> },
      { path: '/access/*', element: <ValidateLinkRoutes /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
