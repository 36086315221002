/* eslint-disable prettier/prettier */
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import { useCallback, useState } from 'react';
import { deviceGridColumns } from 'config/configColumns'
import { useAuth } from 'context/authContext';

import {useStyles} from './style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'center' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'center' as const,
  },
  variant: 'menu' as const,
};

interface DevicesGridSettingsProps {
  toggleBackdrop: () => void;
}

export function GridSettings({ toggleBackdrop }: DevicesGridSettingsProps) {
  const classes = useStyles();
  const { user, updateCustomerSettings } = useAuth();

  const [hasUpdate, setHasUpdate] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    user.settings?.grid?.deviceGridColumns ?? [],
  );

  const isAllSelected =
    deviceGridColumns.length > 0 &&
    selectedOptions.length === deviceGridColumns.length;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setHasUpdate(true);
    const value = event.target.value as string[];
    if (value[value.length - 1] === 'all') {
      const isSelectedAll = selectedOptions.length === deviceGridColumns.length;
      if (isSelectedAll) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions(() => deviceGridColumns.map(v => v.name));
      }
    } else {
      setSelectedOptions(event.target.value as string[]);
    }
  };


  const handleSave = useCallback(async () => {
    if (hasUpdate) {
      toggleBackdrop();
      const data = {
        grid: {
          deviceGridColumns: selectedOptions,
        },
      };
      try {
        await updateCustomerSettings(data);
        setHasUpdate(false);
        // toast.success('Sucesso!');
      } catch (error) {
        console.log('#error', error);
        // toast.error('Erro ao salvar configurações');
      }
      toggleBackdrop();
    }
  }, [hasUpdate, selectedOptions, toggleBackdrop, updateCustomerSettings]);

  const handleDelete = useCallback(name => {
    setHasUpdate(true);
    setSelectedOptions(prev => prev.filter(p => p !== name));
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="mutiple-select-label">Colunas</InputLabel>
            <Select
              labelId="mutiple-select-label"
              multiple
              fullWidth
              value={selectedOptions}
              onChange={handleChange}
              renderValue={selected => (
                <>{(selected as string[]).length} selecionados</>
              )}
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllSelected ? classes.selectedAll : '',
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllSelected}
                    indeterminate={
                      selectedOptions.length > 0 &&
                      selectedOptions.length < deviceGridColumns.length
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="Selecionar todos"
                />
              </MenuItem>
              {deviceGridColumns.length &&
                deviceGridColumns.map(column => (
                  <MenuItem key={column.id} value={column.name}>
                    <Checkbox
                      checked={selectedOptions.indexOf(column.name) > -1}
                    />
                    <ListItemText primary={column.label} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <Paper component="ul" className={classes.selectedOptions}>
            {deviceGridColumns.length && selectedOptions.length
              ? deviceGridColumns
                .filter(dc => selectedOptions.includes(dc.name))
                .sort((a, b) => a.id - b.id)
                .map(data => {
                  return (
                    <li key={data.id}>
                      <Chip
                        label={data.label}
                        size="small"
                        onDelete={() => handleDelete(data.name)}
                        className={classes.chip}
                      />
                    </li>
                  );
                })
              : null}
          </Paper>
        </Grid>
      </Grid>
      <div className={classes.btnSave}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          size="small"
          onClick={handleSave}
          disabled={!hasUpdate}
        >
          Salvar
        </Button>
      </div>
    </div>
  );
}
