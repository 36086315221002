/* eslint-disable import/no-duplicates */
/* eslint-disable no-return-assign */
import { TransitionGroup } from 'react-transition-group';
import {
  ListItem,
  Typography,
  IconButton,
  Divider,
  ListItemText,
  useTheme,
  useMediaQuery,
  Collapse,
  LinearProgress,
} from '@material-ui/core';
import {
  CloseRounded,
  ArrowForwardIosRounded,
  BatteryAlert,
  Info,
  LocationOff,
  Speed,
} from '@material-ui/icons';
import { formatDistance, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ReactNode, useCallback } from 'react';
import { IEvent } from 'types';
import { SwipeableTemporaryDrawer } from '../SwipeableDrawer';

import { useStyles } from './styles';

interface InfoSiderBarProps {
  isOpen: boolean;
  toggleInfoSideBar: () => void;
  handleRemoveEvent: (id: string) => void;
  data: IEvent[] | undefined;
  fetchNextPage: () => void;
  hasNextPage: boolean;
}

export const InfoSidebar = ({
  isOpen,
  toggleInfoSideBar,
  handleRemoveEvent,
  data = [],
  fetchNextPage,
  hasNextPage,
}: InfoSiderBarProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderEventIcon = useCallback((eventSlug: string) => {
    const icons: { [key: string]: ReactNode } = {
      info: <Info fontSize="small" />,
      alarm_against_external_power_cut: <BatteryAlert fontSize="small" />,
      gps_malfunction: <LocationOff fontSize="small" />,
      harsh_acceleration: <Speed fontSize="small" />,
    };
    return icons[eventSlug] || icons.info;
  }, []);

  const renderItem = (item: IEvent) => {
    const now = new Date();
    const eventDate = parseISO(item.dateTime);
    const time = formatDistance(eventDate, now, {
      locale: pt,
    });

    return (
      <ListItem className={classes.card}>
        <div>
          <ListItemText
            primary={
              <>
                {renderEventIcon(item.eventSlug)}
                {time}
              </>
            }
            secondary={
              <>
                <Typography
                  component="span"
                  variant="button"
                  color="textPrimary"
                  gutterBottom
                >
                  {item.event}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <br />
                  {`PLACA: ${item.vehicle?.plate}`}
                  <br />
                  {`MODELO: ${item.vehicle?.model}`}
                  <br />
                  {format(eventDate, 'dd/MM/yyyy HH:mm:ss')}
                </Typography>
              </>
            }
          />
          <IconButton
            size="small"
            className={classes.closeCardButton}
            onClick={() => handleRemoveEvent(item.id)}
          >
            <CloseRounded fontSize="small" />
          </IconButton>
        </div>
      </ListItem>
    );
  };

  return (
    <SwipeableTemporaryDrawer isOpen={isOpen}>
      <div style={{ maxWidth: '100%', minHeight: '100%' }}>
        <Typography>
          <IconButton aria-label="closeDrawer" onClick={toggleInfoSideBar}>
            <ArrowForwardIosRounded />
          </IconButton>
          Eventos últimas 24hs
        </Typography>
        <Divider light />

        <div
          id="scrollableDiv"
          style={{
            width: isMobile ? '90vw' : 400,
            height: '95%',
            overflow: 'auto',
          }}
        >
          <InfiniteScroll
            dataLength={data.length}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={<LinearProgress />}
            scrollableTarget="scrollableDiv"
            scrollThreshold="200px"
          >
            <TransitionGroup>
              {data.map(item => (
                <Collapse key={item.id}>{renderItem(item)}</Collapse>
              ))}
            </TransitionGroup>
          </InfiniteScroll>
        </div>
      </div>
    </SwipeableTemporaryDrawer>
  );
};
