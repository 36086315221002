import { IVehicle } from 'features/Vehicle';
import googleMapReact, { Coords } from 'google-map-react';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { mapThemes } from 'styles/mapThemes';
// import useSupercluster from 'use-supercluster';
import { useAuth } from './authContext';

interface GeofenceProviderProps {
  children: ReactNode;
}

enum ActionTypes {
  onMapLoaded = 'onMapLoaded',
  onMapChange = 'onMapChange',
  setVehiclesOnMap = 'setVehiclesOnMap',
  setOpenInfoWindow = 'setOpenInfoWindow',
}

type Google = {
  map: google.maps.Map | null;
  maps: any;
  ref: any;
};

type State = {
  mapIsLoaded: boolean;
  googleMaps: Pick<Google, 'map' | 'maps'>;
  zoom: number;
  bounds: googleMapReact.Bounds;
  vehiclesOnMap: IVehicle[];
  openInfoWindowIndex: number | null;
  currentZoom: number | undefined;
  currentCoords: Coords | undefined;
};

type Action<T> = {
  type: ActionTypes;
  payload?: T;
};
interface MapContextData {
  onMapLoaded: (google: Google) => void;
  onMapChange: (event: googleMapReact.ChangeEventValue) => void;
  onOpenInfoWindow: (
    infoWindowIndex: number,
    currentZoom: number,
    currentCoords: Coords,
  ) => void;
  onCloseInfoWindow: () => void;
  setVehiclesOnMap: (vehicles: IVehicle[]) => void;
  state: State;
  mapRef: any;
}

const initialState: State = {
  mapIsLoaded: false,
  googleMaps: {
    map: null,
    maps: null,
  },
  zoom: 10,
  bounds: {
    nw: {
      lat: 0,
      lng: 0,
    },
    se: {
      lat: 0,
      lng: 0,
    },
    sw: {
      lat: 0,
      lng: 0,
    },
    ne: {
      lat: 0,
      lng: 0,
    },
  },
  vehiclesOnMap: [],
  openInfoWindowIndex: null,
  currentZoom: undefined,
  currentCoords: undefined,
};

const MapContext = createContext({} as MapContextData);

const mapReducer = (state: State, action: Action<any>): State => {
  switch (action.type) {
    case ActionTypes.onMapLoaded:
      return {
        ...state,
        mapIsLoaded: !!action.payload,
        googleMaps: action.payload,
      };

    case ActionTypes.onMapChange:
      return {
        ...state,
        zoom: action.payload.zoom,
        bounds: action.payload.bounds,
      };

    case ActionTypes.setVehiclesOnMap:
      return {
        ...state,
        vehiclesOnMap: action.payload,
      };

    case ActionTypes.setOpenInfoWindow:
      return {
        ...state,
        openInfoWindowIndex: action.payload,
      };

    default:
      return state;
  }
};

function MapProvider({ children }: GeofenceProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(mapReducer, initialState);

  const { user } = useAuth();
  const mapRef = useRef<any>(null);

  useEffect(() => {
    if (state.googleMaps.map) {
      if (user?.settings?.map?.theme) {
        state.googleMaps.map.setOptions({
          styles: mapThemes[user.settings.map.theme] ?? mapThemes.light,
        });
      }
    }
  }, [state.googleMaps.map, user?.settings?.map?.theme, user?.settings?.theme]);

  const onMapLoaded = useCallback((googleMaps: Google) => {
    mapRef.current = googleMaps.map;
    dispatch({
      type: ActionTypes.onMapLoaded,
      payload: {
        map: googleMaps.map,
        maps: googleMaps.maps,
      },
    });
  }, []);

  const onMapChange = useCallback((event: googleMapReact.ChangeEventValue) => {
    dispatch({ type: ActionTypes.onMapChange, payload: event });
  }, []);

  const onOpenInfoWindow = useCallback(
    (infoWindowIndex: number, currentZoom: number, currentCoords: Coords) => {
      state.googleMaps.map?.setCenter(currentCoords);
      dispatch({
        type: ActionTypes.setOpenInfoWindow,
        payload: infoWindowIndex,
      });
    },
    [state.googleMaps],
  );

  const onCloseInfoWindow = useCallback(() => {
    dispatch({
      type: ActionTypes.setOpenInfoWindow,
      payload: null,
    });
  }, []);

  const setVehiclesOnMap = useCallback((vehicles: IVehicle[]) => {
    dispatch({ type: ActionTypes.setVehiclesOnMap, payload: vehicles });
  }, []);

  return (
    <MapContext.Provider
      value={{
        state,
        onMapLoaded,
        onMapChange,
        onOpenInfoWindow,
        onCloseInfoWindow,
        setVehiclesOnMap,
        mapRef,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

function useMap(): MapContextData {
  const context = useContext(MapContext);

  if (!context) {
    throw new Error('useMap must be used within a mapProvider');
  }

  return context;
}

export { MapProvider, useMap };
