import React from 'react';
import {
  MapOutlined,
  SupervisorAccount,
  DriveEta,
  Timeline,
} from '@material-ui/icons';
import { Can, Link } from 'components';
import { BottomNavigationAction } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';

const menuItems = [
  {
    url: '/map',
    tooltip: {
      title: 'Mapa',
    },
    icon: <MapOutlined fontSize="small" />,
  },
  {
    url: '/subaccounts',
    tooltip: {
      title: 'Subcontas',
    },
    icon: <SupervisorAccount fontSize="small" />,
    can: ['subaccounts'],
  },
  /*   {
    url: '/geofence',
    tooltip: {
      title: 'Cerca',
    },
    icon: <CropFree fontSize="small" />,
    can: ['geofence'],
  }, */
  {
    url: '/trace',
    tooltip: {
      title: 'Historico',
    },
    icon: <Timeline fontSize="small" />,
    can: ['reports'],
  },
  {
    url: '/vehicles',
    tooltip: {
      title: 'Veículos',
    },
    icon: <DriveEta fontSize="small" />,
  },
];

export function BottomNavItems() {
  const path = useLocation();
  const classes = useStyles();
  const factory = menuItems.map(({ can, url, icon, tooltip }) => {
    const Content = () => (
      <Link to={url} className={classes.selected}>
        <BottomNavigationAction
          label={tooltip.title}
          showLabel={path.pathname.startsWith(url)}
          icon={icon}
          className={
            path.pathname.startsWith('/subaccounts')
              ? classes.selected
              : classes.content
          }
        />
      </Link>
    );
    if (can) {
      return (
        <Can permissions={can} key={url}>
          <Content />
        </Can>
      );
    }
    return <Content key={url} />;
  });
  return <>{factory}</>;
}
