import { Fallback, Navbar } from 'components';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { lazyImport } from 'utils/lazyImport';

const { MapRoutes } = lazyImport(() => import('features/Map'), 'MapRoutes');
const { TraceRoutes } = lazyImport(
  () => import('features/Trace'),
  'TraceRoutes',
);
// const { GeofenceRoutes } = lazyImport(
//   () => import('features/GeoFence'),
//   'GeofenceRoutes',
// );
const { SubaccountsRoutes } = lazyImport(
  () => import('features/SubAccounts'),
  'SubaccountsRoutes',
);

const { VehiclesRoutes } = lazyImport(
  () => import('features/Vehicle'),
  'VehiclesRoutes',
);

const PrivateRoutes = () => {
  return (
    <>
      <Suspense fallback={<Fallback />}>
        <Navbar />
        <Outlet />
      </Suspense>
    </>
  );
};

export const protectedRoutes = [
  {
    path: '/',
    element: <PrivateRoutes />,
    children: [
      { path: '/map/*', element: <MapRoutes /> },
      { path: '/trace/*', element: <TraceRoutes /> },
      // { path: '/geofence/*', element: <GeofenceRoutes /> },
      { path: '/subaccounts/*', element: <SubaccountsRoutes /> },
      { path: '/vehicles/*', element: <VehiclesRoutes /> },
      { path: '/', element: <Navigate to="/map" /> },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
];
