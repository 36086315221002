import React from 'react';
import { MapOutlined, SupervisorAccount, Timeline } from '@material-ui/icons';
import { Can, Link } from 'components';
import { ListItemIcon, Tooltip, ListItem } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useStyle } from './styles';

const menuItems = [
  {
    url: '/map',
    tooltip: {
      title: 'Mapa',
    },
    icon: <MapOutlined fontSize="small" />,
  },
  {
    url: '/subaccounts',
    tooltip: {
      title: 'Subcontas',
    },
    icon: <SupervisorAccount fontSize="small" />,
    can: ['subaccounts'],
  },
  /* {
    url: '/geofence',
    tooltip: {
      title: 'Cerca',
    },
    icon: <CropFree fontSize="small" />,
    can: ['geofence'],
  }, */
  {
    url: '/trace',
    tooltip: {
      title: 'Historico',
    },
    icon: <Timeline fontSize="small" />,
    can: ['reports'],
  },
];

export function NavItems() {
  const classes = useStyle();
  const path = useLocation();
  const factory = menuItems.map(({ can, url, tooltip, icon }) => {
    const Content = () => (
      <Link to={url}>
        <Tooltip {...tooltip} placement="right" arrow interactive>
          <ListItem
            selected={path.pathname.startsWith(url)}
            className={classes.listItem}
          >
            <ListItemIcon classes={{ root: classes.listIcon }}>
              {icon}
            </ListItemIcon>
          </ListItem>
        </Tooltip>
      </Link>
    );
    if (can) {
      return (
        <Can permissions={can} key={url}>
          <Content />
        </Can>
      );
    }
    return <Content key={url} />;
  });

  return <>{factory}</>;
}
