import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { ChangeEventHandler, useState } from 'react';

type PasswordFieldProps = {
  label: string;
  id: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  errorMessage?: string;
};
export function PasswordField({ errorMessage, ...props }: PasswordFieldProps) {
  const [visibility, setVisibility] = useState(false);
  return (
    <TextField
      required
      variant="outlined"
      margin="normal"
      fullWidth
      type={visibility ? 'text' : 'password'}
      {...props}
      error={!!errorMessage}
      helperText={errorMessage}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setVisibility(prev => !prev)}
              onMouseDown={e => e.preventDefault()}
              edge="end"
            >
              {visibility ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
