import { Tooltip } from '@material-ui/core';
import { BatteryAlert } from '@material-ui/icons';
import { useStyles } from './styles';

interface BateryPercentProps {
  bateryPercent: string;
}

export function BateryPercent({ bateryPercent }: BateryPercentProps) {
  const classes = useStyles({ bateryPercent });
  if (bateryPercent === undefined) return <>-</>;
  if (Number(bateryPercent) === 0) {
    return (
      <Tooltip title={`${bateryPercent}%`} arrow placement="right">
        <BatteryAlert fontSize="small" className={classes.noBattery} />
      </Tooltip>
    );
  }
  return <strong className={classes.color}>{bateryPercent}%</strong>;
}
