import { Box, makeStyles, Theme, Paper } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { useRef } from 'react';

interface DirectionMarkerIconProps {
  direction: number;
  ignition: number;
  speed: number;
  label: string;
  visualizeLabel: boolean;
}

interface StyleProps {
  direction: number;
  ignition: number;
}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  iconDirection: {
    width: '80px',
    height: '80px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: ({ direction }) => `rotate(${direction - 21}deg)`,
    transition: 'transform 0.2s',
    pointerEvents: 'none',
    zIndex: 1,
    '&:hover': {
      transform: ({ direction }) => `rotate(${direction - 21}deg) scale(1.5)`,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      inset: '0',
      background: 'conic-gradient(rgb(51, 255, 51, 0.4) 15%, #0000 0)',
      transform: 'translate(-6px, 13px)',
      filter: 'blur(3px)',
      zIndex: 1,
    },
  },
  ignitionOnAndMoving: {
    width: 21,
    height: 21,
    backgroundColor: 'rgb(51, 255, 51)',
    border: '3px solid white',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'transform .2s',
    pointerEvents: 'auto',
    zIndex: 3000,
  },
  ignitionOnAndStopped: {
    width: 20,
    height: 20,
    backgroundColor: 'rgb(255,255,0)',
    border: '3px solid white',
    borderRadius: '50%',
    zIndex: 3000,
    cursor: 'pointer',
    transition: 'transform .2s',
    pointerEvents: 'auto',
    '&:hover': {
      transform: 'scale(1.5)',
    },
  },
  ignitionOff: {
    width: 20,
    height: 20,
    backgroundColor: 'rgb(255, 0, 0)',
    border: '3px solid white',
    borderRadius: '50%',
    zIndex: 3000,
    cursor: 'pointer',
    transition: 'transform .2s',
    pointerEvents: 'auto',
    transform: 'translate(0,0px)',
    '&:hover': {
      transform: 'scale(1.5)',
    },
  },

  labelCardBottom: {
    position: 'relative',
    // background: '#00aabb',
    transition: 'all .5s',
    borderRadius: '.4em',
    fontSize: 9,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '50%',
      width: '0',
      height: '0',
      border: '6px solid transparent',
      borderBottomColor: theme.palette.background.paper,
      borderTop: '0',
      marginLeft: '-6px',
      marginTop: '-6px',
    },
  },
  labelCardTop: {
    position: 'relative',
    transition: 'all .2s',
    borderRadius: '.4em',
    fontSize: 9,
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      left: '50%',
      width: '0',
      height: '0',
      border: '6px solid transparent',
      borderTopColor: theme.palette.background.paper,
      borderBottom: '0',
      marginLeft: '-6px',
      marginBottom: '-6px',
    },
  },
  labelCardLeft: {
    position: 'relative',
    transition: 'all .2s',
    borderRadius: '.4em',
    fontSize: 9,
    '&::after': {
      content: '""',
      position: 'absolute',
      right: '0',
      top: '50%',
      width: '0',
      height: '0',
      border: '6px solid transparent',
      borderLeftColor: theme.palette.background.paper,
      borderRight: '0',
      marginTop: '-5px',
      marginRight: '-5px',
    },
  },
  labelCardRight: {
    position: 'relative',
    transition: 'all .2s',
    borderRadius: '.4em',
    fontSize: 9,
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '50%',
      width: '0',
      height: '0',
      border: '6px solid transparent',
      borderRightColor: theme.palette.background.paper,
      borderLeft: '0',
      marginTop: '-5px',
      marginLeft: '-5px',
    },
  },
  labelText: {
    fontSize: 9,
  },
}));

export function DirectionMarkerIcon({
  direction,
  ignition,
  speed,
  label,
  visualizeLabel,
}: DirectionMarkerIconProps) {
  const classes = useStyles({ direction, ignition });
  const refLabel = useRef<any>(null);
  const isMoving = speed > 0 && ignition;
  let iconClass = '';
  let hoverTransform = '';
  const labelPositionStyle: CSSProperties = {
    position: 'absolute',
    transition: 'transform .2s',
  };
  let labelPositionClass = '';

  if (ignition === 0) {
    iconClass = classes.ignitionOff;
    labelPositionStyle.left = 30;
    labelPositionClass = classes.labelCardRight;
    hoverTransform = 'scale(1.5) translate(20px, 0)';
  } else if (speed > 0) {
    iconClass = classes.ignitionOnAndMoving;
    if (direction < 45) {
      labelPositionStyle.top = 70;
      labelPositionClass = classes.labelCardBottom;
      hoverTransform = 'scale(1.5) translate(0, 12px)';
    } else if (direction <= 120) {
      labelPositionStyle.right = 70;
      labelPositionClass = classes.labelCardLeft;
      hoverTransform = 'scale(1.5) translate(-20px, 0)';
    } else if (direction <= 220) {
      labelPositionStyle.bottom = 70;
      labelPositionClass = classes.labelCardTop;
      hoverTransform = 'scale(1.5) translate(0, -12px)';
    } else {
      labelPositionStyle.left = 70;
      labelPositionClass = classes.labelCardRight;
      hoverTransform = 'scale(1.5) translate(20px, 0)';
    }
  } else {
    iconClass = classes.ignitionOnAndStopped;
    labelPositionStyle.left = 30;
    labelPositionClass = classes.labelCardRight;
    hoverTransform = 'scale(1.5) translate(20px, 0)';
  }

  function onMouseEnterMarker() {
    refLabel.current.style.transform = hoverTransform;
    // if (!visualizeLabel) refLabel.current.style.display = 'block';
    if (!visualizeLabel) refLabel.current.style.visibility = 'visible';
  }
  function onMouseLeaveMarker() {
    refLabel.current.style.transform = 'scale(1) translate(0,0)';
    // if (!visualizeLabel) refLabel.current.style.display = 'none';
    if (!visualizeLabel) refLabel.current.style.visibility = 'hidden';
  }
  return (
    <>
      <Box
        className={clsx({
          [classes.iconDirection]: isMoving,
        })}
      >
        <Box
          className={iconClass}
          onMouseEnter={onMouseEnterMarker}
          onMouseLeave={onMouseLeaveMarker}
        />
      </Box>
      <div
        style={{
          ...labelPositionStyle,
          zIndex: -99999,
          // display: visualizeLabel ? 'block' : 'none',
          visibility: visualizeLabel ? 'visible' : 'hidden',
        }}
        ref={refLabel}
      >
        <Box
          className={labelPositionClass}
          style={{ zIndex: -9999 }}
          component={Paper}
          width="100%"
          minWidth="60px"
          maxWidth="100px"
          padding={0.5}
          zIndex={-1}
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <strong className={classes.labelText}>{label}</strong>
        </Box>
      </div>
    </>
  );
}
