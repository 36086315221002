import { useState } from 'react';
import { Box, TextField, Grid, Button, Typography } from '@material-ui/core';

import { useAuth } from 'context/authContext';
import { useVehicles, useUpdateVehicleLabel } from 'features/Vehicle';
import { useStyles } from './styles';

type FormValues = {
  label: string;
  vehicleId: string;
};

interface Props {
  toggleBackdrop: () => void;
}

export function SettingsLabel({ toggleBackdrop }: Props): JSX.Element {
  const classes = useStyles();
  const { user } = useAuth();
  const { data: vehicles } = useVehicles();
  const updateVehicleLabel = useUpdateVehicleLabel();
  const [formValues, setFormValues] = useState<FormValues>({
    label: '',
    vehicleId: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues(prev => {
      return { ...prev, [name]: value };
    });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    toggleBackdrop();
    updateVehicleLabel
      .mutateAsync({
        clientId: user.id,
        vehicleId: Number(formValues.vehicleId),
        label: formValues.label,
      })
      .finally(() => toggleBackdrop());
  };

  return (
    <div className={classes.root}>
      <Box component="form" height="200px" width="100%" onSubmit={onSubmit}>
        <Box display="flex" justifyContent="space-between">
          <Typography>Atualizar rótulo</Typography>
        </Box>
        <Grid container>
          <Grid
            item
            md={6}
            sm={12}
            style={{ padding: '0.5rem 0px', paddingRight: 4 }}
          >
            <TextField
              id="vehicles"
              name="vehicleId"
              label="Placas"
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event)
              }
              fullWidth
              select
              required
              SelectProps={{
                native: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <option value="">Selecione</option>
              {vehicles?.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.plate}
                </option>
              ))}
            </TextField>
          </Grid>

          <Grid item md={6} sm={12} style={{ padding: '0.5rem 0px' }}>
            <TextField
              id="label"
              name="label"
              label="Rótulo"
              variant="outlined"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(event)
              }
              fullWidth
              required
            />
          </Grid>
          <Button variant="contained" type="submit">
            Salvar
          </Button>
        </Grid>
      </Box>
    </div>
  );
}
