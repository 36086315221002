import { useQuery } from 'react-query';
import { EVENT_TYPES_QUERY_KEY } from 'config';
import { EventService } from './eventService';

const getEventTypes = async () => {
  const response = await EventService.eventTypes();

  return response.data.payload;
};

export const useEventTypes = () => {
  const data = useQuery({
    queryKey: [EVENT_TYPES_QUERY_KEY],
    queryFn: () => getEventTypes(),
  });

  return data;
};
