import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TVehicle } from 'types/vehicle';
import { _masks } from 'utils';
import { useStyles } from './styles';

interface VehicleInfoProps {
  vehicle: TVehicle;
}

export function VehicleInfo({ vehicle }: VehicleInfoProps) {
  const classes = useStyles({});

  if (vehicle) {
    return (
      <Card className={classes.cardContainer} style={{ margin: 0, padding: 0 }}>
        <CardContent>
          <Grid container className={classes.grid} spacing={0}>
            <Grid item xs={6}>
              <Typography variant="caption" className={classes.text}>
                Placa: {vehicle.plate} <br />
                Tipo: {vehicle.type}
                <br />
                Marca: {vehicle.make} <br />
                Cor: {vehicle.color} <br />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" className={classes.text}>
                Rótulo: {vehicle.label} <br />
                Chassi: {_masks.vin(vehicle.vin || '')} <br />
                {/* Situação: {isActive(vehicle.status as IsActive)} <br /> */}
                Modelo: {vehicle.model} <br />
                Ano: {vehicle.manufacturedYear} / {vehicle.modelYear} <br />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" className={classes.text}>
                Motorista: {vehicle.driver} <br />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* <Typography variant="caption" className={classes.text}>
                Tipo bloqueio: {hasLock(vehicle.lockType)} <br />
                Obs: {vehicle.obsVehicle}
              </Typography> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card className={classes.cardContainer} style={{ margin: 0, padding: 0 }}>
      <CardContent>
        <Grid container className={classes.grid} spacing={0}>
          <Grid item xs={6}>
            <Typography variant="caption" className={classes.text}>
              Placa: <Skeleton className={classes.skeleton} /> <br />
              Tipo: <Skeleton className={classes.skeleton} /> <br />
              Marca: <Skeleton className={classes.skeleton} /> <br />
              Cor: <Skeleton className={classes.skeleton} /> <br />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" className={classes.text}>
              Chassi: <Skeleton className={classes.skeleton} /> <br />
              Situação: <Skeleton className={classes.skeleton} /> <br />
              Modelo: <Skeleton className={classes.skeleton} /> <br />
              Ano: <Skeleton className={classes.skeleton} /> <br />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.text}>
              Motorista: <Skeleton className={classes.skeleton} /> <br />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <Typography variant="caption" className={classes.text}>
              Obs: <Skeleton className={classes.skeleton} /> <br />
            </Typography> */}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
