import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { PinDrop } from '@material-ui/icons';
import { useStyles } from './styles';

interface AddressGoogleMapsLinkProps {
  lat: string;
  lng: string;
  label?: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}

export function AddressGoogleMapsLink({
  lat,
  lng,
  label,
  icon: Icon = PinDrop,
}: AddressGoogleMapsLinkProps) {
  const classes = useStyles();
  return (
    <a
      className={classes.link}
      href={`http://maps.google.com/maps?q=${lat},${lng}&ll=${lat},${lng}&z=19`}
      target="_blank"
      rel="noreferrer"
    >
      {label ?? <Icon fontSize="small" />}
    </a>
  );
}
