import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  selected: {
    textDecoration: 'none',
    '&$selected': {
      color: theme.palette.action.active,
    },
  },
  content: {
    color: theme.palette.action.disabled,
  },
}));
