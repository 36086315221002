import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  unlocked: {
    color: theme.palette.success.main,
  },
  locked: {
    color: theme.palette.error.main,
  },
}));
