import { AxiosError } from 'axios';
import { VEHICLES_QUERY_KEY } from 'config';
import { useToast } from 'context/toastContext';
import { queryClient } from 'lib/react-query';
import { useMutation } from 'react-query';
import { IUpdateVehicleDTO, IVehicle } from '../types';
import { VehiclesService } from './vehicleService';

const updateVehicleLabel = (data: IUpdateVehicleDTO) => {
  return VehiclesService.updateLabel(data);
};

export const useUpdateVehicleLabel = () => {
  const { addToast } = useToast();

  return useMutation({
    onError: (error: AxiosError) => {
      console.log(error);
      addToast({ type: 'error', message: error.response?.data?.message });
    },
    onSuccess: (_, params) => {
      const query = queryClient.getQueryCache().get(VEHICLES_QUERY_KEY);
      if (query?.state?.data && Array.isArray(query.state.data)) {
        const prevData = query.state.data as IVehicle[];
        const updated = prevData.map(v =>
          v.id === params.vehicleId ? { ...v, label: params.label } : v,
        );
        query.setData(updated);
      }

      addToast({ type: 'success', message: 'Rótulo editado com sucesso!' });
    },
    mutationFn: (data: IUpdateVehicleDTO) => updateVehicleLabel(data),
  });
};
