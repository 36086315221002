/* eslint-disable import/prefer-default-export */
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { deviceGridColumns } from 'config/configColumns';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';
import { useAuth } from './authContext';

interface ICustomDeviceGridColumns {
  id: number;
  label: string;
  value: string;
  minWidth: number;
  align: string;
  alignRow: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  render?: (data: { [key: string]: any }) => ReactNode;
  default: boolean;
  name: string;
}

interface IGridContext {
  customDeviceGridColumns: ICustomDeviceGridColumns[];
}

export const GridContext = createContext({} as IGridContext);

const GridProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();

  const customDeviceGridColumns = useMemo(() => {
    if (user.settings?.grid?.deviceGridColumns?.length) {
      return user.settings.grid.deviceGridColumns
        .map(v => deviceGridColumns.find(c => v === c.name))
        .filter(e => e) as ICustomDeviceGridColumns[];
    }
    return deviceGridColumns.filter(
      v => v.default,
    ) as ICustomDeviceGridColumns[];
  }, [user.settings?.grid?.deviceGridColumns]);

  return (
    <GridContext.Provider
      value={{
        customDeviceGridColumns,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};
function useGrid() {
  const context = useContext(GridContext);

  if (!context) {
    throw new Error('useGrid must be used within an GridPovider');
  }

  return context;
}

export { GridProvider, useGrid };
