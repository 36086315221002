function formatCoord(coord: string | undefined): string {
  if (!coord) return '-';
  return coord.substring(0, coord.indexOf('.') + 6);
}
interface ILatLonFormat {
  latLng?: {
    lat: string;
    lng: string;
  };
  coord?: string;
}

export function LatLonFormat({
  latLng = undefined,
  coord,
}: ILatLonFormat): JSX.Element {
  if (latLng === undefined && coord === undefined) return <>-</>;
  if (latLng) {
    return (
      <>
        {formatCoord(latLng.lat)}
        <br />
        {formatCoord(latLng.lng)}
      </>
    );
  }
  return <>{formatCoord(coord)}</>;
}
