import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    // display: 'flex',
    position: 'relative',
    zIndex: 1000,
  },
  container: {
    // minHeight: 300,
    // maxHeight: 800,

    position: 'relative',
    height: 'calc(100% - 80px)',
    flexGrow: 1,
    zIndex: 1,
  },
  paper: {
    width: '100%',
    height: '100%',
    // minHeight: 365,
    // maxHeight: 500,
    // height: '100%',
    // marginBottom: theme.spacing(2),
  },
  containerTable: {
    // height: 200,
  },
  table: {
    // height: '100%',
    // maxHeight: 200,
  },
  toolbar: {
    position: 'relative',
    bottom: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  gridActions: {},
  title: {
    flex: '1 1 100%',
  },
  tr: { cursor: 'pointer' },
  th: {
    fontSize: 10,
    [theme.breakpoints.only('sm')]: {
      fontSize: 9,
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 9,
    },
    // textAlign: 'center',
    background: theme.palette.background.default,
  },
  headerToolbar: {
    background: theme.palette.background.default,
    margin: 0,
    minHeight: 35,
  },
  td: {
    [theme.breakpoints.only('sm')]: {
      fontSize: 8,
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 8,
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 9,
    },
    [theme.breakpoints.between(1920, 2100)]: {
      fontSize: 10,
    },
    [theme.breakpoints.up(2100)]: {
      fontSize: 18,
    },
    // textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.text.secondary,
  },
  collapseBox: {
    maxHeight: 250,
    margin: 10,
  },
  collapsePaper: {
    height: 250,
  },
  collapseRow: {
    backgroundColor: theme.palette.primary.main,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  tablePaginationRoot: {
    maxHeight: 30,
    minHeight: 30,
  },
  tablePaginationToobar: {
    maxHeight: 30,
    minHeight: 30,
  },
  tablePagination: {
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.6rem',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '0.6rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.9rem',
    },
  },
}));
