/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
import msk from 'msk';

export const _masks = {
  plate: value => msk.fit(value.toUpperCase(), 'AAA-9S99'),
  vin: value => msk.fit(value.toUpperCase(), 'SSS SSSSSS SS SSSSSS'),
};

export const masks = {
  login: e => (e.target.value = msk.fit(e.target.value, 'SSSSSSSSSS')),
  password: e => (e.target.value = msk.fit(e.target.value, 'SSSSSSSSSS')),
  cep: e => (e.target.value = msk.fit(e.target.value, '99999-999')),
  cpf: e => (e.target.value = msk.fit(e.target.value, '999.999.999-99')),
  cnpj: e => (e.target.value = msk.fit(e.target.value, '99.999.999/9999-99')),
  cpfcnpj: e => {
    if (e.target.value.length < 15) {
      e.target.value = msk.fit(e.target.value, '999.999.999-99');
    } else {
      e.target.value = msk.fit(e.target.value, '99.999.999/9999-99');
    }
  },
  rg: e => (e.target.value = msk.fit(e.target.value, '99.999.999-9')),
  phone: e => {
    if (e.target.value.length <= 14) {
      e.target.value = msk.fit(e.target.value, '(99) 9999-9999');
    } else {
      e.target.value = msk.fit(e.target.value, '(99) 9 9999-9999');
    }
  },
  renavam: e => {
    e.target.value = msk.fit(e.target.value, '99999999999');
  },
  vin: e => (e.target.value = msk.fit(e.target.value, 'SSS SSSSSS SS SSSSSS')),
  year: e => (e.target.value = msk.fit(e.target.value, '9999')),
  plate: e =>
    (e.target.value = msk.fit(e.target.value.toUpperCase(), 'AAA-9S99')),
  uf: e => (e.target.value = e.target.value.substring(0, 2).toUpperCase()),
  text: e => (e.target.value = e.target.value.replace(/[^A-Za-z]/, '')),
  number: e => (e.target.value = e.target.value.replace(/\D/, '')),
  imeilot: e => {
    const { value } = e.target;
    const lines = value.split('\n');

    if (lines.length > 1) {
      lines[lines.length - 1] = msk.fit(
        lines[lines.length - 1],
        '99999999999999999999,\n9',
      );
      e.target.value = lines.join('\n');
    } else {
      e.target.value = msk.fit(e.target.value, '99999999999999999999,\n9');
    }
  },
  iccid: e =>
    (e.target.value = msk.fit(e.target.value, '99999999999999999999')),
  currency: e => {
    const { value } = e.currentTarget;

    let price = value.replace(/\D/g, '');
    price = price.split('');

    if (price.length > 2) price.splice(-2, 0, ',');
    if (price.length > 6) price.splice(-6, 0, '.');
    if (price.length > 10) price.splice(-10, 0, '.');
    e.currentTarget.value = price.join('');
  },
};
