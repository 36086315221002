import { queryClient } from 'lib/react-query';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { GeofenceService } from 'services/geoFence';
import { Geofence, ICreateGeofence, IUpdateGeofence } from 'types/Geofence';
import { useAuth } from './authContext';
import { useToast } from './toastContext';

interface GeofenceProviderProps {
  children: ReactNode;
}

enum ActionTypes {
  whenMapIsLoaded = 'whenMapIsLoaded',
  initDrawingManager = 'initDrawingManager',
  getDrawnPolygonFence = 'getDrawnPolygonFence',
  onEditingFence = 'onEditingFence',
  onSelectFence = 'onSelectFence',
  clearPreviousDrawnFence = 'clearPreviousDrawnFence',
  closeFormDrawer = 'closeFormDrawer',
  openFormDrawer = 'openFormDrawer',
  onCreateGeofence = 'onCreateGeofence',
  onUpdateGeofence = 'onUpdateGeofence',
  onRemoveGeofence = 'onRemoveGeofence',
  onSelectAllVehicles = 'onSelectAllVehicles',
  onSelectVehicle = 'onSelectVehicle',
  onSubmitVehiclesToGeofence = 'onSubmitVehiclesToGeofence',
  onSubmitGeofenceForm = 'onSubmitGeofenceForm',
  onEditingGeofence = 'onEditingGeofence',
  onDeleteDialogIsOpen = 'onDeleteDialogIsOpen',
  openCardCollapse = 'openCardCollapse',
  onToggleFormEditing = 'onToggleFormEditing',
  ondEditingFence = 'ondEditingFence',
  onToggleAddVehiclesToFenceDrawer = 'onToggleAddVehiclesToFenceDrawer',
  onCloseAddVehiclesToFenceDrawer = 'onCloseAddVehiclesToFenceDrawer',
  onToggleRemoveVehiclesFromGeofenceDialog = 'onToggleRemoveVehiclesFromGeofenceDialog',
  onViewingDrawnFence = 'onViewingDrawnFence',
}

type Google = {
  map: any;
  maps: any;
  ref: Element | null;
};

type State = {
  mapIsLoaded: boolean;
  google: Google | null;
  drawingManager: google.maps.drawing.DrawingManager | null;
  currentDrawingMode: any | null;
  drawnFence: {
    id: string;
    type: string;
    coordinate: number[][];
    name: string;
    status: 'ativa' | 'inativa';
  };
  formOpen: boolean;
  selectedGeofence: Geofence | null;
  cardCollapseIsOpen: number | null;
  isEditingForm: boolean;
  deleteDialog: { isOpen: boolean; id: string; name: string };
  addVehiclesToFenceDrawerIsOpen: boolean;
  isAllVehiclesChecked: boolean;
  vehiclesChecked: number[];
  selectedGeofenceId: string;
  removeVehiclesFromFenceDialogIsOpen: boolean;
  viewingGeofence: boolean;
};

type Action<T> = {
  type: ActionTypes;
  payload?: T;
};
interface GeofenceContextData {
  initMap: (payload: { mapIsLoaded: boolean; google: Google }) => void;
  geofenceState: State;
  resetDrawingMode: () => void;
  onEditingGeofence: (data: Geofence) => void;
  initializeDrawingManager: () => void;
  closeFormDrawer: () => void;
  openFormDrawer: () => void;
  handleSelectAllVehicles: (vehicles: any) => void;
  handleSelectVehicle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOpenDeleteDialog: ({ id, name }: { id: string; name: string }) => void;
  handleCloseDeleteDialog: () => void;
  onSubmitGeofence: (data: IUpdateGeofence) => void;
  onOpenCardCollapse: (index: any) => void;
  handleToggleAddVehiclesToFenceDrawer: (geofenceId: string) => void;
  onDeleteGeofence: () => void;
  handleAddVehiclesToGeofence: () => void;
  handleRemoveVehiclesFromFence: () => void;
  handleToggleRemoveVehiclesFromGeofenceDialog: () => void;
  onViewingDrawnFence: (data: Geofence) => void;
}

const initialState: State = {
  currentDrawingMode: null,
  google: null,
  drawingManager: null,
  mapIsLoaded: false,
  drawnFence: { type: '', coordinate: [], status: 'inativa', name: '', id: '' },
  formOpen: false,
  addVehiclesToFenceDrawerIsOpen: false,
  cardCollapseIsOpen: null,
  deleteDialog: { isOpen: false, id: '', name: '' },
  isAllVehiclesChecked: false,
  isEditingForm: false,
  viewingGeofence: false,
  removeVehiclesFromFenceDialogIsOpen: false,
  selectedGeofence: null,
  selectedGeofenceId: '',
  vehiclesChecked: [],
};

const GeofenceContext = createContext({} as GeofenceContextData);

const geoFenceReducer = (state: State, action: Action<any>): State => {
  switch (action.type) {
    case ActionTypes.whenMapIsLoaded:
      return {
        ...state,
        mapIsLoaded: action.payload.mapIsLoaded,
        google: action.payload.google,
      };

    case ActionTypes.initDrawingManager:
      return {
        ...state,
        drawingManager: action.payload,
      };

    case ActionTypes.getDrawnPolygonFence:
      return {
        ...state,
        drawnFence: action.payload.drawnFence,
        currentDrawingMode: action.payload.event,
        formOpen: true,
      };

    case ActionTypes.onEditingFence:
      return {
        ...state,
        isEditingForm: action.payload.isEditingForm,
        formOpen: action.payload.formOpen,
        drawnFence: action.payload.drawnFence,
        currentDrawingMode: action.payload.currentDrawingMode,
      };

    case ActionTypes.closeFormDrawer:
      return {
        ...state,
        formOpen: false,
        viewingGeofence: false,
        drawnFence: {
          type: '',
          coordinate: [],
          name: '',
          status: 'inativa',
          id: '',
        },
      };

    case ActionTypes.openFormDrawer:
      return {
        ...state,
        formOpen: true,
      };

    case ActionTypes.onSelectAllVehicles:
      return {
        ...state,
        isAllVehiclesChecked: action.payload.isAllVehiclesChecked,
      };

    case ActionTypes.onSelectVehicle:
      return {
        ...state,
        vehiclesChecked: action.payload.vehiclesChecked,
      };

    case ActionTypes.openCardCollapse:
      return {
        ...state,
        cardCollapseIsOpen: action.payload.cardCollapseIsOpen,
      };

    case ActionTypes.onToggleFormEditing:
      return {
        ...state,
        isEditingForm: action.payload.isEditingForm,
      };

    case ActionTypes.onToggleAddVehiclesToFenceDrawer:
      return {
        ...state,
        addVehiclesToFenceDrawerIsOpen:
          action.payload.addVehiclesToFenceDrawerIsOpen,
        selectedGeofenceId: action.payload.selectedGeofenceId,
      };

    case ActionTypes.onDeleteDialogIsOpen: {
      return {
        ...state,
        deleteDialog: {
          isOpen: action.payload.isOpen,
          id: action.payload.id,
          name: action.payload.name,
        },
      };
    }

    case ActionTypes.onSubmitVehiclesToGeofence:
      return {
        ...state,
        addVehiclesToFenceDrawerIsOpen:
          action.payload.addVehiclesToFenceDrawerIsOpen,
        isAllVehiclesChecked: action.payload.isAllVehiclesChecked,
        selectedGeofenceId: action.payload.selectedGeofenceId,
        vehiclesChecked: action.payload.vehiclesChecked,
      };

    case ActionTypes.onToggleRemoveVehiclesFromGeofenceDialog:
      return {
        ...state,
        removeVehiclesFromFenceDialogIsOpen:
          action.payload.removeVehiclesFromFenceDialogIsOpen,
      };

    case ActionTypes.onViewingDrawnFence:
      return {
        ...state,
        drawnFence: action.payload.drawnFence,
        currentDrawingMode: action.payload.currentDrawingMode,
        formOpen: true,
        viewingGeofence: true,
      };

    default:
      return state;
  }
};

function GeofenceProvider({ children }: GeofenceProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(geoFenceReducer, initialState);
  // const { data: vehicles } = useVisualizedVehicles();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { reset } = useForm();

  const initMap = useCallback((payload: any) => {
    dispatch({
      type: ActionTypes.whenMapIsLoaded,
      payload,
    });
  }, []);

  const initializeDrawingManager = useCallback(() => {
    if (state.mapIsLoaded && state.google) {
      if (state.drawingManager) return;
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          editable: false,
          fillColor: 'red',
          fillOpacity: 0.2,
          strokeColor: 'red',
          strokeWeight: 3,
        },
      });
      drawingManager.setMap(state.google.map);

      dispatch({
        type: ActionTypes.initDrawingManager,
        payload: drawingManager,
      });
    }
  }, [state.drawingManager, state.google, state.mapIsLoaded]);

  // useEffect(() => {
  //   initializeDrawingManager();
  // }, [initializeDrawingManager]);

  const getDrawnPolygonFence = useCallback(() => {
    if (state.mapIsLoaded && state.drawingManager) {
      return google.maps.event.addListener(
        state.drawingManager,
        'overlaycomplete',
        (event: {
          type: google.maps.drawing.OverlayType.POLYGON;
          overlay: google.maps.Polygon;
        }) => {
          const coordinatesArray = event.overlay?.getPath().getArray();

          const coordsResult = coordinatesArray.map(item => [
            item.lat(),
            item.lng(),
          ]);

          const formattedCoordsResult = [...coordsResult, coordsResult[0]];

          const drawnFence = {
            type: event.type,
            coordinate: formattedCoordsResult,
          };

          dispatch({
            type: ActionTypes.getDrawnPolygonFence,
            payload: { event, drawnFence },
          });

          state.drawingManager?.setDrawingMode(null);
        },
      );
    }
    return null;
  }, [state.drawingManager, state.mapIsLoaded]);

  useEffect(() => {
    getDrawnPolygonFence();
  }, [getDrawnPolygonFence]);

  const clearDrawFenceOnMapClick = useCallback(() => {
    if (state.mapIsLoaded) {
      state.google?.maps?.event?.addListener(state.google.map, 'click', () =>
        state.currentDrawingMode?.overlay.setMap(null),
      );
    }
  }, [
    state.currentDrawingMode,
    state.google?.map,
    state.google?.maps.event,
    state.mapIsLoaded,
  ]);

  useEffect(() => {
    clearDrawFenceOnMapClick();
  }, [clearDrawFenceOnMapClick]);

  const resetDrawingMode = useCallback(() => {
    if (state.mapIsLoaded) {
      state.currentDrawingMode?.overlay.setMap(null);
      if (!state.drawingManager && !state.currentDrawingMode) {
        initializeDrawingManager();
      } else {
        state.drawingManager?.setMap(state.google?.map);
      }
    }
  }, [
    initializeDrawingManager,
    state.currentDrawingMode,
    state.drawingManager,
    state.google?.map,
    state.mapIsLoaded,
  ]);

  const onEditingGeofence = useCallback(
    (data: Geofence) => {
      const formattedFenceCoords = data.fence.coordinate.flatMap(
        (item: any) => ({
          lat: item[0],
          lng: item[1],
        }),
      );
      if (state.google !== null) {
        const { maps, map } = state.google;
        const currentFenceToDraw = new maps.Polygon({
          paths: formattedFenceCoords,
          fillColor: 'red',
          fillOpacity: 0.2,
          strokeColor: 'red',
          strokeWeight: 3,
        });

        dispatch({
          type: ActionTypes.onEditingFence,
          payload: {
            drawnFence: {
              name: data.name,
              status: data.status,
              type: data.fence.type,
              coordinate: data.fence.coordinate,
              id: data.id,
            },
            currentDrawingMode: {
              type: 'polygon',
              overlay: currentFenceToDraw,
            },
            formOpen: true,
            isEditingForm: true,
          },
        });

        currentFenceToDraw.setMap(map);
        state.google?.map.setCenter(formattedFenceCoords[0]);
        state.google?.map.setZoom(17);
        // state.drawingManager?.setMap(null);
      }
    },
    [state.google],
  );

  const handleOpenDeleteDialog = useCallback(
    ({ id, name }: { id: string; name: string }) => {
      dispatch({
        type: ActionTypes.onDeleteDialogIsOpen,
        payload: { isOpen: true, id, name },
      });
    },
    [],
  );

  const handleCloseDeleteDialog = useCallback(() => {
    dispatch({
      type: ActionTypes.onDeleteDialogIsOpen,
      payload: { isOpen: false, id: '', name: '' },
    });
  }, []);

  const handleSelectAllVehicles = useCallback(
    (vehicles: any) => {
      dispatch({
        type: ActionTypes.onSelectAllVehicles,
        payload: { isAllVehiclesChecked: !state.isAllVehiclesChecked },
      });
      if (vehicles) {
        const vehiclesChecked = vehicles?.map((vehicle: any) => vehicle.id);
        dispatch({
          type: ActionTypes.onSelectVehicle,
          payload: { vehiclesChecked },
        });
      }
      if (state.isAllVehiclesChecked) {
        dispatch({
          type: ActionTypes.onSelectVehicle,
          payload: { vehiclesChecked: [] },
        });
      }
    },
    [state.isAllVehiclesChecked],
  );

  const handleSelectVehicle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { id, checked } = event.target;
      const vehiclesChecked = [...state.vehiclesChecked, Number(id)];
      dispatch({
        type: ActionTypes.onSelectVehicle,
        payload: { vehiclesChecked },
      });
      if (!checked) {
        const vehiclesChecked = state.vehiclesChecked.filter(
          item => item !== Number(id),
        );
        dispatch({
          type: ActionTypes.onSelectVehicle,
          payload: { vehiclesChecked },
        });
      }
    },
    [state.vehiclesChecked],
  );

  const closeFormDrawer = useCallback(() => {
    dispatch({
      type: ActionTypes.closeFormDrawer,
    });
    state.currentDrawingMode?.overlay.setMap(null);
  }, [state.currentDrawingMode?.overlay]);

  const toggleFormEditing = useCallback(() => {
    dispatch({
      type: ActionTypes.onToggleFormEditing,
      payload: { isEditingForm: !state.isEditingForm },
    });
  }, [state.isEditingForm]);

  const createGeoFence = useMutation(
    async (data: ICreateGeofence) => {
      await GeofenceService.createGeofence({
        ...data,
        clientId: user.id,
        companyId: user.companyId,
      });
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('geofences');
      },
      onSuccess: () => {
        addToast({ type: 'success', message: 'Cadastrado com sucesso.' });
        closeFormDrawer();
        state.currentDrawingMode?.overlay.setMap(null);
        queryClient.invalidateQueries('geofences');
      },
      onError: (error: any, _variables, context) => {
        console.log(error);
        addToast({ type: 'error', message: error.response?.data?.message });
        queryClient.setQueryData('geofences', context);
      },
      onSettled: () => {
        queryClient.invalidateQueries('geofences');
      },
      retry: false,
    },
  );

  const updateGeoFence = useMutation(
    async (data: IUpdateGeofence) => {
      await GeofenceService.updateGeofence(state.drawnFence.id, data);
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('geofences');
      },
      onSuccess: () => {
        addToast({ type: 'success', message: 'Editado com sucesso.' });
        closeFormDrawer();
        resetDrawingMode();
        toggleFormEditing();
        reset({ id: '', name: '', status: '' });
        queryClient.invalidateQueries('geofences');
      },
      onError: (error: any, _variables, context) => {
        console.log(error);
        addToast({ type: 'error', message: error.response?.data?.message });
        queryClient.setQueryData('geofences', context);
      },
      onSettled: () => {
        queryClient.invalidateQueries('geofences');
      },
      retry: false,
    },
  );

  const deleteGeofence = useMutation(
    async (id: string) => {
      await GeofenceService.deleteGeofence(id);
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('geofences');
      },
      onSuccess: () => {
        addToast({ type: 'success', message: 'Apagado com sucesso.' });
        closeFormDrawer();
        resetDrawingMode();
        queryClient.invalidateQueries('geofences');
      },
      onError: (error: any, _variables, context) => {
        console.log(error);
        addToast({ type: 'error', message: error.response?.data?.message });
        queryClient.setQueryData('geofences', context);
      },
      onSettled: () => {
        queryClient.invalidateQueries('geofences');
      },
      retry: false,
    },
  );

  const addVehiclesToFence = useMutation(
    async ({
      fenceId,
      vehicleIds,
    }: {
      fenceId: string;
      vehicleIds: number[];
    }) => {
      await GeofenceService.addVehicleInFence(fenceId, vehicleIds);
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('geofences');
      },
      onSuccess: () => {
        addToast({
          type: 'success',
          message: 'Veículo adicionado à cerca com sucesso.',
        });
        dispatch({
          type: ActionTypes.onSubmitVehiclesToGeofence,
          payload: {
            addVehiclesToFenceDrawerIsOpen: false,
            isAllVehiclesChecked: false,
            selectedGeofenceId: '',
            vehiclesChecked: [],
          },
        });
        queryClient.invalidateQueries('geofences');
      },
      onError: (error: any, _variables, context) => {
        console.log(error);
        addToast({ type: 'error', message: error.response?.data?.message });
        queryClient.setQueryData('geofences', context);
      },
      onSettled: () => {
        queryClient.invalidateQueries('geofences');
      },
      retry: false,
    },
  );

  const removeVehiclesFromFence = useMutation(
    async ({
      fenceId,
      vehicleIds,
    }: {
      fenceId: string;
      vehicleIds: number[];
    }) => {
      await GeofenceService.removeVehicleFromFence(fenceId, vehicleIds);
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries('geofences');
      },
      onSuccess: () => {
        addToast({
          type: 'success',
          message: 'Veículo removido da cerca com sucesso.',
        });
        dispatch({
          type: ActionTypes.onSubmitVehiclesToGeofence,
          payload: {
            addVehiclesToFenceDrawerIsOpen: false,
            isAllVehiclesChecked: false,
            selectedGeofenceId: '',
            vehiclesChecked: [],
          },
        });

        dispatch({
          type: ActionTypes.onDeleteDialogIsOpen,
          payload: { id: '', name: '', isOpen: false },
        });

        queryClient.invalidateQueries('geofences');
      },
      onError: (error: any, _variables, context) => {
        console.log(error);
        addToast({ type: 'error', message: error.response?.data?.message });
        queryClient.setQueryData('geofences', context);
      },
      onSettled: () => {
        queryClient.invalidateQueries('geofences');
      },
      retry: false,
    },
  );

  const onDeleteGeofence = useCallback(() => {
    deleteGeofence.mutateAsync(state.deleteDialog.id);
  }, [deleteGeofence, state.deleteDialog.id]);

  const onSubmitGeofence = useCallback(
    (data: any) => {
      if (state.isEditingForm) {
        updateGeoFence.mutateAsync({ ...data });
        updateGeoFence.reset();
      } else {
        createGeoFence.mutateAsync({
          ...data,
          fence: state.drawnFence,
        });
        createGeoFence.reset();
      }
    },
    [createGeoFence, state.drawnFence, state.isEditingForm, updateGeoFence],
  );

  const onOpenCardCollapse = useCallback(
    (index: any) => {
      const cardCollapseIsOpen =
        state.cardCollapseIsOpen !== index ? index : null;
      dispatch({
        type: ActionTypes.openCardCollapse,
        payload: { cardCollapseIsOpen },
      });
    },
    [state.cardCollapseIsOpen],
  );

  const handleAddVehiclesToGeofence = useCallback(() => {
    addVehiclesToFence.mutateAsync({
      fenceId: state.selectedGeofenceId,
      vehicleIds: state.vehiclesChecked,
    });
  }, [addVehiclesToFence, state.selectedGeofenceId, state.vehiclesChecked]);

  const handleRemoveVehiclesFromFence = useCallback(() => {
    removeVehiclesFromFence.mutateAsync({
      fenceId: state.selectedGeofenceId,
      vehicleIds: state.vehiclesChecked,
    });
  }, [
    removeVehiclesFromFence,
    state.selectedGeofenceId,
    state.vehiclesChecked,
  ]);

  const handleToggleAddVehiclesToFenceDrawer = useCallback(
    (geofenceId: string) => {
      dispatch({
        type: ActionTypes.onToggleAddVehiclesToFenceDrawer,
        payload: {
          addVehiclesToFenceDrawerIsOpen: !state.addVehiclesToFenceDrawerIsOpen,
          selectedGeofenceId: geofenceId,
        },
      });
    },
    [state.addVehiclesToFenceDrawerIsOpen],
  );

  const handleToggleRemoveVehiclesFromGeofenceDialog = useCallback(() => {
    dispatch({
      type: ActionTypes.onToggleRemoveVehiclesFromGeofenceDialog,
      payload: {
        removeVehiclesFromFenceDialogIsOpen:
          !state.removeVehiclesFromFenceDialogIsOpen,
      },
    });
  }, [state.removeVehiclesFromFenceDialogIsOpen]);

  const onViewingDrawnFence = useCallback(
    (data: Geofence) => {
      const formattedFenceCoords = data.fence.coordinate.flatMap(
        (item: any) => ({
          lat: item[0],
          lng: item[1],
        }),
      );

      if (state.google !== null) {
        const { maps, map } = state.google;
        const currentFenceToDraw = new maps.Polygon({
          paths: formattedFenceCoords,
          fillColor: 'red',
          fillOpacity: 0.2,
          strokeColor: 'red',
          strokeWeight: 3,
        });

        dispatch({
          type: ActionTypes.onViewingDrawnFence,
          payload: {
            drawnFence: {
              name: data.name,
              status: data.status,
              type: data.fence.type,
              coordinate: data.fence.coordinate,
            },
            currentDrawingMode: {
              type: 'polygon',
              overlay: currentFenceToDraw,
            },
          },
        });

        currentFenceToDraw.setMap(map);
        map.setCenter(formattedFenceCoords[0]);
        map.setZoom(17);
        // state.google?.map.setCenter(formattedFenceCoords[0]);
        // state.google?.map.setZoom(10);
      }
    },
    [state.google],
  );

  return (
    <GeofenceContext.Provider
      value={{
        initMap,
        geofenceState: state,
        resetDrawingMode,
        onEditingGeofence,
        initializeDrawingManager,
        closeFormDrawer,
        openFormDrawer: () => {
          dispatch({
            type: ActionTypes.openFormDrawer,
            payload: { formOpen: true },
          });
          // initializeDrawingManager();
        },
        handleSelectAllVehicles,
        handleSelectVehicle,
        handleOpenDeleteDialog,
        handleCloseDeleteDialog,
        onSubmitGeofence,
        onOpenCardCollapse,
        onDeleteGeofence,
        handleAddVehiclesToGeofence,
        handleRemoveVehiclesFromFence,
        handleToggleAddVehiclesToFenceDrawer,
        handleToggleRemoveVehiclesFromGeofenceDialog,
        onViewingDrawnFence,
      }}
    >
      {children}
    </GeofenceContext.Provider>
  );
}

function useGeofence(): GeofenceContextData {
  const context = useContext(GeofenceContext);

  if (!context) {
    throw new Error('useGeofence must be used within a GeofenceProvider');
  }

  return context;
}

export { GeofenceProvider, useGeofence };
