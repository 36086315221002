import { FaCarSide, FaMotorcycle, FaShip, FaTruck } from 'react-icons/fa';

export function RenderIcon({
  className,
  fontSize,
  type,
}: {
  className?: string | undefined;
  fontSize: string | number | undefined;
  type: string;
}): JSX.Element {
  const icons: { [key: string]: JSX.Element } = {
    car: <FaCarSide className={className} fontSize={fontSize} />,
    motorcycle: <FaMotorcycle className={className} fontSize={fontSize} />,
    boat: <FaShip className={className} fontSize={fontSize} />,
    truck: <FaTruck className={className} fontSize={fontSize} />,
  };

  return icons[type.toLowerCase()] || icons.car;
}
