import { Button, ButtonGroup } from '@material-ui/core';
import {
  Settings,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
} from '@material-ui/icons';
import { CustomModal } from 'components/CustomModal';
import { useSplitPane } from 'components/SplitPanel/splitPanel.js';
import { useState } from 'react';
import { GridSettingsMenu } from '../GridSettingsModal';
import { useStyles } from './styles';

export function GridControlButton({
  top,
  left,
  right,
  bottom,
}: {
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { moveDown, moveUp, moveMiddle } = useSplitPane();

  const classes = useStyles();

  return (
    <>
      <ButtonGroup
        style={{
          position: 'absolute',
          top,
          bottom,
          left,
          right,
          zIndex: 1,
        }}
        variant="contained"
        // color="secondary"
        size="small"
      >
        <Button className={classes.btn} onClick={moveUp}>
          <VerticalAlignTop className={classes.btnIcon} />
        </Button>
        <Button className={classes.btn} onClick={moveMiddle}>
          <VerticalAlignCenter className={classes.btnIcon} />
        </Button>
        <Button className={classes.btn} onClick={moveDown}>
          <VerticalAlignBottom className={classes.btnIcon} />
        </Button>
        <Button className={classes.btn} onClick={() => setModalIsOpen(true)}>
          <Settings className={classes.btnIcon} />
        </Button>
      </ButtonGroup>
      <CustomModal
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        paperProps={{ style: { padding: 0 } }}
        width="100vw"
      >
        <GridSettingsMenu />
      </CustomModal>
    </>
  );
}
