import { makeStyles, Theme } from '@material-ui/core';

interface Props {
  isAuthenticated: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  bottomNavContainer: {
    width: '100%',
    height: '65px',
    position: 'fixed',
    zIndex: 1000,
    bottom: 0,
    left: 0,
    paddingBottom: 5,
    background: theme.palette.background.default,
    display: ({ isAuthenticated }: Props) =>
      isAuthenticated ? 'flex' : 'none',
  },
}));
