import { Card, CardHeader } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useAuth } from 'context/authContext';
import { useToast } from 'context/toastContext';
import { ReactNode, useEffect, useState } from 'react';
import { CommandsService, ICommand } from 'services/commands';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IVehicle } from '../types';
import AlertDialog from './AlertDialog';
import { VehicleCard } from './VehicleCard';

interface VehiclesListProps {
  vehicles?: IVehicle[];
  loading: boolean;
}

interface AlertCommandProps {
  isOpen: boolean;
  showButtons: boolean;
  handleAgree: () => void;
  message: string | ReactNode;
}

export type TLockCommand = 'enable_output_1' | 'disable_output_1';
export const generateId = () => Math.random().toString(16).slice(2);

const alertMessages: {
  disable_output_1: string;
  enable_output_1: string | ReactNode;
} = {
  disable_output_1: 'Tem certeza que deseja enviar comando para desbloqueio?',
  enable_output_1: (
    <>
      *Bloqueio deve ser enviado apenas em caso de roubo ou furto. <br />
      Tem certeza que deseja enviar comando para bloqueio?
    </>
  ),
};

const initialStateAlert = {
  isOpen: false,
  showButtons: true,
  handleAgree: () => {},
  message: '',
};

export function VehiclesList({
  vehicles = [],
  loading = true,
}: VehiclesListProps) {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [alertCommand, setAlertCommand] =
    useState<AlertCommandProps>(initialStateAlert);

  const { permissions } = user;
  const hasCommandPermission = permissions.includes('commands');

  const sendCommand = async (command: TLockCommand, vehicle: IVehicle) => {
    setAlertCommand(prev => ({
      ...prev,
      showButtons: false,
      message: 'Enviando...',
    }));
    const { imei, id, model } = vehicle?.device;
    const commandData = {
      clientId: user.id,
      imei,
      deviceId: id,
      trackerModel: model,
      command,
    } as ICommand;
    try {
      await CommandsService.send(commandData);
      addToast({ type: 'success', message: 'Comando adicionado à fila!' });
    } catch (error: any) {
      console.log('error', error.response.status);
    }
    setAlertCommand(initialStateAlert);
  };
  const handleSendCommand = async (
    command: TLockCommand,
    vehicle: IVehicle,
  ) => {
    if (!command) {
      addToast({ type: 'warning', message: 'Selecione o comando!' });
      return;
    }

    setAlertCommand({
      isOpen: true,
      showButtons: true,
      message: alertMessages[command],
      handleAgree: () => {
        sendCommand(command, vehicle);
      },
    });
  };
  const handleCloseAlert = () => {
    setAlertCommand(initialStateAlert);
  };

  const [items, setItems] = useState([] as IVehicle[]);
  const [initialVehicleLength, setInitialVehicleLength] = useState(0);
  const fetchData = () => {
    setItems(old =>
      old.concat(vehicles.slice(items.length, items.length + 50)),
    );
  };
  useEffect(() => {
    if (
      vehicles.length &&
      initialVehicleLength &&
      vehicles.length !== initialVehicleLength
    ) {
      setItems(vehicles.slice(0, 50));
    } else {
      setInitialVehicleLength(vehicles.length);
    }
  }, [vehicles, initialVehicleLength]);
  useEffect(() => {
    if (!items.length) {
      setItems(old => vehicles.slice(old.length, 50));
    }
  }, [vehicles, items]);
  if (loading) {
    return (
      <>
        {[1, 2, 3, 4, 5, 6, 7, 8].map(card => (
          <Card key={card} style={{ marginBottom: '0.5rem' }}>
            <CardHeader
              avatar={
                <Skeleton
                  variant="circle"
                  width={40}
                  height={40}
                  style={{ marginRight: '1rem' }}
                />
              }
              title={<Skeleton variant="text" />}
              subheader={<Skeleton variant="text" />}
            />
          </Card>
        ))}
      </>
    );
  }

  return (
    <>
      <InfiniteScroll
        dataLength={items.length} // This is important field to render the next data
        next={fetchData}
        hasMore={items.length < vehicles.length}
        loader={null}
        endMessage={null}
      >
        {items.map((vehicle, index) => (
          <VehicleCard
            key={generateId()}
            vehicle={vehicle}
            cardIndex={index}
            hasCommandPermission={hasCommandPermission}
            handleSendCommand={handleSendCommand}
          />
        ))}
      </InfiniteScroll>
      <AlertDialog
        isOpen={alertCommand.isOpen}
        showButtons={alertCommand.showButtons}
        message={alertCommand.message}
        onAgree={alertCommand.handleAgree}
        onDisagree={handleCloseAlert}
        onClose={handleCloseAlert}
      />
    </>
  );
}
