import {
  useCallback,
  createContext,
  useContext,
  useState,
  ReactNode,
} from 'react';

import { SessionService } from 'services/session';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { User } from 'types/User';
import { storage } from 'utils/storage';
import { CustomerSettingsService } from 'services/userSettings';
import { TCustomerSettings } from 'types/customer';

interface AuthProviderProps {
  children: ReactNode;
}

interface SignInCredentials {
  login: string;
  password: string;
  rememberMe: boolean;
}

interface AuthContextData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: User;
  SignIn: (credentials: SignInCredentials) => Promise<void>;
  SignOut: () => void;
  isAuthenticated: boolean;
  loading: boolean;
  success: boolean;
  setUser: (user: User) => void;
  updateCustomerSettings: (data: TCustomerSettings) => Promise<void>;
}

const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  // eslint-disable-next-line consistent-return
  function getFromStorage(key: string) {
    const localValues = storage.getItem({ key, storageType: 'local' });
    const sessionValues = storage.getItem({ key, storageType: 'session' });
    const userSettings = storage.getItem({
      key: 'settings',
      storageType: 'local',
    });

    if (localValues) {
      return { ...localValues, settings: userSettings };
    }

    if (sessionValues) {
      return { ...sessionValues, settings: userSettings };
    }
  }

  const [user, setUser] = useState(() => {
    const storagedUser = getFromStorage('user') as User;

    if (storagedUser) {
      return storagedUser;
    }

    return {} as User;
  });

  const isAuthenticated = !!Object.keys(user).length;

  const mutation = useMutation(
    async (formData: SignInCredentials) => {
      const data = await SessionService.login(formData);

      return data;
    },
    {
      onSuccess: (response, { rememberMe }) => {
        const user = response.data?.payload;

        setUser(user);
        storage.setItem({
          key: 'settings',
          storageType: 'local',
          values: user.settings,
        });
        if (rememberMe) {
          storage.setItem({ key: 'user', storageType: 'local', values: user });
        } else {
          storage.setItem({
            key: 'user',
            storageType: 'session',
            values: user,
          });
        }
      },
      onError: (error: AxiosError) => {
        console.log(error);
        // const message = error.response?.data?.message || error.message;
        // addToast({ type: 'error', message });
      },
    },
  );

  const SignIn = async ({ login, password, rememberMe }: SignInCredentials) => {
    try {
      await mutation.mutateAsync({
        login,
        password,
        rememberMe,
      });
      mutation.reset();
    } catch (error) {
      console.log(error);
    }
  };

  const SignOut = useCallback(() => {
    const localStorageData = storage.getItem({
      key: 'user',
      storageType: 'local',
    });
    const sessionStorageData = storage.getItem({
      key: 'user',
      storageType: 'session',
    });
    if (localStorageData) {
      storage.clearItem({ key: 'user', storageType: 'local' });
    } else if (sessionStorageData) {
      storage.clearItem({ key: 'user', storageType: 'session' });
    }

    setUser({} as User);
    // Object.keys(localStorage)
    //   .filter(id => id.includes(STORAGE_PREFIX))
    //   .map(item => localStorage.removeItem(item));

    // Object.keys(sessionStorage)
    //   .filter(id => id.includes(STORAGE_PREFIX))
    //   .map(item => localStorage.removeItem(item));
  }, []);

  const updateCustomerSettings = useCallback(
    async (data: TCustomerSettings) => {
      CustomerSettingsService.updateByCustomerId(user.id, data);

      const update = user.settings ?? ({} as TCustomerSettings);

      if (data.grid) {
        update.grid = {
          ...user.settings?.grid,
          ...data.grid,
        };
      }

      if (data.theme) {
        update.theme = data.theme;
      }

      if (data.map) {
        update.map = {
          ...user.settings?.map,
          ...data.map,
        };
      }

      storage.setItem({
        key: 'settings',
        storageType: 'local',
        values: update,
      });

      const localStorageData = storage.getItem({
        key: 'user',
        storageType: 'local',
      });

      if (localStorageData) {
        storage.setItem({
          key: 'user',
          storageType: 'local',
          values: { ...user, settings: update },
        });
      } else {
        storage.setItem({
          key: 'user',
          storageType: 'session',
          values: { ...user, settings: update },
        });
      }
      setUser({ ...user, settings: { ...update } });
    },
    [user],
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        SignIn,
        SignOut,
        isAuthenticated,
        loading: mutation.isLoading,
        success: mutation.isSuccess,
        updateCustomerSettings,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthPovider');
  }

  return context;
}

export { AuthProvider, useAuth };
