import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  AppBar,
  Tab,
  Tabs,
  withStyles,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import {
  Close,
  DriveEta,
  Link as LinkIcon,
  LocationOn,
  Send,
} from '@material-ui/icons';
import { useAuth } from 'context/authContext';
import { useToast } from 'context/toastContext';
import { useDeviceInfo } from 'features/Map/api/useDeviceInfo';
import { IVehicle } from 'features/Vehicle';
import { ChangeEvent, ReactNode, useState } from 'react';
import { CommandsService, ICommand } from 'services/commands';
import { TDeviceInfo } from 'types/device';
import { CommandsInfo } from './commands';
import { LastPositionInfo } from './lastPositionInfo';
import { ShareTrackerLink } from './ShareTrackerLink';
import { useStyles } from './styles';
import { VehicleInfo } from './VehicleInfo';

interface InfoWindowProps {
  vehicleData: IVehicle;
  layerProps: any;
  onClose: () => void;
  isMobile?: boolean;
}

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
}

const StyledTabPanel = withStyles({
  root: {
    padding: 0,
    backgroundColor: 'red',
  },
  'MuiTabPanel-root': { padding: 0 },
})(TabPanel);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return <div {...other}>{value === index && <Box>{children}</Box>}</div>;
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export function InfoWindow({
  vehicleData,
  layerProps,
  onClose,
  isMobile,
}: InfoWindowProps): JSX.Element {
  const { addToast } = useToast();
  const { user } = useAuth();
  const { permissions } = user;
  const hasCommandPermission = permissions.includes('commands');
  const [tabIndex, setTabIndex] = useState(0);
  const classes = useStyles({
    color: vehicleData.device.ignition === 'on' ? green[500] : red[500],
    isMobile,
  });

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSendCommand = async (command: string) => {
    if (!command) {
      addToast({ type: 'warning', message: 'Selecione o comando!' });
      return;
    }
    const { imei, id, model } = vehicleData?.device;
    const commandData = {
      clientId: user.id,
      imei,
      deviceId: id,
      trackerModel: model,
      command,
    } as ICommand;

    try {
      await CommandsService.send(commandData);
      addToast({ type: 'success', message: 'Comando adicionado à fila!' });
    } catch (error: any) {
      console.log('error', error.response.status);
    }
  };

  const { data } = useDeviceInfo({
    params: { imei: vehicleData?.device?.imei },
  });

  const { vehicle, lastPosition, device } = data ?? ({} as TDeviceInfo);

  return (
    <Box className={classes.box} {...layerProps}>
      <Card className={classes.card} variant="outlined">
        <CardHeader
          className={classes.cardHeader}
          action={
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          }
          title={
            <Typography
              variant="subtitle2"
              style={{ textAlign: 'center', fontSize: 12 }}
            >
              <strong>PLACA {vehicle?.plate}</strong> <br />
            </Typography>
          }
        />
        <CardContent className={classes.cardContent}>
          <>
            <AppBar position="static" classes={{ root: classes.appBar }}>
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                aria-label="simple tabs example"
                className={classes.tabs}
                centered
                variant="fullWidth"
              >
                <Tab
                  className={classes.tab}
                  icon={
                    <LocationOn
                      className={classes.tabIcon}
                      fontSize="small"
                      color="secondary"
                    />
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  className={classes.tab}
                  icon={<DriveEta fontSize="small" color="secondary" />}
                  {...a11yProps(1)}
                />
                <Tab
                  className={classes.tab}
                  icon={<LinkIcon fontSize="small" color="secondary" />}
                  {...a11yProps(2)}
                />
                <Tab
                  disabled={!hasCommandPermission}
                  className={classes.tab}
                  icon={<Send fontSize="small" color="secondary" />}
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>
            <StyledTabPanel value={tabIndex} index={0}>
              <LastPositionInfo
                lastPosition={lastPosition}
                model={device?.model}
              />
            </StyledTabPanel>
            <StyledTabPanel value={tabIndex} index={1}>
              <VehicleInfo vehicle={vehicle} />
            </StyledTabPanel>
            <StyledTabPanel value={tabIndex} index={2}>
              <ShareTrackerLink
                deviceId={vehicleData?.device?.imei}
                plate={vehicleData?.plate}
              />
            </StyledTabPanel>
            <StyledTabPanel value={tabIndex} index={3}>
              <CommandsInfo handleSendCommand={handleSendCommand} />
            </StyledTabPanel>
          </>
        </CardContent>
      </Card>
      <span className={classes.arrow} />
    </Box>
  );
}
