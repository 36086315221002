import axios, { AxiosError } from 'axios';
import { REACT_APP_API_URL } from 'config';
import Toast from 'context/toastContext';
import { storage } from 'utils';

const { CancelToken } = axios;
const source = CancelToken.source();

export const Api = axios.create({
  baseURL: REACT_APP_API_URL,
  cancelToken: source.token,
});

let isRefreshing = false;
let failedRequestsQueue: {
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
}[] = [];

function getFromStorage() {
  const localToken = storage.getItem({ key: 'user', storageType: 'local' });
  const sessionToken = storage.getItem({ key: 'user', storageType: 'session' });

  if (localToken) {
    return {
      ...localToken,
      type: 'local',
    };
  }

  if (sessionToken) {
    return {
      ...sessionToken,
      type: 'session',
    };
  }

  return null;
}

Api.interceptors.request.use(
  config => {
    const storedData = getFromStorage();
    if (storedData) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${storedData.token}`;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

Api.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (error.response.data?.code === 'token.expired') {
        const storagedValues = getFromStorage();

        const originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          Api.post('customer/session/refresh', {
            refreshToken: storagedValues.refreshToken,
          })
            .then(response => {
              const user = response.data.payload;

              if (storagedValues.type === 'session') {
                storage.setItem({
                  key: 'user',
                  storageType: 'session',
                  values: user,
                });
              }

              if (storagedValues.type === 'local') {
                storage.setItem({
                  key: 'user',
                  storageType: 'local',
                  values: user,
                });
              }

              Api.defaults.headers.Authorization = `Bearer ${user.token}`;
              failedRequestsQueue.forEach(request =>
                request.onSuccess(user.token),
              );
              failedRequestsQueue = [];
            })
            .catch(error => {
              console.log(error);
              source.cancel('request_canceled');

              failedRequestsQueue.forEach(request => request.onFailure(error));
              failedRequestsQueue = [];
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: string) => {
              originalConfig.headers.Authorization = `Bearer ${token}`;

              resolve(Api(originalConfig));
            },
            onFailure: (error: AxiosError) => {
              reject(error);
            },
          });
        });
      }

      Toast.error(
        'Houve um problema na validação das suas credenciais, você será deslogado.',
      );

      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/';
      }, 5000);

      return Promise.reject(new Error('Error with auth token'));
    }
    if (error.message === 'request_canceled') {
      return Promise.reject(error);
    }
    const message = error.response?.data?.message || error.message;
    Toast.error(message);
    return Promise.reject(error);
  },
);
