import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export function AlertDialog({
  handleClose,
  handleDisagree,
  handleAgree,
  labelButtonAgree = 'OK',
  labelButtonDisagree = 'Cancelar',
  title,
  contentText,
  open,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogActions>
            <IconButton aria-label="close" onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogActions>
        </Grid>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary">
            {labelButtonDisagree}
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            {labelButtonAgree}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
