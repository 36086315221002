import { useCan } from 'hooks/useCan';
import { forwardRef, Fragment, ReactNode } from 'react';

interface CanProps {
  children: ReactNode;
  permissions?: string[];
  roles?: string[];
}

export function Can({
  children,
  permissions,
  roles,
}: CanProps): JSX.Element | null {
  const userCanSeeComponent = useCan({
    permissions,
    role: roles,
  });

  if (!userCanSeeComponent) {
    return null;
  }

  return <>{children}</>;
}

export const CanWrapper = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ children, permissions, roles }: CanProps, ref: any) => {
    const userCanSeeComponent = useCan({
      permissions,
      role: roles,
    });

    if (!userCanSeeComponent) {
      return null;
    }

    return <div ref={ref}>{children}</div>;
  },
);
