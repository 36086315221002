/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { Api } from 'lib/axios';

import { Event } from '../types';

export interface IResponse {
  message: string;
  payload: Event[];
}
export interface IResponseUnreadEvents {
  message: string;
  payload: {
    events: Event[];
    currentPage: number;
    totalItems: number;
    totalPages: number;
    hasNextPage: boolean;
    itemsPerPage: number;
  };
}

export interface IListUnreadEvent {
  vehicleIds: number[];
  size: number;
  page: number;
  eventTypes: string[];
}

export interface IListUnreadEventByClientId {
  clientId: number;
  accessType: string;
  size: number;
  page: number;
  eventType: string[];
}

export const EventService = {
  unreadEvents: ({
    vehicleIds,
    size,
    page,
    eventTypes = [],
  }: IListUnreadEvent): Promise<AxiosResponse<IResponseUnreadEvents>> =>
    Api.get('/web_client_events/unread_by_vehicle_ids', {
      params: { vehicleIds, size, page, eventTypes },
    }),
  unreadEventsByClientId: ({
    clientId,
    accessType,
    size,
    page,
    eventType = [],
  }: IListUnreadEventByClientId): Promise<
    AxiosResponse<IResponseUnreadEvents>
  > =>
    Api.get('/web_client_events/unread_by_client_id', {
      params: { clientId, accessType, size, page, eventType },
    }),
  listEvents: (
    vehicleIds: number[],
    limitDays: number,
  ): Promise<AxiosResponse<IResponse>> =>
    Api.get(`/web_client_events/all_by_vehicle_ids`, {
      params: { vehicleIds, limitDays },
    }),
  read: (eventId: string): Promise<AxiosResponse> =>
    Api.put(`/web_client_events/set_read/${eventId}`, { read: true }),
  eventTypes: (): Promise<AxiosResponse> => Api.get('event_types'),
};
