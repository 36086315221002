import { makeStyles, Theme } from '@material-ui/core';

interface Props {
  isAuthenticated: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.paper,
    width: 35,
    height: '100%',
    position: 'fixed',
    flexDirection: 'column',
    borderRadius: 0,
    display: ({ isAuthenticated }: Props) =>
      isAuthenticated ? 'flex' : 'none',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    marginTop: 5,
  },
  settings: {
    position: 'fixed',
    bottom: 0,
    left: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  lightModeChecked: {
    color: theme.palette.warning.light,
  },
  darkModeChecked: {
    color: theme.palette.grey.A100,
  },
}));
