import { Coords } from 'google-map-react';
import React from 'react';

type PolyLineProps = {
  coords: Coords[];
  google: any;
};

export const RenderPolyLines = ({ coords, google }: PolyLineProps) => {
  const arrow = {
    path: 'M 0,0 2,3 -2,3 1,0 z', // 0,0 is the tip of the arrow
    fillColor: 'red',
    fillOpacity: 1.0,
    strokeColor: 'red',
    strokeWeight: 1,
  };

  if (coords.length) {
    coords.forEach((_item, index) => {
      const line = new google.maps.Polyline({
        path: coords.slice(index, index + 3),
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 3,
        geodesic: true,
        icons: [
          {
            icon: arrow,
            offset: '100%',
          },
        ],
      });
      return line.setMap(google.map);
    });

    google.map.panTo(coords.slice(coords.length - 1)[0]);
  }
  return null;
};

export const PolyLines = React.memo(RenderPolyLines);
