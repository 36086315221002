import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    root: {},

    formControl: {
      margin: theme.spacing(1),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    selectedOptions: {
      display: 'flex',
      justifyContent: 'start',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    btnSave: {
      position: 'absolute',
      bottom: 10,
      right: 10,
    },
    selectAllText: {
      fontWeight: 500,
    },
    indeterminateColor: {
      color: '#f50057',
    },
    selectedAll: {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    iconDirection: {
      width: '80px',
      height: '80px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transform: 'rotate(70deg)',
      transition: 'transform 0.2s',
      pointerEvents: 'none',
      zIndex: 1,
      border: '3px solid red',
      '&::before': {
        content: '""',
        position: 'absolute',
        borderRadius: '50%',
        inset: '0',
        background: 'conic-gradient(rgb(51, 255, 51, 0.4) 11%, #0000 0)',
        transform: 'translate(-7px, 15px)',
        filter: 'blur(3px)',
        zIndex: 1,
      },
    },
    ignitionOnAndMoving: {
      width: 19,
      height: 19,
      backgroundColor: 'rgb(51, 255, 51)',
      border: '3px solid white',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
