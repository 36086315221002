import { Box, colors, Paper, makeStyles, Theme } from '@material-ui/core';
import { NavigationRounded } from '@material-ui/icons';
import { useRef } from 'react';
// import { RenderIcon } from 'utils';

interface NavigationMarkerIconProps {
  ignition: number;
  direction: number;
  speed: number;
  visualizeLabel: boolean;
  label: string;
}

interface RenderIconProps {
  ignition: boolean;
  moving: boolean;
  direction: number;
  className: string;
}

interface StyleProps {
  color?: string;
  bottomLabel?: number;
  rightLabel?: number;
}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  carIcon: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .2s',
    width: 40,
    height: 40,
    '&:hover': {
      transform: 'scale(1.3)',
    },
  },
  icon: {
    pointerEvents: 'auto',
    cursor: 'pointer',
    position: 'absolute',
    left: 0,
    top: 0,
    transition: 'all .2s',
  },

  labelRight: {
    position: 'relative',
    right: ({ rightLabel }) => rightLabel,
    bottom: ({ bottomLabel }) => bottomLabel,
    borderRadius: '.4em',
    fontSize: 9,
    transition: 'transform .2s',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '50%',
      width: '0',
      height: '0',
      border: '6px solid transparent',
      borderRightColor: theme.palette.background.paper,
      borderLeft: '0',
      marginTop: '-5px',
      marginLeft: '-5px',
    },
  },
  ignitionOnAndStopped: {
    width: 20,
    height: 20,
    backgroundColor: 'rgb(255,255,0)',
    border: '3px solid white',
    borderRadius: '50%',
    zIndex: 3000,
    cursor: 'pointer',
    transition: 'transform .2s',
    pointerEvents: 'auto',
    position: 'absolute',
    left: 10,
    top: 10,
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  ignitionOff: {
    width: 20,
    height: 20,
    backgroundColor: 'rgb(255,0,0)',
    border: '3px solid white',
    borderRadius: '50%',
    zIndex: 3000,
    cursor: 'pointer',
    transition: 'transform .2s',
    pointerEvents: 'auto',
    position: 'absolute',
    left: 10,
    top: 10,
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

function RenderIcon({
  className,
  direction,
  ignition,
  moving,
}: RenderIconProps) {
  const classes = useStyles({});
  if (ignition) {
    if (moving) {
      return (
        <NavigationRounded
          className={className}
          style={{
            transform: `rotate(${direction}deg)`,
            fontSize: 40,
            color: colors.green[500],
          }}
        />
      );
    }
    return <Box className={classes.ignitionOnAndStopped} />;
  }
  return <Box className={classes.ignitionOff} />;
}

export function NavigationMarkerIcon({
  ignition,
  label,
  visualizeLabel,
  direction,
  speed,
}: NavigationMarkerIconProps) {
  const labelRef = useRef<any>(null);
  const ignitionOn = ignition === 1;
  const isMoving = speed > 0;
  let bottomLabel = 0;
  let rightLabel = 0;

  if (ignitionOn && isMoving) {
    bottomLabel = -5;
    rightLabel = -60;
  } else {
    bottomLabel = 0;
    rightLabel = -50;
  }

  const classes = useStyles({ bottomLabel, rightLabel });

  function onMouseEnterMarker() {
    if (!visualizeLabel) labelRef.current.style.visibility = 'visible';
  }
  function onMouseLeaveMarker() {
    if (!visualizeLabel) labelRef.current.style.visibility = 'hidden';
  }

  return (
    <div className={classes.carIcon}>
      <div onMouseEnter={onMouseEnterMarker} onMouseLeave={onMouseLeaveMarker}>
        <RenderIcon
          className={classes.icon}
          direction={direction}
          ignition={ignitionOn}
          moving={isMoving}
        />
      </div>
      <div
        ref={labelRef}
        style={{ visibility: visualizeLabel ? 'visible' : 'hidden' }}
      >
        <Box
          className={classes.labelRight}
          component={Paper}
          width="100%"
          minWidth="60px"
          maxWidth="100px"
          padding={0.5}
          textAlign="center"
          alignItems="center"
          justifyContent="center"
        >
          <strong>{label}</strong>
        </Box>
      </div>
    </div>
  );
}
