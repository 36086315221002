import { Api } from 'lib/axios';
import { useQuery, UseQueryResult } from 'react-query';
import { TDeviceInfo } from 'types/device';

async function getDeviceInfo(imei: string) {
  if (!imei) return undefined;

  const response = await Api.get('device_tracker/get_device_info', {
    params: { imei, address: true },
  });

  return response.data.payload;
}

type UseDeviceInfoProps = {
  params: { imei: string };
};

export const useDeviceInfo = ({
  params,
}: UseDeviceInfoProps): UseQueryResult<TDeviceInfo> => {
  const data = useQuery({
    queryKey: ['deviceInfo', params.imei],
    queryFn: () => getDeviceInfo(params.imei),
  });

  return data;
};
