import { IVehicle } from 'features/Vehicle';
import { CarMarkerIcon } from './markerIcons/CarMarkerIcon';
import { DirectionMarkerIcon } from './markerIcons/DirectionMarkerIcon';
import { NavigationMarkerIcon } from './markerIcons/NavigationMarkerIcon';

interface RenderIconProps {
  typeMarker: 'car' | 'direction' | 'navigation';
  visualizeLabel: boolean;
  typeLabel: string;
  vehicle: IVehicle;
}

export function renderIcon({
  typeLabel,
  typeMarker,
  visualizeLabel,
  vehicle,
}: RenderIconProps) {
  switch (typeMarker) {
    case 'car':
      return (
        <CarMarkerIcon
          ignition={Number(vehicle.device.ignition)}
          label={typeLabel === 'custom' ? vehicle.label : vehicle.plate}
          visualizeLabel={visualizeLabel}
          vehicleType={vehicle.type}
        />
      );
    case 'direction':
      return (
        <DirectionMarkerIcon
          direction={Number(vehicle.device.direction)}
          ignition={Number(vehicle.device.ignition)}
          speed={Number(vehicle.device.speed)}
          label={typeLabel === 'custom' ? vehicle.label : vehicle.plate}
          visualizeLabel={visualizeLabel}
        />
      );
    case 'navigation':
      return (
        <NavigationMarkerIcon
          direction={Number(vehicle.device.direction)}
          ignition={Number(vehicle.device.ignition)}
          speed={Number(vehicle.device.speed)}
          label={typeLabel === 'custom' ? vehicle.label : vehicle.plate}
          visualizeLabel={visualizeLabel}
        />
      );
    default:
      return (
        <DirectionMarkerIcon
          direction={Number(vehicle.device.direction)}
          ignition={Number(vehicle.device.ignition)}
          speed={Number(vehicle.device.speed)}
          label={typeLabel === 'custom' ? vehicle.label : vehicle.plate}
          visualizeLabel={visualizeLabel}
        />
      );
  }
}
