// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Collapse,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Suspense, useCallback, useState } from 'react';
import { RenderIcon } from 'utils/renderVehicleIcons';
import { useVisualizeLabel } from 'context/visualizeLabelContext';
import { lazyImport } from 'utils/lazyImport';
import { Fallback } from 'components';
import { IVehicle } from '../types';
import { TLockCommand } from './VehiclesList';

const { CollapseContent } = lazyImport(
  () => import('./CollapseContent'),
  'CollapseContent',
);

interface VehicleCardProps {
  vehicle: IVehicle;
  cardIndex: number;
  hasCommandPermission: boolean;
  handleSendCommand: (command: TLockCommand, vehicle: IVehicle) => void;
}

const useStyles = makeStyles<Theme>(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  descriptionContainer: {
    paddingBottom: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  descriptionText: {
    fontSize: '0.7rem',
  },
  addressBox: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0.5rem 0.5rem',
  },
}));

export function VehicleCard({
  vehicle,
  cardIndex,
  hasCommandPermission,
  handleSendCommand,
}: VehicleCardProps): JSX.Element {
  const classes = useStyles();

  const [collapseIsOpen, setCollapseIsOpen] = useState<number | null>(null);
  const { visualizeLabel } = useVisualizeLabel();

  const handleOpenCardCollapse = useCallback((index: number) => {
    setCollapseIsOpen(prev => (prev !== index ? index : null));
  }, []);

  return (
    <Card elevation={5} style={{ marginBottom: '0.5rem' }}>
      <CardHeader
        avatar={<RenderIcon fontSize="40px" type={vehicle?.type} />}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: collapseIsOpen === cardIndex,
            })}
            onClick={() => handleOpenCardCollapse(cardIndex)}
            aria-expanded={collapseIsOpen === cardIndex}
            aria-label="expand"
          >
            <ExpandMore />
          </IconButton>
        }
        title={
          visualizeLabel ? (
            <strong>Rótulo: {vehicle?.label}</strong>
          ) : (
            <strong>Placa: {vehicle?.plate}</strong>
          )
        }
        subheader={`Última posição: ${vehicle?.device?.dateTime}`}
      />
      <Collapse in={cardIndex === collapseIsOpen} timeout="auto" unmountOnExit>
        <Suspense fallback={<Fallback />}>
          <CollapseContent
            vehicle={vehicle}
            handleSendCommand={handleSendCommand}
            hasCommandPermission={hasCommandPermission}
          />
        </Suspense>
      </Collapse>
    </Card>
  );
}
