/* eslint-disable import/no-duplicates */

import { useStyles } from './styles';

interface LastCommunicationTimeProps {
  dateTime: string;
}

export function LastCommunicationTime({
  dateTime,
}: LastCommunicationTimeProps) {
  const classes = useStyles();

  if (dateTime === undefined) return <>-</>;
  if (dateTime === null)
    return <strong className={classes.offline}>OFFLINE</strong>;

  const diff = Math.abs(
    new Date().getTime() - new Date(dateTime.replace(' ', 'T')).getTime(),
  );
  const minutes = Math.ceil(diff / (1000 * 60));
  const hours = (minutes / 60).toFixed();
  const days = (Number(hours) / 24).toFixed();

  if (minutes < 10) return <strong className={classes.online}>ONLINE</strong>;

  if (minutes < 60)
    return <strong className={classes.recently}>{`${minutes} min`}</strong>;

  if (minutes < 180)
    return <strong className={classes.recently}>{`${hours} h`}</strong>;

  if (minutes < 1440)
    return <strong className={classes.shortTime}>{`${hours} h`}</strong>;

  return <strong className={classes.longTime}>{`${days} dias`}</strong>;
}
