import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import {
  BatteryChargingFull,
  Block,
  FlashOn,
  Language,
  PinDrop,
  Schedule,
  Speed,
  VpnKey,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { TPosition } from 'types/position';
import { useStyles } from './styles';

interface LastPositionInfoProps {
  lastPosition: TPosition;
  model: string;
}

function formatCoord(coord: string) {
  if (!coord) return '-';
  return coord.substring(0, coord.indexOf('.') + 6);
}

export function LastPositionInfo({
  lastPosition,
  model,
}: LastPositionInfoProps) {
  const classes = useStyles({});
  if (lastPosition) {
    let isLock = Boolean(lastPosition.out1);
    if (model === 'LOGALL_INVERTED' || model === 'E3_INVERTED') {
      isLock = !isLock;
    }
    return (
      <Card className={classes.cardContainer}>
        <CardContent>
          {/* <Typography gutterBottom variant="h6" component="h2">
            Último posicionamento
          </Typography> */}
          {/* <Divider /> */}
          <Grid container className={classes.grid} spacing={0}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                variant="caption"
                className={classes.textIcon}
              >
                <Schedule style={{ marginRight: 10, fontSize: 14 }} />{' '}
                {lastPosition.dateTime} <br />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                gutterBottom
                variant="caption"
                className={classes.textIcon}
              >
                <VpnKey
                  fontSize="small"
                  style={{ marginRight: 10, fontSize: 14 }}
                />
                {lastPosition.ignition ? (
                  <span className={classes.onLine}>ON</span>
                ) : (
                  <span className={classes.offLine}>OFF</span>
                )}{' '}
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                className={classes.textIcon}
              >
                <Speed
                  fontSize="small"
                  style={{ marginRight: 10, fontSize: 14 }}
                />{' '}
                {lastPosition.speed}km/h <br />
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                className={classes.textIcon}
              >
                <FlashOn
                  fontSize="small"
                  style={{ marginRight: 10, fontSize: 14 }}
                />{' '}
                {lastPosition.voltage}v <br />
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                gutterBottom
                variant="caption"
                className={classes.textIcon}
              >
                <Block
                  fontSize="small"
                  style={{ marginRight: 10, fontSize: 14 }}
                />{' '}
                {isLock ? (
                  <span className={classes.offLine}>BLOQUEADO</span>
                ) : (
                  <span className={classes.onLine}>DESBLOQUEADO</span>
                )}{' '}
                <br />
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                className={classes.textIcon}
              >
                <Language
                  fontSize="small"
                  style={{ marginRight: 10, fontSize: 14 }}
                />
                {formatCoord(lastPosition.latitude)} /{' '}
                {formatCoord(lastPosition.longitude)}
                <br />
              </Typography>
              <Typography
                gutterBottom
                variant="caption"
                className={classes.textIcon}
              >
                <BatteryChargingFull
                  fontSize="small"
                  style={{ marginRight: 10, fontSize: 14 }}
                />
                {lastPosition.bateryPercent}%
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                variant="caption"
                className={classes.textIcon}
              >
                <br />
                <PinDrop
                  fontSize="small"
                  style={{ marginRight: 10, fontSize: 14 }}
                />{' '}
                {lastPosition.address ?? (
                  <Skeleton className={classes.skeleton} />
                )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Grid container className={classes.grid} spacing={0}>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="caption"
              className={classes.textIcon}
            >
              <Schedule fontSize="small" style={{ marginRight: 10 }} />{' '}
              <Skeleton className={classes.skeleton} /> <br />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="caption"
              className={classes.textIcon}
            >
              <VpnKey fontSize="small" style={{ marginRight: 10 }} />
              <Skeleton className={classes.skeleton} /> <br />
            </Typography>
            <Typography
              gutterBottom
              variant="caption"
              className={classes.textIcon}
            >
              <Speed fontSize="small" style={{ marginRight: 10 }} />{' '}
              <Skeleton className={classes.skeleton} /> <br />
            </Typography>
            <Typography
              gutterBottom
              variant="caption"
              className={classes.textIcon}
            >
              <FlashOn fontSize="small" style={{ marginRight: 10 }} />{' '}
              <Skeleton className={classes.skeleton} /> <br />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              gutterBottom
              variant="caption"
              className={classes.textIcon}
            >
              <Block fontSize="small" style={{ marginRight: 10 }} />{' '}
              <Skeleton className={classes.skeleton} /> <br />
            </Typography>
            <Typography
              gutterBottom
              variant="caption"
              className={classes.textIcon}
            >
              <Language fontSize="small" style={{ marginRight: 10 }} />
              <Skeleton className={classes.skeleton} /> <br />
            </Typography>
            <Typography
              gutterBottom
              variant="caption"
              className={classes.textIcon}
            >
              <BatteryChargingFull
                fontSize="small"
                style={{ marginRight: 10 }}
              />
              <Skeleton className={classes.skeleton} /> <br />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              variant="caption"
              className={classes.textIcon}
            >
              <br />
              <PinDrop fontSize="small" style={{ marginRight: 10 }} />{' '}
              <Skeleton className={classes.skeleton} />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
