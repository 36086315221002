import { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from 'lib/react-query';
import { AppThemeProvider } from './themeContext';
import { AuthProvider } from './authContext';
import { ToastProvider } from './toastContext';
import { GeofenceProvider } from './geofenceContext';
import { VisualizeLabelProvider } from './visualizeLabelContext';
import { GridProvider } from './gridProvider';
import { MapProvider } from './mapContext';

interface AppProviderProps {
  children: ReactNode;
}

export function AppProvider({ children }: AppProviderProps): JSX.Element {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <AuthProvider>
          <AppThemeProvider>
            <ToastProvider>
              <MapProvider>
                <GeofenceProvider>
                  <GridProvider>
                    <VisualizeLabelProvider>{children}</VisualizeLabelProvider>
                  </GridProvider>
                </GeofenceProvider>
              </MapProvider>
            </ToastProvider>
          </AppThemeProvider>
        </AuthProvider>
      </SnackbarProvider>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  );
}
