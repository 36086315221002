/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import { Fragment, useCallback, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Toolbar,
  Paper,
  Grid,
  ButtonGroup,
  Button,
  Tooltip,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormGroup,
  TableSortLabel,
} from '@material-ui/core';

import { Search } from '@material-ui/icons';

import { Skeleton } from '@material-ui/lab';
import { useStyles } from './styles';
import { GridControlButton } from './ControlButtons/index.tsx';

function descendingComparator(a, b, orderBy) {
  if (['dateTime'].includes(orderBy) && a.device && b.device) {
    if (b.device[orderBy] < a.device[orderBy]) {
      return -1;
    }
    if (b.device[orderBy] > a.device[orderBy]) {
      return 1;
    }
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export function GridTable({
  headCells = [],
  dataCells = [],
  tableActions = [],
  // eslint-disable-next-line no-unused-vars
  lastEvents = [],
  isFetching = false,
  // eslint-disable-next-line prettier/prettier, no-unused-vars
  onRowClick = device => {},
  selectedRow,
  // eslint-disable-next-line prettier/prettier, no-unused-vars
  handleChange = e => {},
  // eslint-disable-next-line prettier/prettier
}) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('deviceId');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [, setRowOpen] = useState();

  // function toggleRow(index) {
  //   setRowOpen(prev => (prev !== index ? index : null));
  // }

  const RenderButtons = useCallback(() => {
    if (tableActions.length > 1) {
      return (
        <ButtonGroup size="small">
          {tableActions.map(({ id, icon: Icon, action, title }) => (
            <Button key={id} type="button" onClick={action}>
              <Icon style={{ fontSize: 18, paddingBottom: 2 }} /> {title}
            </Button>
          ))}
        </ButtonGroup>
      );
    }
    return tableActions.map(({ id, icon: Icon, action, title }) => (
      <Button
        key={id}
        type="button"
        variant="contained"
        size="small"
        onClick={action}
      >
        <Icon /> {title}
      </Button>
    ));
  }, [tableActions]);

  const renderTableLoading = useCallback(() => {
    const rows = [];
    const cells = [];
    for (let i = 0; i < 10; i++) {
      cells.push(
        <TableCell style={{ textAlign: 'center' }}>
          <Skeleton variant="rect" />
        </TableCell>,
      );
    }
    for (let i = 0; i < 1; i++) {
      rows.push(<TableRow>{cells}</TableRow>);
    }
    return <>{rows}</>;
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const EnhancedTableToolbar = useCallback(() => {
    return (
      <Toolbar className={classes.toolbar}>
        <Grid className={classes.buttonContainer}>
          <RenderButtons />
        </Grid>
      </Toolbar>
    );
  }, [classes.buttonContainer, classes.toolbar]);

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const TableBodyContent = useCallback(() => {
    const isSelected = id => selectedRow?.id === id;
    return dataCells.length > 0 ? (
      stableSort(dataCells, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((item, index) => {
          const isItemSelected = isSelected(item.id);
          const formattedItem = {
            ...item,
            ...item.device,
          };
          const data = [
            ...headCells.map(head => {
              return {
                value: head.render
                  ? head.render(formattedItem)
                  : formattedItem[head.value],
                align: head.alignRow || 'center',
              };
            }),
          ];

          return (
            <Fragment key={index}>
              <TableRow
                hover
                // onClick={() => onRowClick(item)}
                aria-checked={isItemSelected}
                tabIndex={-1}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                selected={isItemSelected}
                className={classes.tr}
              >
                {data.map((i, k) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableCell
                    key={k}
                    className={classes.td}
                    align={i.align}
                    // align="center"
                    onClick={() => onRowClick(item)}
                  >
                    {/* eslint-disable-next-line no-nested-ternary */}

                    {i.value}
                  </TableCell>
                ))}
              </TableRow>
            </Fragment>
          );
        })
    ) : (
      <>
        {isFetching ? (
          renderTableLoading()
        ) : (
          <TableRow>
            <TableCell colSpan={17} style={{ textAlign: 'center' }}>
              Vazio
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }, [
    classes,
    dataCells,
    headCells,
    onRowClick,
    order,
    orderBy,
    page,
    rowsPerPage,
    selectedRow?.id,
    isFetching,
    renderTableLoading,
  ]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <GridControlButton top={10} right={8} />
        <Toolbar variant="dense" className={classes.headerToolbar}>
          <FormGroup row>
            <FormControl size="small">
              <OutlinedInput
                style={{ fontSize: 9 }}
                // margin="none"
                id="searchTerms"
                name="searchTerms"
                type="search"
                placeholder="Pesquisar..."
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </FormGroup>
        </Toolbar>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {headCells.length > 0 ? (
                  <>
                    {headCells.map(headCell => (
                      <Tooltip
                        key={headCell.id}
                        title={headCell.label}
                        placement="top"
                      >
                        <TableCell
                          align={headCell.align || 'left'}
                          padding={headCell.disablePadding ? 'none' : 'default'}
                          className={classes.th}
                          style={{ minWidth: headCell.minWidth, padding: 0 }}
                          sortDirection={
                            orderBy === headCell.value ? order : false
                          }
                        >
                          <TableSortLabel
                            active={orderBy === headCell.value}
                            direction={
                              orderBy === headCell.value ? order : 'asc'
                            }
                            onClick={() => handleRequestSort(headCell.value)}
                            hideSortIcon
                          >
                            {headCell.icon ? headCell.icon : headCell.label}
                            {orderBy === headCell.value ? (
                              <span className={classes.visuallyHidden}>
                                {order === 'desc'
                                  ? 'sorted descending'
                                  : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      </Tooltip>
                    ))}
                    {/* <TableCell key="collapseColunm" className={classes.th} /> */}
                  </>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableBodyContent />
            </TableBody>
          </Table>
        </TableContainer>
        {/* <div style={{ position: 'absolute', bottom: 0 }}> */}
        <TablePagination
          labelRowsPerPage="Qtd por pag."
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={dataCells.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          classes={{
            root: classes.tablePaginationRoot,
            toolbar: classes.tablePaginationToobar,
            caption: classes.tablePagination,
            menuItem: classes.tablePagination,
            selectRoot: classes.tablePagination,
          }}
        />
        <EnhancedTableToolbar />
        {/* </div> */}
      </Paper>
    </div>
  );
}
