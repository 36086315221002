/* eslint-disable prettier/prettier */
import {
  useCallback,
  useMemo,
  useState,
  MouseEvent,
  ReactNode,
  ChangeEvent,
  useEffect,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  Grid,
  Button,
  ButtonGroup,
  TableHead,
  Tooltip,
  TableSortLabel,
  InputAdornment,
  TextField,
} from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';

import { Search, SvgIconComponent } from '@material-ui/icons';
import { useStyles } from './styles';

type Order = 'asc' | 'desc';

type HeadCell = {
  id: number;
  label: string;
  value: string;
  minWidth?: number;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  disablePadding?: boolean;
  icon?: ReactNode;
};

type TableAction = {
  id: number;
  title: string;
  icon?: SvgIconComponent;
  action: () => void;
};

interface EnhancedTableHeadProps {
  classes: ReturnType<typeof useStyles>;
  numSelected?: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount?: number;
  headCells: HeadCell[];
  checkBoxReadOnly?: boolean

}

interface EnhancedTableProps {
  toolbar?: boolean;
  search?: boolean;
  headCells: HeadCell[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataCells?: any[];
  tableActions?: TableAction[];
  loading?: boolean;
  // eslint-disable-next-line prettier/prettier
  onRowClick?: (id: string) => void;
  selectedRow?: unknown[];
  // eslint-disable-next-line prettier/prettier
  selectAllRows?: (args: any[]) => void;
  checkBoxReadOnly?: boolean
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  classes: ReturnType<typeof useStyles>;
  children: ReactNode;
}

interface Data {
  id: number;
  label: string;
  value: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (a: any, b: any) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead({
  classes,
  order,
  orderBy,
  numSelected = 0,
  rowCount = 0,
  onSelectAllClick,
   onRequestSort,
  headCells,
  checkBoxReadOnly
}: EnhancedTableHeadProps) {
  const createSortHandler =
    (property: string) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.length > 0 ? (
          <>
            <TableCell padding="checkbox" className={classes.th}>
              <Checkbox
                color="default"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all devices' }}
                disabled={checkBoxReadOnly}
              />
            </TableCell>
            {headCells.map(headCell => (
              <Tooltip key={headCell.id} title={headCell.label} placement="top">
                <TableCell
                  align={headCell.align}
                  padding={headCell.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === headCell.value ? order : false}
                  className={classes.th}
                  style={{ minWidth: headCell.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === headCell.value}
                    direction={orderBy === headCell.value ? order : 'asc'}
                    onClick={createSortHandler(headCell.value)}
                  >
                    {headCell.icon ? headCell.icon : headCell.label}
                    {orderBy === headCell.value ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              </Tooltip>
            ))}
          </>
        ) : null}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({
  numSelected,
  children,
  classes,
}: EnhancedTableToolbarProps) {
  return (
    <Toolbar>
      <Grid className={classes.buttonContainer}>{children}</Grid>
      <Grid
        className={clsx(classes.toolbar, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 && (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selecionado(s)
          </Typography>
        )}
      </Grid>
    </Toolbar>
  );
}

export function EnhancedTable({
  toolbar = false,
  headCells = [],
  dataCells = [],
  tableActions = [],
  loading = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRowClick = () => { },
  selectedRow = [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectAllRows = () => { },
  search,
  checkBoxReadOnly = false
}: EnhancedTableProps): JSX.Element {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('value');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    setFilteredData(dataCells);
  }, [dataCells]);

  const handleSerach = useCallback(
    SearchValue => {
      if (SearchValue) {
        const filtered = dataCells.filter(item => {
          return Object.values(item).some(
            value =>
              typeof value === 'string' &&
              value
                .toString()
                .toLowerCase()
                .includes(SearchValue.toLowerCase()),
          );
        });
        setFilteredData(filtered);
      } else {
        setFilteredData(dataCells);
      }
    },
    [dataCells],
  );

  const renderButtons = useMemo(() => {
    if (tableActions.length > 1) {
      return (
        <ButtonGroup size="small">
          {tableActions.map(({ id, icon: Icon, action, title }) => {
            return (
              <Button key={id} type="button" onClick={action}>
                {Icon && <Icon style={{ fontSize: 15, paddingBottom: 2 }} />}
                {title}
              </Button>
            );
          })}
        </ButtonGroup>
      );
    }
    return tableActions.map(({ id, icon: Icon, action, title }) => (
      <Button
        key={id}
        type="button"
        variant="contained"
        size="small"
        onClick={action}
      >
        {Icon && <Icon style={{ fontSize: 15, paddingBottom: 2 }} />}
        {title}
      </Button>
    ));
  }, [tableActions]);

  const handleRequestSort = useCallback(
    (_event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [orderBy, order],
  );

  const handleSelectAllClick = useCallback(
    e => {
      const { checked } = e.target;
      if (checked) {
        const newSelecteds = filteredData.map(item => item.id);
        selectAllRows(newSelecteds);
        return;
      }
      selectAllRows([]);
    },
    [filteredData, selectAllRows],
  );

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TableBodyContent = useCallback(() => {
    const isSelected = (key: number) => selectedRow.indexOf(key) !== -1;

    if (!toolbar) {
      return (
        <>
          {filteredData.length > 0 ? (
            stableSort(filteredData, getComparator(order, orderBy)).map(
              (item, index) => {
                const isItemSelected = isSelected(item.id as number);
                const labelId = `enhanced-table-checkbox-${index}`;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const data = [...headCells.map(head => item[head.value])];

                return (
                  <TableRow
                    className={classes.tr}
                    hover
                    onClick={() => {
                      if(!checkBoxReadOnly){
                        onRowClick(item.id)}
                      }
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={item.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="default"
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                        disabled={checkBoxReadOnly}
                      />
                    </TableCell>
                    {data.map((i, k) => (
                      <TableCell
                        // eslint-disable-next-line react/no-array-index-key
                        key={k}
                        onClick={() => onRowClick(item.id)}
                        id={labelId}
                        className={classes.td}
                        align={i.align || 'center'}
                      >
                        {i}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              },
            )
          ) : (
            <TableRow>
              <TableCell
                colSpan={headCells.length + 1}
                style={{ textAlign: 'center' }}
              >
                Vazio
              </TableCell>
            </TableRow>
          )}
        </>
      );
    }
    return (
      <>
        {filteredData.length > 0 ? (
          stableSort(filteredData, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => {
              const isItemSelected = isSelected(item.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              const data = [...headCells.map(head => item[head.value])];

              return (
                <TableRow
                  hover
                  onClick={() => onRowClick(item.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={item.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="default"
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>

                  {data.map((i, k) => (
                    <TableCell
                      // eslint-disable-next-line react/no-array-index-key
                      key={k}
                      id={labelId}
                      className={classes.td}
                    >
                      {i}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
        ) : (
          <TableRow>
            <TableCell
              colSpan={headCells.length + 1}
              style={{ textAlign: 'center' }}
            >
              Vazio
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }, [toolbar, filteredData, order, orderBy, page, rowsPerPage, headCells, selectedRow, classes.tr, classes.td, checkBoxReadOnly, onRowClick]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer component={Paper} className={classes.paper}>
          <Toolbar style={{ display: search ? '' : 'none' }}>
            <TextField
              margin="dense"
              placeholder="Pesquisar..."
              fullWidth
              size="small"
              name="search"
              id="search"
              onChange={e => {
                handleSerach(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </Toolbar>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selectedRow.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredData.length}
              headCells={headCells}
              checkBoxReadOnly={checkBoxReadOnly}
            />
            <TableBody style={{ maxHeight: 300 }}>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Skeleton variant="rect" animation="pulse" width="auto" />
                  </TableCell>
                </TableRow>
              ) : (
                <TableBodyContent />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {toolbar && (
          <>
            <TablePagination
              labelRowsPerPage="Qtd por pag."
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <EnhancedTableToolbar
              numSelected={selectedRow.length}
              classes={classes}
            >
              {renderButtons}
            </EnhancedTableToolbar>
          </>
        )}
      </Paper>
    </div>
  );
}
