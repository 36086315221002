import React from 'react';
import { List, Box, Paper } from '@material-ui/core';
import { useAuth } from 'context/authContext';
import logo from 'assets/one_icon.png';
import { REACT_APP_LOGO_BASE_URL } from 'config';
import { useStyles } from './styles';
import { SettingsSection } from '../SettingsSection';
import { NavItems } from '../NavItems';

export function SideNav(): JSX.Element {
  const { isAuthenticated } = useAuth();
  const classes = useStyles({ isAuthenticated });

  function Logo() {
    const subDomain = window.location.hostname.split('.').slice(0, 2).join('.');
    return (
      <Box className={classes.logo}>
        <img
          src={`${REACT_APP_LOGO_BASE_URL}${subDomain}_sidebaricon.png`}
          alt="logo"
          width={30}
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = logo;
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <Paper className={classes.root} elevation={3}>
        <Logo />

        <List component="nav">
          <NavItems />{' '}
        </List>
        <SettingsSection />
      </Paper>
    </>
  );
}
