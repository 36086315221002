import React, { useCallback, useEffect, useState } from 'react';
import {
  Switch,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Box,
} from '@material-ui/core';
import {
  BrightnessHigh,
  Brightness3,
  Settings,
  ExitToApp,
} from '@material-ui/icons';
import { useAuth } from 'context/authContext';
import { useCustomTheme } from 'context/themeContext';
import { LocalThemeProvider } from 'context/LocalThemeProvider';
import { useStyles } from './styles';
import { ProfileSection } from '../components/ProfileSection';

export function SettingsSection() {
  const classes = useStyles();

  const { SignOut, user } = useAuth();
  const { appTheme, setTheme } = useCustomTheme();

  const [switchChecked, setSwitchChecked] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const toggleTheme = useCallback(
    e => {
      const { checked } = e.target;
      setTheme(checked ? 'dark' : 'light');
      setSwitchChecked(checked);
    },
    [setTheme],
  );

  const handleOpenSettings: React.MouseEventHandler<HTMLSpanElement> =
    useCallback(e => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setAnchorEl(e.currentTarget);
    }, []);

  const handleCloseSettings = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    setSwitchChecked(appTheme === 'dark');
  }, [appTheme]);

  return (
    <>
      <Box className={classes.settings}>
        <Tooltip title="Menu">
          <IconButton
            aria-label="settings menu"
            component="span"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleOpenSettings}
            style={{ paddingBottom: 10 }}
            size="small"
          >
            <Settings fontSize="small" />
          </IconButton>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseSettings}
        >
          <MenuItem>
            <p>{user?.login}</p>
          </MenuItem>

          <MenuItem>
            <Switch
              color="default"
              checked={switchChecked}
              onChange={toggleTheme}
              icon={
                <BrightnessHigh
                  fontSize="small"
                  className={classes.lightModeChecked}
                />
              }
              checkedIcon={
                <Brightness3
                  fontSize="small"
                  className={classes.darkModeChecked}
                />
              }
              inputProps={{ 'aria-label': 'theme checkbox' }}
            />
            Tema{' '}
          </MenuItem>
          <LocalThemeProvider>
            <ProfileSection onClick={handleCloseSettings} />
          </LocalThemeProvider>
          <MenuItem
            onClick={() => {
              handleCloseSettings();
              SignOut();
            }}
          >
            <ListItemIcon>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
}
