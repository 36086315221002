import { makeStyles, Theme } from '@material-ui/core/styles';

type styleProps = {
  color?: string;
  isMobile?: boolean;
};

export const useStyles = makeStyles<Theme, styleProps>(theme => ({
  subHeader: {
    display: 'flex',
  },
  card: {
    [theme.breakpoints.up('md')]: {
      minWidth: 350,
      maxWidth: 350,
      position: 'absolute',
      bottom: -5,
      left: -220,
    },

    cursor: 'text',
    pointerEvents: 'auto',
  },
  cardHeader: {
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      padding: 1,
    },
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  title: {
    fontSize: 14,
  },
  text: {
    fontSize: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 9,
    },
  },
  pos: {
    // marginBottom: 12,
  },
  icon: {
    color: (props: any) => props.color,
    marginLeft: 2,
  },
  box: {},
  cardContainer: {
    padding: 0,
    margin: 0,

    [theme.breakpoints.up('md')]: {
      minWidth: 75,
      minHeight: 150,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 160,
    },
  },
  grid: {
    flexGrow: 1,
  },
  textIcon: {
    fontSize: 9,
    alignItems: 'center',
    display: 'flex',
  },
  onLine: {
    color: theme.palette.success.main,
  },
  offLine: {
    color: theme.palette.error.main,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      top: 45,
    },
  },
  tabs: {
    backgroundColor: theme.palette.background.default,
    minHeight: 30,
    maxHeight: 30,
  },
  tab: {
    minWidth: 10,
    minHeight: 30,
    maxHeight: 30,
  },
  tabPanel: {},
  tabIcon: {
    // padding: 0,
  },
  appBar: {
    // height: 10,
    '&:button': {
      maxHeigth: 20,
    },
  },
  input: {
    fontSize: 10,
  },
}));
