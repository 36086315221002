import { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';

import { AppProvider } from 'context/index';

import { Navbar } from 'components/Navbar';
import { Fallback } from 'components/Fallback';
import { InfoSnackBar } from 'components/InfoAlert';
import withClearCache from 'components/ClearCache';
import { REACT_APP_LOGO_BASE_URL } from 'config';
import { AppRoutes } from './routes';

function Main(): JSX.Element {
  useEffect(() => {
    const subDomain = window.location.hostname.split('.').slice(0, 2).join('.');
    const faviconLink = document.querySelector(
      "link[rel='icon']",
    ) as HTMLLinkElement;
    if (faviconLink) {
      faviconLink.href = `${REACT_APP_LOGO_BASE_URL}${subDomain}_favicon.ico`;
    }
  }, []);
  return (
    <AppProvider>
      <CssBaseline />
      <BrowserRouter>
        <Navbar />
        <InfoSnackBar />
        <Suspense fallback={<Fallback />}>
          <AppRoutes />
        </Suspense>
      </BrowserRouter>
    </AppProvider>
  );
}

const ClearCacheComponent = withClearCache(Main);

function App(): JSX.Element {
  return <ClearCacheComponent />;
}

export default App;
