export const STORAGE_PREFIX = '@light_web_client:';
export const SUB_ACCOUNTS_QUERY_KEY = 'sub_accounts';
export const VEHICLES_QUERY_KEY = 'vehicles';
export const CLIENT_VEHICLES_QUERY_KEY = 'client_vehicles';
export const PERMISSIONS_QUERY_KEY = 'permissions';
export const EVENT_TYPES_QUERY_KEY = 'event_types';
export const ADDRESS_QUERY_KEY = 'address';
export const REACT_APP_GOOGLE_API_KEY =
  'AIzaSyBiHlfy6ROVMX0AkuihoWGrASKb8JLpLIc';
export const REACT_APP_API_URL = 'https://light-admin.one.srv.br';
// export const REACT_APP_API_URL = 'http://localhost:3333';
export const REACT_APP_LOGO_BASE_URL =
  'https://monitoramento-assets.s3.amazonaws.com/logo/';
