import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

export const SwipeableTemporaryDrawer = ({
  isOpen = false,
  // eslint-disable-next-line prettier/prettier
  onClose = () => { },
  // eslint-disable-next-line prettier/prettier
  onOpen = () => { },
  children,
}) => {
  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      disableDiscovery
      disableSwipeToOpen
      transitionDuration={500}
    >
      {children}
    </SwipeableDrawer>
  );
};
