import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert as MuiAlert, AlertProps, AlertTitle } from '@material-ui/lab';
import { makeStyles, Theme } from '@material-ui/core/styles';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export function InfoSnackBar(): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={60000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning">
          <AlertTitle>Comunicado:</AlertTitle>
          Estamos funcionando com novo telefone, pedimos a gentileza de
          registrarem nosso novo número: 0800 080 1110
        </Alert>
      </Snackbar>
    </div>
  );
}
