import { VEHICLES_QUERY_KEY } from 'config';
import { useAuth } from 'context/authContext';
import { useQuery } from 'react-query';
import { sliceIntoChunks } from 'utils/sliceIntoChunks';
import { IVehicle } from '../types';
import { VehiclesService } from './vehicleService';

const ONE_MINUTE = 60 * 1000;

// function keepTrackingDevicePositions(device: IDevice) {
//   const storagedPosition = localStorage.getItem(
//     `@light_web_client:device_coords-${device.imei}`,
//   );

//   // const storePosition = [
//   //   { lat: Number(device.latitude), lng: Number(device.longitude) },
//   // ];

//   if (storagedPosition) {
//     const parsed = JSON.parse(storagedPosition);
//     parsed.splice(0, parsed.length - 30);
//     parsed.push({
//       lat: Number(device.latitude),
//       lng: Number(device.longitude),
//     });
//     localStorage.removeItem(`@light_web_client:device_coords-${device.imei}`);
//     // localStorage.setItem(
//     //   `@light_web_client:device_coords-${device.imei}`,
//     //   JSON.stringify(parsed),
//     // );
//   }
//   if (!storagedPosition) {
//     // localStorage.setItem(
//     //   `@light_web_client:device_coords-${device.imei}`,
//     //   JSON.stringify(storePosition),
//     // );
//   }
// }

async function getVehicles(ids: number[]): Promise<IVehicle[]> {
  let vehiclesData: IVehicle[] = [];
  const chunkIds = sliceIntoChunks<number>(ids, 100);

  const response = await Promise.all(
    chunkIds.map(ids => VehiclesService.list(ids)),
  );

  response.forEach(r => {
    const data = r.data.payload;
    vehiclesData = [...vehiclesData, ...data];
  });

  return vehiclesData;
}

export const useVehicles = () => {
  const { user } = useAuth();

  return useQuery(
    [VEHICLES_QUERY_KEY, user?.vehicles],
    () => getVehicles(user?.vehicles),
    {
      refetchInterval: ONE_MINUTE,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: Boolean(user?.vehicles?.length),
      queryHash: VEHICLES_QUERY_KEY,
    },
  );

  // return data;
};
