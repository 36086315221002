import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',

    // display: 'flex',
  },
  btn: {
    height: 25,
  },
  btnIcon: {
    fontSize: 20,
  },
  td: {
    [theme.breakpoints.only('sm')]: {
      fontSize: 8,
    },
    [theme.breakpoints.only('md')]: {
      fontSize: 8,
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: 9,
    },
    [theme.breakpoints.between(1920, 2100)]: {
      fontSize: 10,
    },
    [theme.breakpoints.up(2100)]: {
      fontSize: 18,
    },

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 0,
    paddingBottom: 0,
    // textAlign: 'left',
  },
}));
