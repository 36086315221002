import { AxiosResponse } from 'axios';
import { Geofence, ICreateGeofence, IUpdateGeofence } from 'types/Geofence';
import { IResponse } from 'types/Response';
import { Api } from '../lib/axios';

export const GeofenceService = {
  createGeofence: (data: ICreateGeofence): Promise<AxiosResponse<void>> =>
    Api.post('/geofence', data),
  findGeofenceById: (geofenceId: string): Promise<AxiosResponse<Geofence>> =>
    Api.get(`/geofence/${geofenceId}`),
  lisAllGeofencesByCompany: (
    companyId: number,
  ): Promise<AxiosResponse<IResponse<Geofence[]>>> =>
    Api.get(`geofence/company/${companyId}`),
  updateGeofence: (
    geofenceId: string,
    data: IUpdateGeofence,
  ): Promise<AxiosResponse> => Api.put(`geofence/${geofenceId}`, data),
  deleteGeofence: (geofenceId: string): Promise<AxiosResponse> =>
    Api.delete(`geofence/${geofenceId}`),
  listAllGeofenceByClient: (clientId: number): Promise<AxiosResponse> =>
    Api.get(`geofence/client/${clientId}`),
  addVehicleInFence: (
    fenceId: string,
    vehicleIds: number[],
  ): Promise<AxiosResponse> =>
    Api.post(`geofence/add_vehicles/${fenceId}`, { vehicleIds }),
  removeVehicleFromFence: (
    fenceId: string,
    vehicleIds: number[],
  ): Promise<AxiosResponse> =>
    Api.delete(
      `geofence/remove_vehicles/${fenceId}?vehicleIds[]=${vehicleIds}`,
    ),
};
