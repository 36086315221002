import { useEffect, useState } from 'react';
import { BottomNavigation } from '@material-ui/core';

import { useAuth } from 'context/authContext';
import { useCustomTheme } from 'context/themeContext';
import { useStyles } from './styles';
import { BottomNavItems } from '../BottomNavItems';

export function BottomNav(): JSX.Element {
  const { isAuthenticated } = useAuth();
  const classes = useStyles({ isAuthenticated });
  const [, setSwitchChecked] = useState(false);

  const { appTheme } = useCustomTheme();

  useEffect(() => {
    if (appTheme === 'dark') {
      setSwitchChecked(true);
    }
  }, [appTheme]);

  return (
    <>
      <BottomNavigation className={classes.bottomNavContainer}>
        <BottomNavItems />
      </BottomNavigation>
    </>
  );
}
