import { Box, makeStyles, Theme } from '@material-ui/core';
import { Header } from 'components';
import { useDisclosure } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useVehicles } from '../api';
import { SearchBox } from '../components/SearchBox';
import { VehiclesList } from '../components/VehiclesList';
import { IVehicle } from '../types';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    background: theme.palette.background.paper,
  },
  content: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    padding: theme.spacing(8.5, 2),
    color: theme.palette.text.secondary,
    background:
      theme.palette.type === 'light'
        ? '#e5e1e1'
        : theme.palette.background.paper,
  },
}));

export function Vehicles() {
  const classes = useStyles();
  const { isOpen, toggle } = useDisclosure();
  const { data: vehicles, isLoading } = useVehicles();
  const [filteredResult, setFilteredResult] = useState<IVehicle[]>([]);
  const [searchTerms, setSearchTerms] = useState('');

  const handleSearchVehicles = useCallback(e => {
    const { value } = e.target;
    setSearchTerms(value);
  }, []);

  useEffect(() => {
    if (vehicles !== undefined) {
      const result = vehicles?.filter(item => {
        return Object.values(item).some(
          value =>
            typeof 'string' &&
            value !== null &&
            value.toString().toLowerCase().includes(searchTerms.toLowerCase()),
        );
      });
      setFilteredResult(result);
    }
  }, [searchTerms, vehicles]);

  return (
    <>
      <Header title="Veículos" openSearch={toggle} />
      <Box className={classes.content}>
        <SearchBox
          isOpen={isOpen}
          handleSearchVehicles={handleSearchVehicles}
        />
        <VehiclesList loading={isLoading} vehicles={filteredResult} />
      </Box>
    </>
  );
}
