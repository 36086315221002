import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { storage } from 'utils/storage';
import { TCustomerSettings } from 'types/customer';
import { useAuth } from './authContext';
import { light, dark } from '../styles/theme.js';

interface ThemeContextProps {
  appTheme: 'light' | 'dark' | 'time';
  setTheme: (theme: 'light' | 'dark' | 'time') => void;
}
const themes = {
  light,
  dark,
  time: new Date().getHours() >= 18 ? dark : light,
};

function getTheme(theme: 'light' | 'dark' | 'time') {
  return themes[theme];
}

const ThemeContext = createContext({} as ThemeContextProps);

const AppThemeProvider = ({ children }: { children: ReactNode }) => {
  const { updateCustomerSettings, user } = useAuth();

  const [appTheme, setAppTheme] = useState<'dark' | 'light' | 'time'>(() => {
    const localValues = storage.getItem({
      key: 'settings',
      storageType: 'local',
    }) as TCustomerSettings;

    if (localValues) {
      return localValues.theme ?? 'dark';
    }

    return 'dark';
  });

  const theme = getTheme(appTheme);

  const setTheme = useCallback(
    (value: 'dark' | 'light' | 'time') => {
      // localStorage.setItem('@bemoove:theme', value);
      setAppTheme(value);
      updateCustomerSettings({ theme: value });
    },
    [updateCustomerSettings],
  );

  useEffect(() => {
    if (user.settings?.theme) setAppTheme(user.settings?.theme);
  }, [user.settings?.theme]);

  return (
    <ThemeContext.Provider value={{ appTheme, setTheme }}>
      <ThemeProvider theme={{ ...theme }}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

function useCustomTheme() {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useTheme must be used within an AuthPovider');
  }

  return context;
}

export { AppThemeProvider, useCustomTheme };
