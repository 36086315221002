import { useMediaQuery, useTheme } from '@material-ui/core';
import { BottomNav } from './BottomNav';
import { SideNav } from './Sidebar';

export function Navbar(): JSX.Element {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobileScreen ? <BottomNav /> : <SideNav />;
}
