import {
  DialogTitle,
  FormControlLabel,
  Dialog,
  List,
  ListItem,
  makeStyles,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Switch,
  Button,
} from '@material-ui/core';

import { useCustomTheme } from 'context/themeContext';
import { ChangeEvent, useCallback, useState } from 'react';
import { DirectionIcon } from 'components/Grid/GridSettingsModal/MapSettings';
import { mapSettings } from 'types/customer';
import { useAuth } from 'context/authContext';
import { DirectionsCar, NavigationRounded } from '@material-ui/icons';

interface SettingsMenuProps {
  open: boolean;
  onClose: () => void;
}

export const useStyles = makeStyles(() => ({
  dialog: {
    width: '100%',
  },
  label: {
    maring: 0,
  },
}));

export function SettingsMenu({ open, onClose }: SettingsMenuProps) {
  const classes = useStyles();
  const { user, updateCustomerSettings } = useAuth();

  const [mapSettings, setMapSettings] = useState<mapSettings>(() => {
    return user.settings?.map ?? ({} as mapSettings);
  });

  const [hasUpdate, setHasUpdate] = useState(false);
  const { setTheme, appTheme } = useCustomTheme();

  const handleChangeMapTheme = useCallback((_, value: string) => {
    const theme = value as 'dark' | 'light' | 'time';
    setMapSettings(prev => ({ ...prev, theme }));
    setHasUpdate(true);
  }, []);

  const handleChangeAppTheme = useCallback(
    (_, value: string) => {
      setTheme(value as 'dark' | 'light' | 'time');
    },
    [setTheme],
  );

  const handleChangeMarker = useCallback((_, value: string) => {
    const marker = value as 'car' | 'direction';
    setMapSettings(prev => ({ ...prev, marker }));
    setHasUpdate(true);
  }, []);

  const handleChangeTypeLabel = useCallback((_, value: string) => {
    const typeLabel = value as 'plate' | 'custom';
    setMapSettings(prev => ({ ...prev, typeLabel }));
    setHasUpdate(true);
  }, []);

  const handleChangeSwitchCluster = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMapSettings(prev => ({ ...prev, cluster: event.target.checked }));
      setHasUpdate(true);
    },
    [],
  );

  const handleChangeSwitchShowLabel = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMapSettings(prev => ({ ...prev, showLabel: event.target.checked }));
      setHasUpdate(true);
    },
    [],
  );

  const handleSave = useCallback(async () => {
    try {
      await updateCustomerSettings({ map: mapSettings });
      setHasUpdate(false);
    } catch (error) {
      console.log(error);
    }
  }, [mapSettings, updateCustomerSettings]);

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog} fullWidth>
      <DialogTitle>Ajustes</DialogTitle>
      <List style={{ padding: 5 }}>
        <ListItem>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tema app</FormLabel>

            <RadioGroup
              row
              name="appTheme"
              defaultValue={appTheme}
              onChange={handleChangeAppTheme}
            >
              <FormControlLabel
                value="light"
                control={<Radio size="small" color="primary" />}
                label="Dia"
                labelPlacement="end"
              />
              <FormControlLabel
                value="dark"
                control={<Radio size="small" color="primary" />}
                label="Noite"
                labelPlacement="end"
              />
              <FormControlLabel
                value="time"
                control={<Radio size="small" color="primary" />}
                label="Automático"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </ListItem>
        <Divider />
        <ListItem>
          <FormControl component="fieldset">
            <FormLabel component="legend">Marcador</FormLabel>
            <RadioGroup
              row
              name="custom_marker"
              defaultValue="top"
              onChange={handleChangeMarker}
              value={mapSettings.marker}
            >
              <FormControlLabel
                value="car"
                label={
                  <DirectionsCar fontSize="small" style={{ color: 'green' }} />
                }
                control={<Radio size="small" color="primary" />}
                labelPlacement="top"
                classes={{
                  labelPlacementTop: classes.label,
                  label: classes.label,
                  root: classes.label,
                }}
              />
              <FormControlLabel
                value="direction"
                control={<Radio size="small" color="primary" />}
                label={<DirectionIcon />}
                labelPlacement="top"
              />
              <FormControlLabel
                value="navigation"
                control={<Radio color="primary" size="small" />}
                label={<NavigationRounded style={{ color: 'green' }} />}
                labelPlacement="top"
              />
            </RadioGroup>
            <RadioGroup row name="position" defaultValue="top">
              <FormControlLabel
                control={
                  <Switch
                    name="marker_cluster"
                    size="small"
                    onChange={handleChangeSwitchCluster}
                    checked={mapSettings.cluster}
                  />
                }
                label="Agrupar marcadores"
              />
            </RadioGroup>
          </FormControl>
        </ListItem>
        <Divider />
        <ListItem>
          <FormControl component="fieldset">
            <FormLabel component="legend">Rótulo</FormLabel>
            <RadioGroup
              row
              name="custom_label"
              defaultValue="top"
              onChange={handleChangeTypeLabel}
              value={mapSettings.typeLabel}
            >
              <FormControlLabel
                value="plate"
                control={<Radio size="small" color="primary" />}
                label="Placa"
                labelPlacement="end"
              />
              <FormControlLabel
                value="custom"
                control={<Radio size="small" color="primary" />}
                label="Rótulo personalizado"
                labelPlacement="end"
              />
            </RadioGroup>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                control={
                  <Switch
                    name="show_label_switch"
                    size="small"
                    onChange={handleChangeSwitchShowLabel}
                    checked={mapSettings.showLabel}
                  />
                }
                label="Exibir rótulo"
              />
            </RadioGroup>
          </FormControl>
        </ListItem>
        <Divider />
        <ListItem>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tema mapa</FormLabel>
            <RadioGroup
              row
              name="custom_map_theme"
              defaultValue="top"
              onChange={handleChangeMapTheme}
              value={mapSettings.theme}
            >
              <FormControlLabel
                value="light"
                control={<Radio size="small" color="primary" />}
                label="Dia"
                labelPlacement="end"
              />
              <FormControlLabel
                value="dark"
                control={<Radio size="small" color="primary" />}
                label="Noite"
                labelPlacement="end"
              />
              <FormControlLabel
                value="time"
                control={<Radio size="small" color="primary" />}
                label="Automático"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </ListItem>
        <ListItem
          style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'end',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            disabled={!hasUpdate}
            onClick={handleSave}
          >
            Aplicar
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
}
