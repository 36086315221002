import { useMediaQuery, useTheme } from '@material-ui/core';
import React, {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import './index.css';

const SplitPaneContext = createContext();

export const SplitPanelRow = ({ children, ...rest }) => {
  const [clientHeight, setClientHeight] = useState(415);
  const [clientWidth, setClientWidth] = useState(null);
  const [position, setPosition] = useState('top');

  const yDividerPos = useRef(null);
  const xDividerPos = useRef(null);

  const onMouseHoldDown = e => {
    yDividerPos.current = e.clientY;
    xDividerPos.current = e.clientX;
  };

  const onMouseHoldUp = () => {
    yDividerPos.current = null;
    xDividerPos.current = null;
  };

  const onMouseHoldMove = e => {
    if (!yDividerPos.current && !xDividerPos.current) {
      return;
    }

    setClientHeight(clientHeight - e.clientY + yDividerPos.current);
    setClientWidth(clientWidth + e.clientX - xDividerPos.current);

    yDividerPos.current = e.clientY;
    xDividerPos.current = e.clientX;
  };

  useEffect(() => {
    document.addEventListener('mouseup', onMouseHoldUp);
    document.addEventListener('mousemove', onMouseHoldMove);

    return () => {
      document.removeEventListener('mouseup', onMouseHoldUp);
      document.removeEventListener('mousemove', onMouseHoldMove);
    };
  });

  const moveUp = () => {
    setPosition('top');
  };

  const moveDown = () => {
    setPosition('bottom');
  };

  const moveMiddle = () => {
    setPosition('middle');
  };

  const setCurrentClientHeight = height => {
    if (height < 30) {
      setPosition('bottom');
      setClientHeight(1);
    } else {
      setClientHeight(height);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
      }}
      {...rest}
    >
      <SplitPaneContext.Provider
        value={{
          clientHeight,
          setClientHeight,
          clientWidth,
          setClientWidth,
          onMouseHoldDown,
          moveUp,
          moveDown,
          moveMiddle,
          setCurrentClientHeight,
          position,
        }}
      >
        {children}
      </SplitPaneContext.Provider>
    </div>
  );
};

export const SplitPanelCol = ({ children, ...rest }) => {
  const [clientHeight, setClientHeight] = useState(415);
  const [clientWidth, setClientWidth] = useState(null);
  const [position, setPosition] = useState('middle');

  const yDividerPos = useRef(null);
  const xDividerPos = useRef(null);

  const onMouseHoldDown = e => {
    yDividerPos.current = e.clientY;
    xDividerPos.current = e.clientX;
  };

  const onMouseHoldUp = () => {
    yDividerPos.current = null;
    xDividerPos.current = null;
  };

  const onMouseHoldMove = e => {
    if (!yDividerPos.current && !xDividerPos.current) {
      return;
    }

    setClientHeight(clientHeight - e.clientY + yDividerPos.current);
    setClientWidth(clientWidth + e.clientX - xDividerPos.current);
    setPosition(undefined);

    yDividerPos.current = e.clientY;
    xDividerPos.current = e.clientX;
  };

  useEffect(() => {
    document.addEventListener('mouseup', onMouseHoldUp);
    document.addEventListener('mousemove', onMouseHoldMove);

    return () => {
      document.removeEventListener('mouseup', onMouseHoldUp);
      document.removeEventListener('mousemove', onMouseHoldMove);
    };
  });

  const moveUp = () => {
    setPosition('top');
  };

  const moveDown = () => {
    setPosition('bottom');
  };

  const moveMiddle = () => {
    setPosition('middle');
  };

  const setCurrentClientHeight = height => {
    if (height < 30) {
      setPosition('bottom');
      setClientHeight(1);
    } else {
      setClientHeight(height);
    }
  };
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      {...rest}
    >
      <SplitPaneContext.Provider
        value={{
          clientHeight,
          setClientHeight,
          clientWidth,
          setClientWidth,
          onMouseHoldDown,
          moveDown,
          moveMiddle,
          moveUp,
          position,
          setCurrentClientHeight,
        }}
      >
        {children}
      </SplitPaneContext.Provider>
    </div>
  );
};

export const DividerRow = ({ ...rest }) => {
  const { onMouseHoldDown } = useContext(SplitPaneContext);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onMouseDown={onMouseHoldDown}
      style={{
        border: '2px solid black',
        cursor: 'row-resize',
      }}
      {...rest}
    />
  );
};

export const DividerCol = ({ ...rest }) => {
  const { onMouseHoldDown } = useContext(SplitPaneContext);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onMouseDown={onMouseHoldDown}
      style={{
        border: '5px solid black',
        cursor: 'col-resize',
      }}
      {...rest}
    />
  );
};

export const SplitPanelTop = ({ children, ...rest }) => {
  return (
    <div className="split-pane-top" {...rest}>
      {children}
    </div>
  );
};

export const SplitPanelBottom = ({ children, ...rest }) => {
  const botRef = createRef();
  const { clientHeight, setClientHeight, position, setCurrentClientHeight } =
    useContext(SplitPaneContext);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const xl = useMediaQuery(theme.breakpoints.between(1920, 2100));
  const xxl = useMediaQuery(theme.breakpoints.up(2100));

  const getSize = useCallback(() => {
    if (sm) return '200px';
    if (md) return '200px';
    if (lg) return '190px';
    if (xl) return '200px';
    if (xxl) return '300px';
    return '30%';
  }, [lg, md, sm, xl, xxl]);

  useEffect(() => {
    if (!clientHeight) {
      setClientHeight(botRef.current.clientHeight);
      return;
    }

    if (position === 'top') {
      botRef.current.style.minHeight = `100%`;
      botRef.current.style.maxHeight = `100%`;
      setCurrentClientHeight(botRef.current.offsetHeight);
      return;
    }
    if (position === 'bottom') {
      botRef.current.style.minHeight = 0;
      botRef.current.style.maxHeight = 0;
      setCurrentClientHeight(1);
      return;
    }
    if (position === 'middle') {
      const size = getSize();
      botRef.current.style.minHeight = size;
      botRef.current.style.maxHeight = size;
      setCurrentClientHeight(botRef.current.offsetHeight);
      return;
    }
    botRef.current.classList.remove('split-pane-bottom-transition');
    botRef.current.style.minHeight = `min(100%,${clientHeight}px)`;
    botRef.current.style.maxHeight = `min(100%,${clientHeight}px)`;
    setCurrentClientHeight(botRef.current.offsetHeight);
    // botRef.current.style.minHeight = `${clientHeight}px`;
    // botRef.current.style.maxHeight = `${clientHeight}px`;
  }, [
    clientHeight,
    setClientHeight,
    botRef,
    position,
    setCurrentClientHeight,
    getSize,
  ]);

  return (
    <div className="split-pane-bottom" ref={botRef} {...rest}>
      {children}
    </div>
  );
};

export const SplitPanelLeft = props => {
  const topRef = createRef();
  const { clientWidth, setClientWidth } = useContext(SplitPaneContext);

  useEffect(() => {
    if (!clientWidth) {
      setClientWidth(topRef.current.clientWidth / 2);
      return;
    }

    topRef.current.style.minWidth = `${clientWidth}px`;
    topRef.current.style.maxWidth = `${clientWidth}px`;
  }, [clientWidth, setClientWidth, topRef]);

  return <div {...props} className="split-pane-left" ref={topRef} />;
};

export const SplitPanelRight = ({ children }) => {
  return <div className="split-pane-right">{children}</div>;
};

export function useSplitPane() {
  const context = useContext(SplitPaneContext);

  if (!context) {
    throw new Error('useSplitPane must be used within an SplitPaneProvider');
  }

  return context;
}
