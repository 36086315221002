import { makeStyles } from '@material-ui/core';
import { config } from 'components/UI';

export const useProfileStyle = makeStyles(({ palette, breakpoints }) => ({
  mainInfos: {
    padding: '32px 40px',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 60,
    alignItems: 'center',
    [breakpoints.down(config.modalBreakpoint)]: {
      gridTemplateColumns: 'auto',
      justifyContent: 'center',
      gap: 18,
      padding: '0',
    },
  },
  profilePic: {
    width: 200,
    height: 200,
    borderRadius: '50%',
  },
  status: {
    fontSize: 12,
    position: 'relative',
    textTransform: 'capitalize',
    fontWeight: 500,
    marginTop: 12,

    '&[data-status="inactive"]': {
      color: palette.error.main,
    },
    '&[data-status="active"]': {
      color: palette.success.main,
    },
    [breakpoints.down(config.modalBreakpoint)]: {
      marginTop: 0,
    },
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 4,
    [breakpoints.down(config.modalBreakpoint)]: {
      flexDirection: 'column',
      gap: 12,
    },
  },
  statsInfo: {
    [breakpoints.down(config.modalBreakpoint)]: {
      display: 'grid',
      gridTemplateColumns: 'auto',
      justifyContent: 'center',
      marginBottom: 16,
      '& > *': {
        textAlign: 'center',
      },
    },
  },
}));
