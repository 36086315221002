import { AxiosResponse } from 'axios';
import { Api } from 'lib/axios';
import { TCustomerSettings } from 'types/customer';

export const CustomerSettingsService = {
  updateByCustomerId: (
    clientId: number,
    data: TCustomerSettings,
  ): Promise<AxiosResponse<{ message: string }>> =>
    Api.put(`/client_settings/${clientId}`, data),
};
