import { useRoutes } from 'react-router-dom';
import { useAuth } from 'context/authContext';
import { protectedRoutes } from './private.routes';
import { publicRoutes } from './public.routes';

export const AppRoutes = () => {
  const { isAuthenticated } = useAuth();
  const routes = isAuthenticated ? protectedRoutes : publicRoutes;
  const element = useRoutes([...routes]);

  return <>{element}</>;
};
