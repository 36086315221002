import {
  createContext,
  useContext,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';
import { IconButton } from '@material-ui/core';
import { Close, SvgIconComponent } from '@material-ui/icons';

import { SnackbarKey, useSnackbar, VariantType } from 'notistack';

type Actions = {
  id: number;
  title: string;
  icon: SvgIconComponent;
  action: (key: SnackbarKey) => void | null;
};

type Toast = {
  type: VariantType;
  message: string;
  action?: (key: SnackbarKey) => void;
};

interface ToastContextData {
  addToast: ({ type, message, action }: Toast) => void;
}

interface ToastProviderProps {
  children: ReactNode;
}

let useSnackbarRef: {
  enqueueSnackbar: (
    arg0: string,
    arg1: {
      variant: string;
      autoHideDuration: number;
      anchorOrigin: { vertical: string; horizontal: string };
    },
  ) => void;
};

const ToastContext = createContext({} as ToastContextData);

const ToastProvider = ({ children }: ToastProviderProps): JSX.Element => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const actions = useMemo(
    (): Actions[] => [
      {
        id: 1,
        title: '',
        icon: Close,
        action: (key: SnackbarKey) => closeSnackbar(key),
      },
    ],
    [closeSnackbar],
  );

  const defaultAction = useCallback(
    key => {
      return actions.map(({ id, title, icon: Icon, action }: Actions) => (
        <IconButton key={id} onClick={() => action(key)}>
          {Icon && <Icon />}
          {title && <span style={{ fontSize: '1rem' }}>{title}</span>}
        </IconButton>
      ));
    },
    [actions],
  );

  const addToast = useCallback(
    ({ type = 'default', message, action = defaultAction }) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: 3000,
        action: key => action(key),
        anchorOrigin: {
          // vertical: isMobileVersion ? 'top' : 'bottom',
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    [enqueueSnackbar, defaultAction],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setUseSnackbarRef = (useSnackbarRefProp: any) => {
    useSnackbarRef = useSnackbarRefProp;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const InnerSnackbarUtilsConfigurator = ({ setUseSnackbarRef }: any) => {
    setUseSnackbarRef(useSnackbar());
    return null;
  };

  const SnackbarUtilsConfigurator = () => {
    return (
      <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
    );
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      <SnackbarUtilsConfigurator />
      {children}
    </ToastContext.Provider>
  );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };

export default {
  success(msg: string): void {
    this.toast(msg, 'success');
  },
  warning(msg: string): void {
    this.toast(msg, 'warning');
  },
  info(msg: string): void {
    this.toast(msg, 'info');
  },
  error(msg: string): void {
    this.toast(msg, 'error');
  },
  toast(msg: string, variant = 'default'): void {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
  },
};
