/* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
} from '@material-ui/core';
import { DirectionsCar, NavigationRounded } from '@material-ui/icons';
import { useAuth } from 'context/authContext';
import { ChangeEvent, useCallback, useState } from 'react';
import { mapSettings } from 'types/customer';
import { useStyles } from './style';

interface MapSettingsProps {
  toggleBackdrop: () => void;
}

export function DirectionIcon() {
  const classses = useStyles({})
  return (
    <Box style={{ marginBottom: 9 }}>
      <Box
        className={classses.ignitionOnAndMoving}
      />
    </Box>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function MapSettings(props: MapSettingsProps) {
  const { user, updateCustomerSettings } = useAuth();
  const [mapSettings, setMapSettings] = useState<mapSettings>(() => {
    return user.settings?.map ?? {} as mapSettings
  })
  const [hasUpdate, setHasUpdate] = useState(false)
  const classes = useStyles();

  const handleChangeTheme = useCallback((_, value: string) => {
    const theme = value as 'dark' | 'light' | 'time'
    setMapSettings(prev => ({ ...prev, theme }))
    setHasUpdate(true)
  }, [])

  const handleChangeMarker = useCallback((_, value: string) => {
    const marker = value as 'car' | 'direction'
    setMapSettings(prev => ({ ...prev, marker }))
    setHasUpdate(true)
  }, [])

  const handleChangeTypeLabel = useCallback((_, value: string) => {
    const typeLabel = value as 'plate' | 'custom'
    setMapSettings(prev => ({ ...prev, typeLabel }))
    setHasUpdate(true)
  }, [])

  const handleChangeSwitchCluster = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setMapSettings(prev => ({ ...prev, cluster: event.target.checked }))
    setHasUpdate(true)
  }, [])

  const handleChangeSwitchShowLabel = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setMapSettings(prev => ({ ...prev, showLabel: event.target.checked }))
    setHasUpdate(true)
  }, [])

  const handleSave = useCallback(async () => {
    try {
      await updateCustomerSettings({ map: mapSettings })
      setHasUpdate(false)
    } catch (error) {
      console.log(error);
    }
  }, [mapSettings, updateCustomerSettings])

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Marcador</FormLabel>
            <RadioGroup row aria-label="position" name="position" defaultValue="top" onChange={handleChangeMarker} value={mapSettings.marker}>
              <FormControlLabel
                value="car"
                control={<Radio color="primary" size='small' />}
                label={<DirectionsCar style={{ color: 'green' }} />}
                labelPlacement="top"
              />
              <FormControlLabel
                value="direction"
                control={<Radio color="primary" size='small' />}
                label={<DirectionIcon />}
                labelPlacement="top"
              />
              <FormControlLabel
                value="navigation"
                control={<Radio color="primary" size='small' />}
                label={<NavigationRounded style={{ color: 'green' }} />}
                labelPlacement="top"
              />

            </RadioGroup>
            <RadioGroup row aria-label="position" name="position" defaultValue="top" >
              <FormControlLabel
                control={<Switch name="switch_cluster" size='small' checked={mapSettings.cluster} onChange={handleChangeSwitchCluster} />}
                label="Agrupar marcadores"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <Divider />
        </Grid>
        <Grid item md={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Conteúdo do rótulo</FormLabel>
            <RadioGroup row aria-label="position" name="position" defaultValue="top" onChange={handleChangeTypeLabel} value={mapSettings.typeLabel}>
              <FormControlLabel
                value="plate"
                control={<Radio color="primary" size='small' />}
                label='Placa'
                labelPlacement="end"
              />
              <FormControlLabel
                value="custom"
                control={<Radio color="primary" size='small' />}
                label='Rótulo personalizado'
                labelPlacement="end"
              />

            </RadioGroup>
            <RadioGroup row aria-label="position" name="position" defaultValue="top">
              <FormControlLabel
                control={<Switch name="show_label_switch" size='small' onChange={handleChangeSwitchShowLabel} checked={mapSettings.showLabel} />}
                label="Exibir rótulo"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <Divider />
        </Grid>
        <Grid item md={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tema</FormLabel>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              onChange={handleChangeTheme}
              value={mapSettings.theme}
            >
              <FormControlLabel
                value="dark"
                control={<Radio color="primary" size='small' />}
                label='Escuro'
                labelPlacement="end"
              />
              <FormControlLabel
                value="light"
                control={<Radio color="primary" size='small' />}
                label='Claro'
                labelPlacement="end"
              />
              <FormControlLabel
                value="time"
                control={<Radio color="primary" size='small' />}
                label='Definido pelo horário'
                labelPlacement="end"

              />
            </RadioGroup>

          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.btnSave}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          size="small"
          onClick={handleSave}
          disabled={!hasUpdate}
        >
          Aplicar
        </Button>
      </div>
    </div>
  );
}
