import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  link: {
    fontWeight: 'normal',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: '#1e90ff',
      textDecoration: 'underline',
    },
  },
}));
