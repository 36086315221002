import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 14,
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
  closeCardButton: {
    display: 'none',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(2),
  },
  card: {
    // border: '1px solid',
    // width: '80%',
    borderRadius: '10px',
    // padding: 20,
    // margin: 5,
    transition: theme.transitions.create(['background', 'background-color'], {
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
    '&:hover button': {
      display: 'block',
    },
  },
}));
