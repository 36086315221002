import { Box, colors, Paper, makeStyles, Theme } from '@material-ui/core';
import { RenderIcon } from 'utils';

interface CarMarkerIconProps {
  ignition: number;
  vehicleType: string;
  visualizeLabel: boolean;
  label: string;
}

interface StyleProps {
  color?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  carIcon: {
    position: 'absolute',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.5)',
    },
  },
  icon: {
    color: ({ color }) => color,
    pointerEvents: 'auto',
    cursor: 'pointer',
    position: 'absolute',
    left: 10,
    top: 5,
  },
  label: {
    position: 'relative',
    right: -60,
    top: 0,
    fontSize: 9,
    borderRadius: '.4em',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: '0',
      top: '50%',
      width: '0',
      height: '0',
      border: '6px solid transparent',
      borderRightColor: theme.palette.background.paper,
      borderLeft: '0',
      marginTop: '-5px',
      marginLeft: '-5px',
    },
  },
  labelText: {
    fontSize: 10,
  },
}));

export function CarMarkerIcon({
  ignition,
  vehicleType,
  label,
  visualizeLabel,
}: CarMarkerIconProps) {
  const color = ignition ? colors.green[500] : colors.red[400];

  const classes = useStyles({ color });

  return (
    <div className={classes.carIcon}>
      <RenderIcon className={classes.icon} fontSize="25px" type={vehicleType} />

      <Box
        className={classes.label}
        component={Paper}
        width="100%"
        minWidth="60px"
        maxWidth="100px"
        padding={0.5}
        zIndex={-1}
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        style={{ visibility: visualizeLabel ? 'visible' : 'hidden' }}
      >
        <strong>{label}</strong>
      </Box>
    </div>
  );
}
