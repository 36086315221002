import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  Zoom,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';

export interface SearchBoxProps {
  isOpen: boolean;
  handleSearchVehicles: (e: any) => void;
}

const useStyles = makeStyles<Theme>(theme => ({
  searchBar: {
    justifyContent: 'left',
    display: 'flex',
    background: theme.palette.background.paper,
  },
}));

export function SearchBox({ handleSearchVehicles, isOpen }: SearchBoxProps) {
  const classes = useStyles();
  return (
    <Zoom in={isOpen} style={{ position: 'fixed', width: '100%', zIndex: 10 }}>
      <Toolbar
        className={classes.searchBar}
        style={{ display: isOpen ? '' : 'none' }}
      >
        <FormControl fullWidth>
          <OutlinedInput
            margin="dense"
            id="searchTerms"
            name="searchTerms"
            type="search"
            placeholder="Pesquisar..."
            onChange={handleSearchVehicles}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
          />
        </FormControl>
      </Toolbar>
    </Zoom>
  );
}
