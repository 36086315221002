/* eslint-disable camelcase */
import { Api } from '../lib/axios';

export interface ICommand {
  clientId: number;
  imei: string;
  deviceId: number;
  trackerModel: string;
  command: string;
}

export const CommandsService = {
  send: (data: ICommand) => Api.post('commands_treatment/send', data),
};
