import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  paper: {
    width: '100%',
    maxHeight: 450,
  },
  table: {},
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  title: {
    flex: '1 1 100%',
  },
  thead: {
    // background: theme.palette.titleBar.main,
  },
  th: {
    fontSize: 10,
    textAlign: 'center',
    // background: theme.palette.titleBar.main,
  },
  td: {
    fontSize: 10,
    textAlign: 'center',
  },
  checkbox: {},
  buttonContainer: {},
  highlight: {},
  tableBody: {
    '& .MuiTableRow-root.Mui-Selected, .MuiTableRow-root.Mui-seleted:hover': {
      backgroundColor: '#ccc',
    },
  },
  tr: {
    '& .MuiTableRow-root.Mui-Selected': {
      backgroundColor: '#ccc',
    },
  },
}));
