import { makeStyles, Theme } from '@material-ui/core';

interface params {
  bateryPercent: string;
}

export const useStyles = makeStyles<Theme, params>(theme => ({
  color: {
    color: ({ bateryPercent }) =>
      Number(bateryPercent) === 0 ? theme.palette.error.main : undefined,
  },
  noBattery: {
    color: theme.palette.error.main,
  },
}));
