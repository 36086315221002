/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { IResponse } from 'types/Response';
import { Api } from '../../../lib/axios';
import { IUpdateVehicleDTO, IVehicle } from '../types';

export const VehiclesService = {
  list: (ids: number[]): Promise<AxiosResponse<IResponse<IVehicle[]>>> =>
    Api.get('web_client', { params: { vehicles: ids } }),
  listClientVehicles: (client_id: number | string): Promise<AxiosResponse> =>
    Api.get(`web_client/vehicles/list/${client_id}`),
  updateLabel: ({
    clientId,
    vehicleId,
    label,
  }: IUpdateVehicleDTO): Promise<AxiosResponse> =>
    Api.put(
      `web_client/update_vehicle_label/${clientId}/${vehicleId}`,
      {
        label,
      },
      { params: { vehicle_id: vehicleId, client_id: clientId } },
    ),
};
