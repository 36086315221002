import {
  BatteryChargingFull,
  FlashOn,
  Satellite,
  SignalCellularAlt,
  Speed,
  VpnKey,
  Map as MapIcon,
} from '@material-ui/icons';

import {
  BateryPercent,
  BateryVoltage,
  GsmSignalIcon,
  IsOn,
  SatelliteSignal,
  SpeedKmh,
  DateTimeFormat,
  LatLonFormat,
  LastCommunicationTime,
  AddressGoogleMapsLink,
  IsLocked,
} from 'components/RenderCustomValues';

export const deviceGridColumns = [
  {
    id: 1,
    label: 'Placa',
    value: 'plate',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    default: true,
    name: 'plate',
  },
  {
    id: 2,
    label: 'Rótulo',
    value: 'label',
    minWidth: 50,
    align: 'center',
    default: true,
    name: 'label',
  },
  {
    id: 3,
    label: 'Marca',
    value: 'make',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    default: true,
    name: 'make',
  },
  {
    id: 4,
    label: 'Modelo',
    value: 'model',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    default: true,
    name: 'model',
  },
  {
    id: 5,
    label: 'Data/Hora',
    value: 'dateTime',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    render: ({ dateTime }: { dateTime: string }) => (
      <DateTimeFormat date={dateTime} />
    ),
    default: true,
    name: 'dateTime',
  },
  {
    id: 6,
    label: 'Tempo',
    value: 'dateTime',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    render: ({ dateTime }: { dateTime: string }) => (
      <LastCommunicationTime dateTime={dateTime} />
    ),
    default: true,
    name: 'last_communication_time',
  },
  {
    id: 7,
    label: 'Latitude',
    value: 'latitude',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    render: ({ latitude }: { latitude: string }) => (
      <LatLonFormat coord={latitude} />
    ),
    name: 'latitude',
    default: true,
  },
  {
    id: 8,
    label: 'Longitude',
    value: 'longitude',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    render: ({ longitude }: { longitude: string }) => (
      <LatLonFormat coord={longitude} />
    ),
    name: 'longitude',
    default: true,
  },
  {
    id: 9,
    label: 'Satellite',
    value: 'satellite',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <Satellite fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ satellite }: { satellite: number }) => (
      <SatelliteSignal signal={satellite} />
    ),
    name: 'satellite',
    default: true,
  },
  {
    id: 10,
    label: 'Visualizar no google maps',
    value: '',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <MapIcon style={{ fontSize: 15 }} />,
    render: ({
      latitude,
      longitude,
    }: {
      latitude: string;
      longitude: string;
    }) => <AddressGoogleMapsLink lat={latitude} lng={longitude} />,
    name: 'view_on_google_maps',
    default: true,
  },
  {
    id: 11,
    label: 'GSM',
    value: 'signal',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <SignalCellularAlt fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ signal }: { signal: number }) => (
      <GsmSignalIcon signal={signal} />
    ),
    name: 'signal',
    default: true,
  },
  {
    id: 12,
    label: 'Ignição',
    value: 'ignition',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <VpnKey fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ ignition }: { ignition: string }) => <IsOn isOn={ignition} />,
    default: true,
    name: 'ignition',
  },
  {
    id: 13,
    label: 'Velocidade',
    value: 'speed',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <Speed fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ speed }: { speed: string }) => <SpeedKmh speed={speed} />,
    name: 'speed',
    default: true,
  },
  {
    id: 14,
    label: 'Voltagem',
    value: 'voltage',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <FlashOn fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ voltage }: { voltage: string }) => (
      <BateryVoltage voltage={voltage} />
    ),
    default: true,
    name: 'voltage',
  },
  {
    id: 15,
    label: 'Bateria',
    default: true,
    value: 'bateryPercent',
    name: 'batery_percent',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    icon: <BatteryChargingFull fontSize="inherit" style={{ fontSize: 15 }} />,
    render: ({ bateryPercent }: { bateryPercent: string }) => (
      <BateryPercent bateryPercent={bateryPercent} />
    ),
  },
  {
    id: 16,
    label: 'Bloqueio',
    name: 'out1',
    minWidth: 50,
    align: 'center',
    alignRow: 'center',
    render: ({ out1, model }: { out1: string; model: string }) => (
      <IsLocked isLock={out1} model={model} />
    ),
    default: true,
    value: 'out1',
  },
];
