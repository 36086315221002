import { createMuiTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

// Dark theme
const dark = createMuiTheme(
  {
    palette: {
      type: 'dark',
      // primary: {
      //   main: '#26292C',
      //   light: 'rgb(81, 91, 95)',
      //   dark: 'rgb(26, 35, 39)',
      //   contrastText: '#ffffff',
      // },
      secondary: {
        light: '#64b5f6',
        main: '#2196f3',
        dark: '#1976d2',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      // background: {
      //   default: '#505250',
      // },
      // titleBar: {
      //   main: '#555555',
      //   contrastText: '#ffffff',
      // },
      // error: {
      //   light: '#e57373',
      //   main: red.A400,
      //   dark: '#d32f2f',
      // },
      // success: {
      //   light: '#81c784',
      //   main: '#4caf50',
      //   dark: '#388e3c',
      // },
      // warning: {
      //   light: '#ffb74d',
      //   main: '#ff9800',
      //   dark: '#f57c00',
      // },
      // info: {
      //   light: '#64b5f6',
      //   main: '#2196f3',
      //   dark: '#1976d2',
      // },
    },
    typography: {
      input: {
        fontSize: '0.7rem',
        labelFontSize: '0.7rem',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em',
          },
          '*::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
          },
        },
      },
    },
  },
  ptBR,
);

const light = createMuiTheme(
  {
    palette: {
      type: 'light',
      //   primary: {
      //     main: '#ccc',
      //   },
      //   secondary: {
      //     main: '#4287f5',
      //   },
      //   error: {
      //     main: red.A400,
      //   },
      //   background: {
      //     default: '#eeeeee',
      //     paper: '#f5f5f5',
      //   },
      //   titleBar: {
      //     main: '#eeeeee',
      //     contrastText: '#222222',
      //   },
      // },
      // typography: {
      //   input: {
      //     fontSize: '0.7rem',
      //     labelFontSize: '0.7rem',
      //   },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em',
          },
          '*::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
          },
        },
      },
    },
  },
  ptBR,
);

export { light, dark };
