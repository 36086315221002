import { FiberManualRecord } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  onLine: {
    color: theme.palette.success.main,
  },
  offLine: {
    color: theme.palette.error.main,
  },
}));

export function IsOn({ isOn }: { isOn: boolean | string }): JSX.Element {
  const classes = useStyles();
  if (isOn === undefined) return <>-</>;
  return (
    <FiberManualRecord
      className={isOn ? classes.onLine : classes.offLine}
      style={{ fontSize: 10 }}
    />
  );
}
