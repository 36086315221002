import { QueryConfig } from 'lib/react-query';
import { useQuery } from 'react-query';

import { Api } from 'lib/axios';

type GetLastPositionsDTO = {
  page: number;
  size: number;
  column: 'plate' | 'imei';
  payload: string;
  initialDate: Date;
  finalDate: Date;
};

type UseLastPositionsOptions = {
  config?: QueryConfig<typeof getLastPositions>;
};

interface UseLastPositionProps {
  config?: UseLastPositionsOptions;
  params: GetLastPositionsDTO;
}

export const getLastPositions = ({
  column,
  finalDate,
  initialDate,
  page,
  payload,
  size,
}: GetLastPositionsDTO) => {
  return Api.get('positions/history_only', {
    params: { column, finalDate, initialDate, page, payload, size },
  });
};

export const useLastPositions = ({
  config = {},
  params,
}: UseLastPositionProps) => {
  return useQuery({
    queryKey: ['lastPositions'],
    queryFn: () => getLastPositions(params),
    ...config.config,
  });
};
