import {
  Modal,
  Typography,
  Box,
  Backdrop,
  makeStyles,
  Fade,
  ClickAwayListener,
  IconButton,
} from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import { forwardRef, ReactNode, useImperativeHandle, useState } from 'react';

type Props = {
  title: string;
  children: ReactNode;
  onClose?: () => void;
};

export type UiModalRef = {
  open: () => void;
  close: () => void;
};
export const modalBreakpoint = 525;
const useModalStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    display: 'grid',
    placeContent: 'center',
    '& > *': {
      color: palette.text.primary,
    },
    [breakpoints.down(modalBreakpoint)]: {
      placeContent: 'end',
    },
  },
  boxContainer: {
    minWidth: 440,
    padding: 20,
    display: 'inline-block',
    background: palette.background.default,
    borderRadius: 30,
    '& > .box--header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    [breakpoints.down(modalBreakpoint)]: {
      width: '100vw',
      minWidth: '100vw',
      minHeight: '80vh',
      borderRadius: '30px 30px 0 0',
      overflowY: 'scroll',
    },
  },
}));

export const UiModal = forwardRef<UiModalRef, Props>(function UiModal(
  { title, children, onClose },
  ref,
) {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useModalStyles();
  const handleClose = () => setIsOpen(false);
  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => {
      setIsOpen(false);
      if (onClose) onClose();
    },
  }));
  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="modal-title"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.root}
    >
      <Fade in={isOpen}>
        <ClickAwayListener onClickAway={handleClose}>
          <Box component="main" boxShadow={2} className={classes.boxContainer}>
            <section className="box--header">
              <Typography id="modal-title" variant="h2">
                {title}
              </Typography>
              <IconButton
                aria-label="fechar"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <HighlightOff />
              </IconButton>
            </section>

            <Box component="section" style={{ overflow: 'scroll' }}>
              {children}
            </Box>
          </Box>
        </ClickAwayListener>
      </Fade>
    </Modal>
  );
});
