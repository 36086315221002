import { useMediaQuery, useTheme } from '@material-ui/core';
import GoogleMapReact, { Coords, Maps, MapOptions } from 'google-map-react';

import { useGeofence } from 'context/geofenceContext';
import React, { useEffect, useRef, useState } from 'react';
import { useMap } from 'context/mapContext';
import { REACT_APP_GOOGLE_API_KEY } from '../../config';
import { useStyles } from './styles';

interface Google {
  map: google.maps.Map;
  maps: any;
  ref: Element | null;
}

interface MapProps {
  center?: Coords | undefined;
  zoom: number;
  clientLocation?: Coords | undefined;
  isFenceControlsEnabled: boolean;
  markers?: React.ReactNode;
  children?: (google: Google) => React.ReactNode | React.ReactNode[];
}

export function Map({
  center,
  zoom,
  clientLocation,
  isFenceControlsEnabled,
  markers,
}: MapProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const [isMapReady, setIsMapReady] = useState(false);
  const isDesktopVersion = useMediaQuery(theme.breakpoints.up('sm'));
  const { initMap } = useGeofence();
  const [, setMapProps] = useState<google.maps.Map>();
  const googleRef = useRef<Google | null>(null);

  const { onMapLoaded, onMapChange } = useMap();

  useEffect(() => {
    let layer: HTMLDivElement;
    let internalMapContainer: Element | null;
    if (isMapReady) {
      // append a div to an internal maps div
      // so we can use it as a custom layer for the tooltips
      internalMapContainer = document.querySelector('.gm-style');
      layer = document.createElement('div');
      layer.id = 'infoboxlayer';
      internalMapContainer?.append(layer);
    }
    return () => {
      if (internalMapContainer) {
        // internalMapContainer.remove(layer);
        internalMapContainer.removeChild(layer);
      }
    };
  }, [isMapReady]);

  function mapOptionsCreator(map: Maps): MapOptions {
    if (!isDesktopVersion) {
      return {
        // disableDefaultUI: true,
        keyboardShortcuts: false,
        mapTypeControl: true,
        zoomControl: false,
        fullscreenControl: false,
        streetViewControl: true,
        streetViewControlOptions: {
          position: map.ControlPosition.RIGHT_TOP,
        },
        mapTypeControlOptions: {
          style: map.MapTypeControlStyle.DROPDOWN_MENU,
          position: map.ControlPosition.TOP_LEFT,
        },
        maxZoom: 20,
      };
    }

    return {
      keyboardShortcuts: false,
      mapTypeControl: true,
      zoomControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: map.ControlPosition.LEFT_TOP,
      },
      zoomControlOptions: {
        position: map.ControlPosition.LEFT_TOP,
      },
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: map.ControlPosition.LEFT_TOP,
      },
      maxZoom: 20,
    };
  }

  const mapLoaded = (google: Google) => {
    setIsMapReady(true);
    googleRef.current = google;
    if (isFenceControlsEnabled) {
      initMap({
        google,
        mapIsLoaded: true,
      });
    }

    setMapProps(google.map);

    onMapLoaded(google);

    return null;
  };

  return (
    <div className={classes.root}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: REACT_APP_GOOGLE_API_KEY,
          libraries: ['drawing'].join(','),
        }}
        defaultZoom={4}
        defaultCenter={clientLocation}
        zoom={zoom}
        center={center}
        options={mapOptionsCreator}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={mapLoaded}
        onChange={onMapChange}
      >
        {markers && markers}
      </GoogleMapReact>
    </div>
  );
}
