import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {},

  formControl: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  selectedOptions: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  btnSave: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  selectAllText: {
    fontWeight: 500,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));
