import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles({
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listIcon: {
    minWidth: 0,
  },
});
