import { Tooltip } from '@material-ui/core';
import { LockOpenTwoTone, LockTwoTone } from '@material-ui/icons';
import { useStyles } from './styles';

interface IsLockedProps {
  isLock: string | number | boolean;
  model: string;
}
export function IsLocked({ isLock, model }: IsLockedProps) {
  const classes = useStyles();
  let lockState = isLock === '1' || isLock === 1;
  if (model === 'LOGALL_INVERTED' || model === 'E3_INVERTED') {
    lockState = !lockState;
  }

  if (isLock === undefined) {
    return <>-</>;
  }

  return (
    <Tooltip title={lockState ? 'bloqueado' : 'desbloqueado'}>
      {lockState ? (
        <LockTwoTone fontSize="small" className={classes.locked} />
      ) : (
        <LockOpenTwoTone fontSize="small" className={classes.unlocked} />
      )}
    </Tooltip>
  );
}
