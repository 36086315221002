import createTheme from '@material-ui/core/styles/createMuiTheme';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

type PaletteMode = 'light' | 'dark';

const getDesignTokens = (mode: PaletteMode): PaletteOptions => ({
  type: mode,
  primary: {
    main: '#2068BF',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#f44336',
    contrastText: '#ffffff',
  },
  ...(mode === 'light'
    ? {
        background: {
          default: '#fafafa',
        },
        text: {
          primary: '#656565',
        },
      }
    : {
        background: {
          default: '#303030',
        },
        text: {
          primary: '#ffffff',
        },
      }),
});

export const createLocalTheme = (themeInput: PaletteMode | 'time' = 'dark') => {
  const theme = (() => {
    if (themeInput === 'time') {
      return new Date().getHours() < 18 ? 'light' : 'dark';
    }
    return themeInput;
  })();

  return createTheme({
    palette: getDesignTokens(theme),
    typography: {
      fontFamily: ['Inter', 'Arial', 'apple-system', 'sans-serif'].join(','),
      h1: {
        fontSize: 48,
        fontWeight: 700,
      },
      h2: {
        fontSize: 24,
        fontWeight: 500,
      },
      h3: {
        fontSize: 20,
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: 18,
        fontWeight: 400,
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          borderRadius: 20,
        },
      },
      MuiButton: {
        root: {
          borderRadius: 10,
        },
        containedSecondary: {
          border: `1px solid #F05050`,
        },
        containedPrimary: {
          border: `1px solid #08BDF9`,
        },
      },
      MuiFormControl: {
        marginNormal: {
          margin: '0px !important',
        },
      },
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em',
          },
          '*::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
          },
        },
      },
    },
  });
};
