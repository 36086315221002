import { useAuth } from 'context/authContext';
import { useQuery, UseQueryResult } from 'react-query';
import { EventService } from './eventService';
import { Event } from '../types';

const REFETCH_INTERVAL = 60 * 10000;

async function listLastEvents(ids: number[]) {
  const response = await EventService.listEvents(ids, 1);
  const { message, payload } = response.data;
  if (message === 'success') {
    return payload;
  }
  return [];
}

export function useLastEvents(): UseQueryResult<Event[]> {
  const { user } = useAuth();
  const { permissions } = user;
  const events = useQuery(
    ['lastEvents', user.vehicles],
    () => listLastEvents(user.vehicles),
    {
      refetchInterval: permissions.includes('events')
        ? REFETCH_INTERVAL
        : false,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      enabled: Boolean(user.vehicles?.length) && permissions.includes('events'),
    },
  );

  return events;
}
