import { makeStyles, Theme } from '@material-ui/core';

interface Props {
  color?: string;
  zIndex?: number;
}

export const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    zIndex: ({ zIndex }) => zIndex,
    '-webkit-transition': 'all 0.2s ease',
    transition: 'all 0.2s ease',
    pointerEvents: 'none',
    '&:hover': {
      zIndex: 9999,
    },
  },
  icon: {
    color: (props: Props) => props.color,
    pointerEvents: 'auto',
    cursor: 'pointer',
  },
  carIcon: {
    display: 'flex',
    alignItems: 'center',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.5)',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
  },
  mobileInfoIndow: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: 0,
    marginTop: 55,
  },
}));
