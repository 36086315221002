import { colors, Box } from '@material-ui/core';
import { IVehicle } from 'features/Vehicle';

import { useLayer } from 'react-laag';
import { useMemo } from 'react';
import { InfoWindow } from '../InfoWindow';
import { useStyles } from './styles';
import { renderIcon } from './Rendericon';

interface MarkerProps {
  show: number | null;
  lat: number;
  lng: number;
  onOpen: () => void;
  vehicle: IVehicle;
  index: number | string;
  onClose: () => void;
  visualizeLabel: boolean;
  typeLabel: 'plate' | 'custom';
  marker: 'car' | 'direction';
}

export function RenderMarkerDesktop({
  show,
  onOpen,
  vehicle,
  index,
  onClose,
  visualizeLabel,
  typeLabel,
  marker,
}: MarkerProps): JSX.Element {
  const props = {
    color: vehicle.device.ignition ? colors.green[500] : colors.red[400],
    zIndex: vehicle.device.ignition ? 1 : 2,
  };

  const classes = useStyles(props);
  const isOpen = show === index;

  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: true,
    overflowContainer: false,
    // onOutsideClick: () => onClose(),
    container: 'infoboxlayer',
    onDisappear: type => {
      if (type === 'full') onClose();
    },
  });

  return useMemo(
    () => (
      <>
        <Box
          id={String(vehicle.id)}
          className={classes.root}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={onOpen}
          {...triggerProps}
        >
          {renderIcon({
            typeMarker: marker,
            visualizeLabel,
            typeLabel,
            vehicle,
          })}
        </Box>
        {show === index &&
          renderLayer(
            <div style={{ position: 'relative', top: -20 }}>
              <InfoWindow
                onClose={onClose}
                vehicleData={vehicle}
                layerProps={layerProps}
              />
              ,
            </div>,
          )}
      </>
    ),
    [
      classes.root,
      index,
      layerProps,
      marker,
      onClose,
      onOpen,
      renderLayer,
      show,
      triggerProps,
      typeLabel,
      vehicle,
      visualizeLabel,
    ],
  );
}
