import { Api } from 'lib/axios';
import { useQuery } from 'react-query';

async function findAddress(latitude: number, longitude: number) {
  if (!latitude || !longitude) return undefined;

  const response = await Api.get('geocode', {
    params: { latitude, longitude },
  });

  return response.data.payload;
}

type UseAddressOptions = {
  params: { latitude: number; longitude: number };
  key?: string;
};

export const useAddress = ({ params, key = 'default' }: UseAddressOptions) => {
  const data = useQuery({
    queryKey: [`address@${key}`],
    queryFn: () => findAddress(params.latitude, params.longitude),
  });

  return data;
};
